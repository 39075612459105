/** @jsxImportSource @emotion/react */

import useStyles from './styles';
import { useHistory } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import Calendar from 'react-calendar';
import CourseVenueEdit from '../CourseVenueEdit';
import Loader from '../Loader';
import CustomStatusDropDown from '../CustomStatusDropDown';
import {
  getCourseList,
  getCourseInstanceDetails,
  getTrainersCourseInstanceApi,
  getVenueCourseInstanceApi,
  getCourseInstanceApi,
  updateCourseInstanceApi,
  updateTrainersCourseInstance,
  updateVenuesCourseInstance,
  getCourseVenueList,
  getVenuesByDistance,
  getTrainersByDistance,
  trainersCourseInstanceApi,
  createVenueCourseInstance,
  getCourseGroups,
  getCourseGroupsWithTrainers,
  getCourseInstanceByGroup,
  updateCourseGroup,
  courseInstanceApi,
  updateCourseInstance,
  getTrainersCourseGroup,
  createTrainersCourseGroup,
  updateTrainersCourseGroup,
  getCourseGroupDetail,
  deleteCourseGroup,
  deleteCourseInstance,
  getCourseGroupById,
  getCourseVenueData,
} from '../../services';
import FilterOptionsList from '../FilterOptionsList';
import DatePicker from 'react-multi-date-picker';
import { DateIcon, DateIconSmall, Ellipsis, Delete } from '../Icons';

import {
  courseInstaceDetails,
  courseGroupDetails,
  filterOptionsList,
} from './mockdata';
import CourseInfoPopup from '../CourseInfoPopup';
import CourseInfoConfirmPopup from '../CourseInfoConfirmPopup';
import DeleteCoursePopup from '../DeleteCoursePopup';

const CourseManagementList = () => {
  const styles = useStyles();
  const history = useHistory();
  const [selectedId, setSelectedId] = useState();
  const [courseData, setCourseData] = useState();
  const [confirmedCourses, setConfirmedCourses] = useState();
  const [pendingCourses, setpendingCourses] = useState();
  const [isLoadingPageData, setIsLoadingPageData] = useState(true);
  const [trainerStatusDefault, settrainerStatusDefault] = useState(0);
  const [courseDetailsShowHash, setCourseDetailsShowHash] = useState({});
  const [courseEditShowHash, setCourseEditShowHash] = useState({});
  const [courseEditShowId, setCourseEditShowId] = useState(null);
  const [courseDatesShowId, setCourseDatesShowId] = useState(null);
  const [trainersCourseInstanceHash, setTrainersCourseInstanceHash] = useState(
    {}
  );
  const [courseGroupDetailHash, setCourseGroupDetailHash] = useState({});
  const [trainersInstanceData, setTrainersInstanceData] = useState([]);
  const [venuesCourseInstanceHash, setVenuesCourseInstanceHash] = useState({});
  const [notesInstanceHash, setNotesInstanceHash] = useState({});
  const [notesCourseInstanceHash, setNotesCourseInstanceHash] = useState({});
  const [venues, setVenues] = useState();
  const [courses, setCourses] = useState();
  const [venueFilters, setvenueFilters] = useState([]);
  const [coachFilters, setcoachFilters] = useState([]);
  const [courseFilters, setcourseFilters] = useState([]);
  const [datevalue, setDatevalue] = useState('');
  const [calendarValue, setCalendarValue] = useState('');
  const [showCalendar, setShowCalendar] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [resetFilters, setResetFilters] = useState(false);
  const [isLoadingVenues, setIsLoadingVenues] = useState(false);
  const [currentCoursedates, setCurrentCoursedates] = useState([]);
  const [courseDatesHash, setCourseDatesHash] = useState([]);
  const [courseInfoModal, showCourseInfoModal] = useState(false);
  const [courseConfirmModal, showCourseConfirmModal] = useState(false);
  const [courseDeleteModal, showCourseDeleteModal] = useState(false);
  const [currentCourseEdit, setCurrentCourseEdit] = useState({});
  const [currentCourseDelete, setCurrentCourseDelete] = useState(null);
  const [currentCourseInstanceDelete, setCurrentCourseInstanceDelete] =
    useState([]);
  const [courseInfoData, setCourseInfoData] = useState();
  const [filterOptions, setFilterOptions] = useState(filterOptionsList);

  let day = new Date();
  let month = day.getMonth() + 1;
  let dt = day.getDate();
  let fullMonth = month > 9 ? month : `0${month}`;
  let fulldt = dt > 9 ? dt : `0${dt}`;

  const [datePickerValue, setdatePickerValue] = useState(
    `${day.getFullYear()}/${fullMonth}/${fulldt}`
  );

  const dateArray = [
    '2024-04-22T06:54:10.61',
    '2024-04-24T06:54:10.61',
    '2024-04-26T06:54:10.61',
  ];

  const [courseDateList, setCourseDateList] = useState([]);

  const venueListSize = 7;
  const trainerListSize = 11;

  const statusList = [
    {
      id: 1,
      option: 'Not contacted',
    },
    {
      id: 2,
      option: 'Pending',
    },
    {
      id: 3,
      option: 'Confirmed',
    },
    {
      id: 4,
      option: 'Declined',
    },
  ];

  let tooltipRef = useRef();

  useEffect(() => {
    let handler = e => {
      if (
        !(
          tooltipRef?.current?.contains(e?.target) ||
          e?.target?.classList?.contains('toggleDates')
        )
      ) {
        setCourseDatesShowId(null);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  let editCourseRef = useRef();

  useEffect(() => {
    let handler = e => {
      if (
        !(
          editCourseRef?.current?.contains(e?.target) ||
          e?.target?.classList?.contains('editCourseToggle')
        )
      ) {
        setCourseEditShowId(null);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleCalendarChange = dateval => {
    setShowCalendar(!showCalendar);
    const today = new Date(dateval);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    const formattedToday = `${dd}/${mm}/${yyyy}`;
    setDatevalue(formattedToday);
    setCalendarValue(`${mm}/${dd}/${yyyy}`);
  };

  const handleResetFilters = () => {
    setResetFilters(true);
    setFromDate('');
    setToDate('');
  };

  const setNotes = (note, courseId) => {
    setNotesInstanceHash({ ...notesInstanceHash, [courseId]: note });
  };

  const updateNotes = courseGroupId => {
    console.log(
      `Current note for course ${courseGroupId} - `,
      notesInstanceHash[courseGroupId]
    );
    console.log(
      `Course Instance to be updated for course ${courseGroupId} - `,
      notesCourseInstanceHash[courseGroupId]
    );

    let notesUpdated = '';

    if (
      notesCourseInstanceHash[courseGroupId].notes !== null &&
      notesCourseInstanceHash[courseGroupId].notes !== ''
    )
      notesUpdated = `${notesCourseInstanceHash[courseGroupId].notes} | 
         ${notesInstanceHash[courseGroupId]}`;
    else notesUpdated = notesInstanceHash[courseGroupId];

    const updatedCourseData = {
      ...notesCourseInstanceHash[courseGroupId],
      notes: notesUpdated,
    };

    updateCourseGroup(updatedCourseData, courseGroupId)
      .then(({ data }) => {
        getNotes(courseGroupId);
        console.log('Notes updated -', data);
      })
      .catch(ex =>
        console.log(
          'Error occured in updateCourseGroup while saving note -',
          ex
        )
      );
  };

  const updateDateRange = (startDate, endDate, courseInstance) => {
    console.log('startDate -', startDate);
    console.log('endDate -', endDate);
    console.log('courseInstanceId -', courseInstance);

    const updatedCourseData = {
      ...courseInstance,
      startDate: startDate,
      endDate: endDate,
    };

    updateCourseInstanceApi(updatedCourseData, courseInstance?.courseInstanceId)
      .then(() => {
        getCourseInstanceDetails()
          .then(({ data }) => {
            setConfirmedCourses(
              data?.filter(course => course.finalised === 1)?.reverse()
            );
            setpendingCourses(
              data?.filter(course => course.finalised !== 1).reverse()
            );
          })
          .catch(ex =>
            console.log(
              'Error in getCourseInstanceDetails while date range update',
              ex
            )
          );
      })
      .catch(ex =>
        console.log(
          'Error occured in updateCourseInstanceApi while date range update -',
          ex
        )
      );
  };

  const getTrainers = (courseId, latitude, longitude) => {
    getTrainersCourseGroup(courseId)
      .then(({ data }) => {
        console.log(
          `Trainer instance details for courseID ${courseId} -`,
          data
        );

        getTrainersByDistance(latitude, longitude).then(function (response) {
          console.log('Trainers by dist in axios-', response?.data);

          const closestTrainers = response?.data.slice(0, trainerListSize);
          console.log(
            `Closest ${trainerListSize} trainers - `,
            closestTrainers
          );

          const trainersCourseInstance = {
            ...data,
            trainersNearby: closestTrainers,
          };

          setTrainersCourseInstanceHash({
            ...trainersCourseInstanceHash,
            [courseId]: trainersCourseInstance,
          });

          getCourseGroupDetails(courseId);
        });
      })
      .catch(ex =>
        console.log('Error occured in getTrainersCourseInstanceApi call -', ex)
      );
  };

  const getVenues = (
    courseId,
    latitude,
    longitude,
    venueConfirmed,
    venueId
  ) => {
    // getVenueCourseInstanceApi(courseId)
    //   .then(({ data }) => {
    //     console.log(`Venue instance details for courseID ${courseId} -`, data);

    //     getVenuesByDistance(latitude, longitude).then(function (response) {
    //       console.log('Venues by dist -', response?.data);
    //       const closestVenues = response?.data.slice(0, venueListSize);
    //       console.log(`Closest ${venueListSize} venues - `, closestVenues);

    //       const venuesCourseInstance = { ...data, venuesNearby: closestVenues };

    //       setVenuesCourseInstanceHash({
    //         ...venuesCourseInstanceHash,
    //         [courseId]: venuesCourseInstance,
    //       });
    //     });
    //   })
    //   .catch(ex =>
    //     console.log('Error occured in getVenueCourseInstanceApi call -', ex)
    //   );

    getVenuesByDistance(latitude, longitude)
      .then(function (response) {
        if (venueConfirmed === 1) {
          console.log('Venues by dist -', response?.data);
          const closestVenues = response?.data?.slice(0, venueListSize);
          console.log(`Closest ${venueListSize} venues - `, closestVenues);

          const venuesCourseInstance = { venuesNearby: closestVenues };

          setVenuesCourseInstanceHash({
            ...venuesCourseInstanceHash,
            [courseId]: venuesCourseInstance,
          });
        } else {
          getCourseVenueData(venueId)
            .then(({ data }) => {
              console.log('Venues details of selected venue -', data);

              let closestVenues;

              if (
                response?.data?.find(
                  venue => venue?.venueId === data?.venueId
                ) !== undefined
              ) {
                closestVenues = response?.data?.slice(0, venueListSize);
              } else {
                closestVenues = [data, ...response?.data]?.slice(
                  0,
                  venueListSize
                );
              }

              console.log(`Closest ${venueListSize} venues - `, closestVenues);

              const venuesCourseInstance = { venuesNearby: closestVenues };

              setVenuesCourseInstanceHash({
                ...venuesCourseInstanceHash,
                [courseId]: venuesCourseInstance,
              });
            })
            .catch(ex =>
              console.log(
                'Failed to fetch venue details of selected venue - ',
                ex
              )
            );
        }
      })
      .catch(ex =>
        console.log('Error occured in getVenueCourseInstanceApi call -', ex)
      );
  };

  const getNotes = courseGroupId => {
    getCourseGroupById(courseGroupId)
      .then(({ data }) => {
        let note = '';
        console.log(
          `Notes details for courseID ${courseGroupId} -`,
          data?.notes
        );
        setNotesCourseInstanceHash({
          ...notesCourseInstanceHash,
          [courseGroupId]: data,
        });
        setNotesInstanceHash({ ...notesInstanceHash, [courseGroupId]: '' });
      })
      .catch(ex =>
        console.log('Error occured in getCourseInstanceApi call -', ex)
      );
  };

  const getCourseGroupDetails = courseId => {
    getCourseGroupDetail(courseId)
      .then(({ data }) => {
        console.log(`Course group details for courseID ${courseId} -`, data);
        setCourseGroupDetailHash({
          ...courseGroupDetailHash,
          [courseId]: data,
        });
      })
      .catch(ex =>
        console.log('Error occured in getCourseInstanceApi call -', ex)
      );
  };

  const setTrainerAndVenueCourseInstanceData = (
    courseId,
    latitude,
    longitude,
    venueConfirmed,
    venueId
  ) => {
    getTrainers(courseId, latitude, longitude);
    getVenues(courseId, latitude, longitude, venueConfirmed, venueId);
    getNotes(courseId);
  };

  const showHideCourseDetails = (
    courseId,
    latitude,
    longitude,
    venueConfirmed,
    venueId
  ) => {
    if (courseDetailsShowHash[courseId]) {
      setCourseDetailsShowHash({
        ...courseDetailsShowHash,
        [courseId]: !courseDetailsShowHash[courseId],
      });
    } else {
      setTrainerAndVenueCourseInstanceData(
        courseId,
        latitude,
        longitude,
        venueConfirmed,
        venueId
      );
      setCourseDetailsShowHash({ ...courseDetailsShowHash, [courseId]: true });
    }
  };

  const showHideCourseEdit = (courseId, event) => {
    event?.stopPropagation();
    if (courseEditShowId === null || courseEditShowId !== courseId)
      setCourseEditShowId(courseId);
    else setCourseEditShowId(null);

    loadCourseDates(courseId);
  };

  const loadCourseDates = (courseId, isUpdate = false) => {
    if (!courseDatesHash[courseId] || isUpdate === true) {
      getCourseInstanceByGroup(courseId)
        .then(({ data }) => {
          console.log(`Course instance for courseID ${courseId} -`, data);
          const courseDates = data.sort((a, b) => {
            if (a.courseDate > b.courseDate) return 1;
            else return -1;
          });

          setCourseDatesHash({ ...courseDatesHash, [courseId]: courseDates });
        })
        .catch(ex => {
          console.log('Error occured in getCourseInstanceApi call -', ex);

          const dateArray = [
            '2024-04-22T06:54:10.61',
            '2024-04-24T06:54:10.61',
            '2024-04-26T06:54:10.61',
          ];

          setCourseDatesHash({ ...courseDatesHash, [courseId]: dateArray });
        });
    }
  };

  const showHideCourseDates = courseId => {
    if (courseDatesShowId === null || courseDatesShowId !== courseId) {
      if (!courseDatesHash[courseId]) {
        loadCourseDates(courseId);
      }
      setCourseDatesShowId(courseId);
    } else {
      setCourseDatesShowId(null);
    }
  };

  useEffect(() => {
    getCourseGroupsWithTrainers()
      .then(({ data }) => {
        setConfirmedCourses(
          data
            ?.filter(course => course?.courseGroup?.finalised === 1)
            ?.reverse()
        );
        setpendingCourses(
          data
            ?.filter(course => course?.courseGroup?.finalised !== 1)
            ?.reverse()
        );
      })
      .catch(ex => {
        console.log('Error in getCourseGroupsWithTrainers', ex);
        setConfirmedCourses(
          courseGroupDetails
            ?.filter(course => course.finalised === 1)
            ?.reverse()
        );
        setpendingCourses(
          courseGroupDetails
            ?.filter(course => course.finalised !== 1)
            ?.reverse()
        );
      });

    getCourseVenueList()
      .then(({ data }) => {
        setVenues(data);
      })
      .catch(ex => console.log('Error in getCourseVenueList', ex));

    getCourseList()
      .then(({ data }) => {
        setCourses(data);
      })
      .catch(ex => console.log('Error in getCourseList', ex));
  }, []);

  useEffect(() => {
    if (courses?.length > 0) {
      console.log('course types -', courses);
      const updatedFilters = filterOptions?.map(filter => {
        if (
          filter?.title === 'COURSE TYPE' &&
          filter?.filterList?.options?.length <= 1
        ) {
          const filtersfromCourses = courses.map(course => {
            return {
              id: course?.courseId,
              optionVal: course?.name?.toLowerCase()?.split(' ').join('_'),
              optionLabel: course?.name,
              icon: getCourseIcon(course?.name)
            };
          });

          filter?.filterList?.options.push(...filtersfromCourses);
        }
        return filter;
      });

      setFilterOptions(updatedFilters);
    }
  }, [courses]);

  useEffect(() => {
    //logging state changes
    console.log('confirmedCourses -', confirmedCourses);
    console.log('pendingCourses -', pendingCourses);
    console.log('courseDetailsShowHash -', courseDetailsShowHash);
    console.log('trainersCourseInstanceHash -', trainersCourseInstanceHash);
    console.log('venuesCourseInstanceHash -', venuesCourseInstanceHash);
    console.log('notesInstanceHash -', notesInstanceHash);
    console.log('notesCourseInstanceHash -', notesCourseInstanceHash);
    console.log('venueFilters -', venueFilters);
    console.log('coachFilters -', coachFilters);
    console.log('courseFilters -', courseFilters);
    console.log('fromDate - ', fromDate);
    console.log('toDate - ', toDate);
    console.log('courseDatesHash - ', courseDatesHash);
    console.log('courseDateList - ', courseDateList);
    console.log('courseGroupDetailHash - ', courseGroupDetailHash);
    console.log('filterOptions - ', filterOptions);
  }, [
    confirmedCourses,
    pendingCourses,
    courseDetailsShowHash,
    trainersCourseInstanceHash,
    venuesCourseInstanceHash,
    notesInstanceHash,
    notesCourseInstanceHash,
    courseFilters,
    venueFilters,
    coachFilters,
    fromDate,
    toDate,
    courseDatesHash,
    courseDateList,
    courseGroupDetailHash,
    filterOptions,
  ]);

  const onClickEditVenueHandler = (courseInstanceId, venueId) => {
    history.push(`/courses/update/${courseInstanceId}/${venueId}/list`);
  };

  const getCourseIcon = (courseType) => {
    if(courseType?.toLowerCase()?.includes('uefa b')){
      return '/images/common/filter/uefab.svg';
    }
    else if(courseType?.toLowerCase()?.includes('uefa c')){
      return '/images/common/filter/uefac.svg';
    }
    else if(courseType?.toLowerCase()?.includes('goalkeep')){
      return '/images/common/filter/gk.svg';
    }
    else if(courseType?.toLowerCase()?.includes('futsal')){
      return '/images/common/filter/futsal.svg';
    }
    else{
      return '/images/common/filter/efaif.svg';
    }
  }

  const getDateFormat = (date, format = 'dd-mm-yyyy') => {
    const dt = new Date(date);
    const yyyy = dt.getFullYear();
    let mm =
      format === 'mm dd'
        ? dt.toLocaleString('default', { month: 'short' })
        : dt.getMonth() + 1;
    let dd = dt.getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    if (format === 'dd-mm-yyyy') return `${dd}-${mm}-${yyyy}`;

    if (format === 'yyyy-mm-dd') return `${yyyy}-${mm}-${dd}`;

    return `${mm} ${dd}`;
  };

  if (isLoadingPageData && !pendingCourses && !confirmedCourses) {
    return <Loader message="Loading course data..." />;
  }

  const getDatefromData = (dataId, instance, type) => {
    let date = '';
    if (type === 'trainer')
      date = instance.find(val => val.trainerId === dataId)?.lastContacted;
    if (type === 'venue')
      date = instance.find(val => val.venueId === dataId)?.lastContacted;

    if (date !== null && date !== '' && date !== undefined)
      return getDateFormat(date);
    else return '';
  };

  const getStatusfromData = (dataId, instance, type) => {
    let data;
    let status = 1;

    if (type === 'trainer')
      data = instance.find(val => val.trainerId === dataId);

    if (type === 'venue') data = instance.find(val => val.venueId === dataId);

    const { confirmed, contacted, declined } = data;

    if (confirmed === 1) status = 3;
    else if (declined === 1) status = 4;
    else if (contacted === 1) status = 2;

    return status;
  };

  const getCoachResponseData = (coachData, noOfTrainers, resourced) => {
    const totalCoachContacted = coachData?.length;
    const totalCoachCount = trainerListSize - 1;
    const contactedCount = coachData?.filter(
      coach =>
        coach?.contacted !== null &&
        coach?.contacted !== false &&
        coach?.contacted !== 0
    )?.length;
    const confirmedCount = coachData?.filter(
      coach =>
        coach?.confirmed !== null &&
        coach?.confirmed !== false &&
        coach?.confirmed !== 0
    )?.length;
    const declinedCount = coachData?.filter(
      coach =>
        coach?.declined !== null &&
        coach?.declined !== false &&
        coach?.declined !== 0
    )?.length;

    let status = '',
      message = '',
      info = '';

    if (totalCoachContacted === 0) {
      status = 'default';
      message = `${contactedCount}/${noOfTrainers} coaches contacted`;
    } else if (confirmedCount >= noOfTrainers) {
      if (resourced !== 1) {
        info =
          'One or more coaches who have confirmed are unavailable for one or more date';
        status = 'pending';
      } else {
        status = 'confirmed';
        info = `${noOfTrainers} or more coaches have confirmed availability`;
      }
      message = `${confirmedCount}/${noOfTrainers} coaches confirmed`;
    } else if (confirmedCount > 0 && confirmedCount < noOfTrainers) {
      status = 'pending';
      info = `A minimum of ${noOfTrainers} coaches should confirm availability for all dates`;
      message = `${confirmedCount}/${noOfTrainers} coaches confirmed`;
    } else if (contactedCount > 0) {
      status = 'pending';
      message = `${contactedCount}/${noOfTrainers} coaches contacted`;
      info = `A minimum of ${noOfTrainers} coaches should confirm availability for all dates`;
    } else if (declinedCount >= noOfTrainers) {
      status = 'declined';
      message = `${declinedCount}/${noOfTrainers} coaches declined`;
      info = `At minimum of ${noOfTrainers} coaches should confirm availability for all dates`;
    } else if (declinedCount > 0 && declinedCount < noOfTrainers) {
      status = 'default';
      info = `A minimum of ${noOfTrainers} coaches should confirm availability for all dates`;
      message = `${declinedCount}/${noOfTrainers} coaches declined`;
    } else {
      status = 'default';
      message = `0/${noOfTrainers} coaches contacted`;
    }

    return { status, message, info };
  };

  const changeCourseVenue = (venue, course) => {
    if (venue?.venueId === course?.venueId && course?.venueConfirmed === 1) {
      console.log('Venue already confirmed');
    } else {
      console.log('Update venue');

      const courseGroupApiData = {
        ...course,
        venueId: venue?.venueId,
        venueName: venue?.venue1,
        locationLon: venue?.locationLon,
        locationLat: venue?.locationLat,
        venueConfirmed: 1,
      };

      console.log('courseGroupApiData', courseGroupApiData);

      updateCourseGroup(courseGroupApiData, courseInfoData?.id)
        .then(({ data }) => {
          console.log('Course venue updated- ', data);

          getCourseGroupsWithTrainers()
            .then(({ data }) => {
              setConfirmedCourses(
                data
                  ?.filter(course => course?.courseGroup?.finalised === 1)
                  ?.reverse()
              );
              setpendingCourses(
                data
                  ?.filter(course => course?.courseGroup?.finalised !== 1)
                  ?.reverse()
              );
            })
            .catch(ex => {
              console.log('Error in getCourseGroupsWithTrainers', ex);
            });
        })
        .catch(err => {
          console.log('Error updating venue for course group');
        });
    }
  };

  const onStatusMenuChange = (
    status,
    type,
    instanceObj,
    Id,
    action,
    locationObj
  ) => {
    if (type === 'trainer') {
      if (action === 'update') {
        const data = instanceObj.find(val => val.trainerId === Id);
        const { courseGroupTrainersId, trainerId, courseGroupId } = data;
        const { latitude, longitude } = locationObj;

        const updatedData = {
          courseGroupTrainersId: courseGroupTrainersId,
          trainerId: trainerId,
          courseGroupId: courseGroupId,
          confirmed: status === 3 ? 1 : 0,
          declined: status === 4 ? 1 : 0,
          contacted: status === 2 ? 1 : 0,
          lastContacted:
            status === 1
              ? null
              : getDateFormat(new Date()?.toString(), 'yyyy-mm-dd'),
        };

        updateTrainersCourseGroup(updatedData)
          .then(data => {
            console.log('Trainer instance updated -', data);

            getCourseGroupsWithTrainers()
              .then(({ data }) => {
                setConfirmedCourses(
                  data
                    ?.filter(course => course?.courseGroup?.finalised === 1)
                    ?.reverse()
                );
                setpendingCourses(
                  data
                    ?.filter(course => course?.courseGroup?.finalised !== 1)
                    ?.reverse()
                );
                setTrainersCourseInstanceHash({
                  ...trainersCourseInstanceHash,
                  [courseGroupId]: [],
                });
                getTrainers(courseGroupId, latitude, longitude);
              })
              .catch(ex => {
                console.log('Error in getCourseGroupsWithTrainers', ex);
              });
          })
          .catch(ex =>
            console.log('Error in updateTrainersCourseInstance', ex)
          );
      }
      if (action === 'create') {
        const { courseGroupId } = instanceObj;
        const { latitude, longitude } = locationObj;

        const createData = {
          courseGroupTrainersId: 0,
          trainerId: Id,
          courseGroupId: courseGroupId,
          confirmed: status === 3 ? 1 : 0,
          declined: status === 4 ? 1 : 0,
          contacted: status === 2 ? 1 : 0,
          lastContacted: getDateFormat(new Date()?.toString(), 'yyyy-mm-dd'),
        };

        if (status !== 1) {
          createTrainersCourseGroup(createData)
            .then(data => {
              console.log('Trainer instance created -', data);

              getCourseGroupsWithTrainers()
                .then(({ data }) => {
                  setConfirmedCourses(
                    data
                      ?.filter(course => course?.courseGroup?.finalised === 1)
                      ?.reverse()
                  );
                  setpendingCourses(
                    data
                      ?.filter(course => course?.courseGroup?.finalised !== 1)
                      ?.reverse()
                  );

                  setTrainersCourseInstanceHash({
                    ...trainersCourseInstanceHash,
                    [courseGroupId]: [],
                  });
                  getTrainers(courseGroupId, latitude, longitude);
                })
                .catch(ex => {
                  console.log('Error in getCourseGroupsWithTrainers', ex);
                });
            })
            .catch(ex => console.log('Error in createTrainersCourseGroup', ex));
        }
      }
    }

    if (type === 'venue') {
      if (action === 'update') {
        const data = instanceObj.find(val => val.venueId === Id);

        console.log('Venue data filtered - ', data);
        const { venuesCourseInstanceId, venueId, courseInstanceId } = data;
        const { latitude, longitude } = locationObj;

        const updatedData = {
          venuesCourseInstanceId,
          venueId,
          courseInstanceId,
          confirmed: status === 3 ? 1 : 0,
          declined: status === 4 ? 1 : 0,
          contacted: status === 2 ? 1 : 0,
          lastContacted: getDateFormat(new Date()?.toString(), 'yyyy-mm-dd'),
        };

        updateVenuesCourseInstance(updatedData)
          .then(data => {
            getCourseInstanceDetails()
              .then(({ data }) => {
                setConfirmedCourses(
                  data?.filter(course => course.finalised === 1)?.reverse()
                );
                setpendingCourses(
                  data?.filter(course => course.finalised !== 1).reverse()
                );
                setVenuesCourseInstanceHash({
                  ...venuesCourseInstanceHash,
                  [courseInstanceId]: [],
                });
                getVenues(courseInstanceId, latitude, longitude);
              })
              .catch(ex =>
                console.log('Error in getCourseInstanceDetails', ex)
              );
          })
          .catch(ex => console.log('Error in updateVenuesCourseInstance', ex));
      }

      if (action === 'create') {
        const { courseInstanceId } = instanceObj;
        const { latitude, longitude } = locationObj;

        const createData = {
          venuesCourseInstanceId: 0,
          venueId: Id,
          courseInstanceId,
          confirmed: status === 3 ? 1 : 0,
          declined: status === 4 ? 1 : 0,
          contacted: status === 2 ? 1 : 0,
          lastContacted: getDateFormat(new Date()?.toString(), 'yyyy-mm-dd'),
        };

        createVenueCourseInstance(createData)
          .then(data => {
            getCourseInstanceDetails()
              .then(({ data }) => {
                setConfirmedCourses(
                  data?.filter(course => course.finalised === 1)?.reverse()
                );
                setpendingCourses(
                  data?.filter(course => course.finalised !== 1).reverse()
                );
                setVenuesCourseInstanceHash({
                  ...venuesCourseInstanceHash,
                  [courseInstanceId]: [],
                });
                getVenues(courseInstanceId, latitude, longitude);
              })
              .catch(ex =>
                console.log('Error in getCourseInstanceDetails', ex)
              );
          })
          .catch(ex => console.log('Error in updateVenuesCourseInstance', ex));
      }
    }
  };

  const getVenueAddress = venueId => {
    if (venueId === 0) {
      return '—';
    } else {
      const venue = venues?.find(data => data?.venueId === venueId);

      return `${venue?.address},${venue?.postCode}+40miles`;
    }
  };

  const getCourseName = courseId => {
    if (courseId === 0) {
      return '—';
    } else {
      const course = courses?.find(data => data?.courseId === courseId);

      return course?.name;
    }
  };

  const isEligibleVenue = venueConfirmed => {
    if (venueFilters?.length === 0 || venueFilters?.includes('ALL')) {
      return true;
    } else if (venueConfirmed === 0 && venueFilters?.includes('PENDING')) {
      return true;
    } else if (venueConfirmed !== 0 && venueFilters?.includes('CONFIRMED')) {
      return true;
    } else {
      return false;
    }
  };

  const isEligibleCoach = status => {
    if (coachFilters?.length === 0 || coachFilters?.includes('ALL')) {
      return true;
    } else if (coachFilters?.includes(status?.toUpperCase())) {
      return true;
    } else if (
      status?.toLowerCase() === 'default' &&
      coachFilters?.includes('NOT CONTACTED')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isEligibleCourse = courseType => {
    if (courseFilters?.length === 0 || courseFilters?.includes('ALL')) {
      return true;
    } else if (courseFilters?.includes(courseType?.toUpperCase())) {
      return true;
    } else {
      return false;
    }
  };

  const isEligibleDate = date => {
    const courseDate = new Date(date);
    courseDate?.setHours(0, 0, 0, 0);
    const frmDt = new Date(fromDate);
    frmDt?.setHours(0, 0, 0, 0);
    const toDt = new Date(toDate);
    toDt?.setHours(0, 0, 0, 0);

    if (fromDate === '' && toDate === '') {
      return true;
    } else if (fromDate !== '' && toDate === '' && courseDate >= frmDt) {
      return true;
    } else if (toDate !== '' && fromDate === '' && courseDate <= toDt) {
      return true;
    } else if (
      fromDate !== '' &&
      toDate !== '' &&
      courseDate >= frmDt &&
      courseDate <= toDt
    ) {
      return true;
    } else {
      return false;
    }
  };

  const filterVenues = (element, option) => {
    if (
      element === null &&
      (option?.toUpperCase()?.trim() === 'ALL' ||
        option?.toUpperCase()?.startsWith('ALL'))
    ) {
      setvenueFilters([]);
    } else if (element?.target?.checked) {
      if (
        option?.toUpperCase()?.trim() === 'ALL' ||
        option?.toUpperCase()?.startsWith('ALL')
      )
        setvenueFilters([]);
      else {
        if (!venueFilters?.includes(option))
          setvenueFilters([...venueFilters, option]);
      }
    } else {
      setvenueFilters(
        venueFilters?.filter(
          data => data.toUpperCase() !== option?.toUpperCase()
        )
      );
    }
  };

  const filterCoaches = (element, option) => {
    if (
      element === null &&
      (option?.toUpperCase()?.trim() === 'ALL' ||
        option?.toUpperCase()?.startsWith('ALL'))
    ) {
      setcoachFilters([]);
    } else if (element?.target?.checked) {
      if (option?.toUpperCase()?.trim() === 'ALL') setcoachFilters([]);
      else {
        if (!coachFilters?.includes(option))
          setcoachFilters([...coachFilters, option?.toUpperCase()]);
      }
    } else {
      setcoachFilters(
        coachFilters?.filter(
          data => data?.toUpperCase() !== option?.toUpperCase()
        )
      );
    }
  };

  const filterCourses = (element, option) => {
    if (
      element === null &&
      (option?.toUpperCase()?.trim() === 'ALL' ||
        option?.toUpperCase()?.startsWith('ALL'))
    ) {
      setcourseFilters([]);
    } else if (element?.target?.checked) {
      if (
        option?.toUpperCase()?.trim() === 'ALL' ||
        option?.toUpperCase()?.startsWith('ALL')
      )
        setcourseFilters([]);
      else {
        if (!courseFilters?.includes(option))
          setcourseFilters([...courseFilters, option?.toUpperCase()]);
      }
    } else {
      setcourseFilters(
        courseFilters?.filter(
          data => data?.toUpperCase() !== option?.toUpperCase()
        )
      );
    }
  };

  const filterCourseInstances = (element, title, option) => {
    console.log('filter element clicked -', element);
    console.log('filter title -', title);
    console.log('filter option selected -', option);

    if (title?.toLowerCase()?.includes('venue')) {
      filterVenues(element, option);
    } else if (title?.toLowerCase()?.includes('coach')) {
      filterCoaches(element, option);
    } else if (title?.toLowerCase()?.includes('course')) {
      filterCourses(element, option);
    } else if (title?.toLowerCase()?.includes('all')) {
      filterVenues(element, option);
      filterCoaches(element, option);
      filterCourses(element, option);
    }

    setResetFilters(false);
  };

  const updateCourse = () => {
    console.log('Confirming course');

    // const todayDt = new Date();
    // const start = new Date(startDate);
    // const end = new Date(endDate);
    // const diffTime = Math.abs(start - end);
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const selectedDates = courseInfoData?.courseDates;

    const selectedDatesFormatted = selectedDates?.map(date =>
      getDateFormat(date?.date, 'yyyy-mm-dd')
    );
    const existingDatesFormatted = courseDatesHash[courseInfoData?.id]?.map(
      date => getDateFormat(date.courseDate, 'yyyy-mm-dd')
    );

    console.log('Selected dates - ', selectedDatesFormatted);
    console.log('Existing dates', existingDatesFormatted);
    console.log(
      'Is date same? ',
      JSON.stringify(selectedDatesFormatted) ===
        JSON.stringify(existingDatesFormatted)
    );

    const datesUpdated =
      JSON.stringify(selectedDatesFormatted) ===
      JSON.stringify(existingDatesFormatted)
        ? false
        : true;

    const courseGroupApiData = {
      courseGroupId: courseInfoData?.id,
      courseId: courseInfoData?.courseId,
      venueId: courseInfoData?.courseLocationId,
      courseGroupName: courseInfoData?.courseGroupName,
      courseName: courseInfoData?.courseType,
      venueName: courseInfoData?.courseLocation,
      plannedAttendance: 0,
      noTrainers: courseInfoData?.noOfCoach,
      noRunDays: 0,
      season: 0,
      startDate: courseInfoData?.startDate,
      endDate: courseInfoData?.endDate,
      resourced: 0,
      finalised: 0,
      locationLon: courseInfoData?.locationLon,
      locationLat: courseInfoData?.locationLat,
      notes: '',
      venueConfirmed: courseInfoData?.venueConfirmed,
    };

    console.log('courseGroupApiData', courseGroupApiData);

    updateCourseGroup(courseGroupApiData, courseInfoData?.id)
      .then(({ data }) => {
        console.log('Course group API updated- ', data);

        //Updating course instances if dates are updated

        if (datesUpdated === true) {
          const promises = selectedDates?.map(date => {
            if (date?.courseGroupId && date?.courseInstanceId) {
              const courseIntancedata = {
                courseInstanceId: date?.courseInstanceId,
                courseGroupId: date?.courseGroupId,
                venueId: 0,
                courseDate: date?.date,
                resourced: 0,
                finalised: 0,
                notes: '',
              };

              console.log(
                'courseIntancedata to be updated - ',
                courseIntancedata
              );

              return updateCourseInstance(
                courseIntancedata,
                date?.courseInstanceId
              )
                .then(({ data }) => {
                  console.log('Course instance after updating - ', data);
                  //createVenueInstance(data);
                })
                .catch(ex => {
                  console.log('error in updating course instance -', ex);
                });
            } else {
              const courseIntancedata = {
                courseInstanceId: 0,
                courseGroupId: courseInfoData?.id,
                venueId: 0,
                courseDate: date?.date,
                resourced: 0,
                finalised: 0,
                notes: '',
              };

              console.log(
                'courseIntancedata to be created - ',
                courseIntancedata
              );

              return courseInstanceApi(courseIntancedata)
                .then(({ data }) => {
                  console.log('Course instance after creating - ', data);
                  //createVenueInstance(data);
                })
                .catch(ex => {
                  console.log('error in creating course instance -', ex);
                });
            }
          });

          console.log('Intances to delete - ', currentCourseInstanceDelete);

          const promise2 = currentCourseInstanceDelete?.map(instance => {
            console.log('Intance to be deleted -', instance);

            return deleteCourseInstance(instance)
              .then(({ data }) => {
                console.log('Course instance deleted -', data);
              })
              .catch(ex => {
                console.log('Failed to delete course instance-', ex);
              });
          });

          console.log('Promise 1 -', promises);
          console.log('Promise 2 -', promise2);
          return Promise.all([promises, promise2]).then(() => {
            console.log('All course instances created/updated');
            showCourseInfoModal(false);
            loadCourseDates(courseInfoData?.id, true);
            getCourseGroupsWithTrainers()
              .then(({ data }) => {
                setConfirmedCourses(
                  data
                    ?.filter(course => course?.courseGroup?.finalised === 1)
                    ?.reverse()
                );
                setpendingCourses(
                  data
                    ?.filter(course => course?.courseGroup?.finalised !== 1)
                    ?.reverse()
                );
                getCourseGroupDetails(courseInfoData?.id);
              })
              .catch(ex => {
                console.log('Error in getCourseGroupsWithTrainers', ex);
              });
          });
        } else {
          console.log('Course group updates. No date changed or updated');
          showCourseInfoModal(false);
          getCourseGroupsWithTrainers()
            .then(({ data }) => {
              setConfirmedCourses(
                data
                  ?.filter(course => course?.courseGroup?.finalised === 1)
                  ?.reverse()
              );
              setpendingCourses(
                data
                  ?.filter(course => course?.courseGroup?.finalised !== 1)
                  ?.reverse()
              );
            })
            .catch(ex => {
              console.log('Error in getCourseGroupsWithTrainers', ex);
            });
        }
      })
      .catch(ex => {
        console.log('error in updating course group -', ex);
      });
  };

  const getCoachname = (trainerId, trainerArr) => {
    const trainerObj = trainerArr?.find(
      coach => coach?.trainerId === trainerId
    );

    return trainerObj?.fullName;
  };

  const toggleTrainerCourseInstance = (
    trainerInstanceObj,
    toggleStatus,
    courseGroupId
  ) => {
    console.log('trainerInstanceObj -', trainerInstanceObj);
    console.log('toggleStatus -', toggleStatus);

    let trainerInstanceData;

    if (toggleStatus === 'enable') {
      trainerInstanceData = {
        ...trainerInstanceObj,
        confirmed: 1,
        declined: 0,
      };
    } else {
      trainerInstanceData = {
        ...trainerInstanceObj,
        confirmed: 0,
        declined: 1,
      };
    }

    updateTrainersCourseInstance(trainerInstanceData)
      .then(({ data }) => {
        console.log('Updated trainer course instance -', data);

        getCourseGroupsWithTrainers()
          .then(({ data }) => {
            setConfirmedCourses(
              data
                ?.filter(course => course?.courseGroup?.finalised === 1)
                ?.reverse()
            );
            setpendingCourses(
              data
                ?.filter(course => course?.courseGroup?.finalised !== 1)
                ?.reverse()
            );

            getCourseGroupDetails(courseGroupId);
          })
          .catch(ex => {
            console.log('Error in getCourseGroupsWithTrainers', ex);
          });
      })
      .catch(err =>
        console.log('Error while updating trainer cours instance -', err)
      );
  };

  const deleteCourse = () => {
    console.log('Course group to be deleted - ', currentCourseDelete);
    deleteCourseGroup(currentCourseDelete)
      .then(({ deletedata }) => {
        console.log('Deleted course group -', deletedata);
        showCourseDeleteModal(false);
        setCurrentCourseDelete(null);
        getCourseGroupsWithTrainers()
          .then(({ data }) => {
            setConfirmedCourses(
              data
                ?.filter(course => course?.courseGroup?.finalised === 1)
                ?.reverse()
            );
            setpendingCourses(
              data
                ?.filter(course => course?.courseGroup?.finalised !== 1)
                ?.reverse()
            );
          })
          .catch(ex => {
            console.log(
              'Error in getCourseGroupsWithTrainers after delete',
              ex
            );
          });
      })
      .catch(ex => {
        console.log('Error in delete course groups', ex);
      });
  };

  const buildCourses = type => {
    let courseList, title, subtitle;

    if (type === 'pending') {
      courseList = pendingCourses;
      title = 'Pending courses';
      subtitle = `There are ${pendingCourses?.length} inflight courses`;
    } else if (type === 'confirmed') {
      courseList = confirmedCourses;
      title = 'Confirmed courses';
      subtitle = `There are ${confirmedCourses?.length} confirmed courses`;
    } else {
    }

    let rowCount = 0;

    return (
      <div id={`${type}_courses`}>
        <div css={styles.topadjustment}>
          <div css={styles.courses}>{title}</div>
          <div css={styles.confirmedcourse}>{subtitle}</div>
        </div>
        {
          <div id="courseTable" css={styles.courseTable}>
            {courseList?.map((courseWithTrainers, index) => {
              const course = courseWithTrainers?.courseGroup;
              const trainers = courseWithTrainers?.trainers;
              const isEven = index % 2 === 0;
              const notes =
                notesCourseInstanceHash[course?.courseGroupId]?.notes;
              let notesList = [];
              if (notes !== null && notes !== '') {
                notesList = notes?.split('|').reverse();
              }

              let nearestVenuesLimit = venueListSize;

              let nearestTrainersLimit = trainerListSize;

              let trainerCount = 0;
              let venueCount = 0;
              let isEligible = false;

              const courseName = getCourseName(course?.courseId);

              if (
                isEligibleVenue(course?.venueConfirmed) &&
                isEligibleCoach(
                  getCoachResponseData(
                    trainers,
                    course?.noTrainers,
                    course?.resourced
                  )?.status
                ) &&
                isEligibleCourse(courseName) &&
                isEligibleDate(course?.startDate)
              ) {
                isEligible = true;
                rowCount++;
              }

              let nearestTrainers =
                trainersCourseInstanceHash[course?.courseGroupId]
                  ?.trainersNearby;

              let nearestVenues =
                venuesCourseInstanceHash[course?.courseGroupId]?.venuesNearby;

              if (
                trainersCourseInstanceHash &&
                trainersCourseInstanceHash[course?.courseGroupId]?.trainers
                  ?.length > 0
              ) {
                nearestTrainersLimit =
                  trainerListSize -
                  trainersCourseInstanceHash[course?.courseGroupId]?.trainers
                    ?.length;

                const trainerIds = trainersCourseInstanceHash[
                  course?.courseGroupId
                ]?.trainers.map(trainer => trainer.trainerId);

                nearestTrainers = trainersCourseInstanceHash[
                  course?.courseGroupId
                ]?.trainersNearby?.filter(trainer => {
                  return !trainerIds.includes(trainer?.trainerId);
                });
              }

              let confirmedTrainers = [];

              if (
                trainersCourseInstanceHash &&
                trainersCourseInstanceHash[course?.courseGroupId]
                  ?.courseGroupTrainer?.length > 0
              ) {
                confirmedTrainers = trainersCourseInstanceHash[
                  course?.courseGroupId
                ]?.courseGroupTrainer?.filter(
                  trainer => trainer?.confirmed === 1
                );
                console.log(
                  `Confirmed trainers for course-${course?.courseGroupId} - ${confirmedTrainers?.length}`
                );
              }

              if (
                venuesCourseInstanceHash &&
                venuesCourseInstanceHash[course?.courseGroupId]?.venues
                  ?.length > 0
              ) {
                nearestVenuesLimit =
                  venueListSize -
                  venuesCourseInstanceHash[course?.courseGroupId]?.venues
                    ?.length;

                const venueIds = venuesCourseInstanceHash[
                  course?.courseGroupId
                ]?.venues.map(venue => venue?.venueId);

                nearestVenues = venuesCourseInstanceHash[
                  course?.courseGroupId
                ]?.venuesNearby?.filter(venue => {
                  return !venueIds.includes(venue?.venueId);
                });
              }

              return (
                <>
                  {isEligible === true && (
                    <div
                      css={styles.tables}
                      id={`course_${course?.courseGroupId}`}
                    >
                      <div css={styles.course}>{course?.courseGroupName}</div>
                      <div css={styles.coursetypes}>
                        <img src="/images/common/list/mask.svg" />
                        <div css={styles.coursetypes1}>{courseName}</div>
                      </div>
                      <span css={styles.datePillContainer}>
                        <div
                          css={styles.month}
                          onClick={() =>
                            showHideCourseDates(course?.courseGroupId)
                          }
                          className="toggleDates"
                        >
                          <div css={styles.image} className="toggleDates">
                            <DateIconSmall
                              color={'#FFF'}
                              className="toggleDates"
                            />
                            <div css={styles.june} className="toggleDates">
                              {`${getDateFormat(
                                course?.startDate
                              )} — ${getDateFormat(course?.endDate)}`}
                            </div>
                          </div>
                        </div>
                        {courseDatesShowId === course?.courseGroupId && (
                          <div css={styles.courseTooltip} ref={tooltipRef}>
                            <label className="editTooltipLbl">
                              Course dates
                            </label>
                            <div css={styles.dateContainer}>
                              {courseDatesHash[course?.courseGroupId] &&
                                courseDatesHash[course?.courseGroupId].length >
                                  0 &&
                                courseDatesHash[course?.courseGroupId].map(
                                  dateItem => {
                                    return (
                                      <div css={styles.datePill}>
                                        <DateIconSmall />
                                        {getDateFormat(dateItem?.courseDate)}
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        )}
                      </span>

                      <div css={styles.city(course?.venueId)}>
                        {getVenueAddress(course?.venueId)}
                      </div>
                      <div>
                        <div
                          css={styles.coaches(
                            getCoachResponseData(
                              trainers,
                              course?.noTrainers,
                              course?.resourced
                            )?.status
                          )}
                          title={
                            getCoachResponseData(
                              trainers,
                              course?.noTrainers,
                              course?.resourced
                            )?.info
                          }
                        >
                          <div
                            css={styles.coach1(
                              getCoachResponseData(
                                trainers,
                                course?.noTrainers,
                                course?.resourced
                              )?.status
                            )}
                          >
                            {
                              getCoachResponseData(
                                trainers,
                                course?.noTrainers,
                                course?.resourced
                              )?.message
                            }
                          </div>
                        </div>
                      </div>
                      <div css={styles.venue}>
                        {course?.venueConfirmed === 0 ? (
                          <div css={styles.venue1}>
                            <img src="/images/common/list/bell-green.svg" />
                            Contact venues
                          </div>
                        ) : (
                          <div css={styles.venue1}>
                            <img src="/images/common/list/tick.svg" />
                            Venue confirmed
                          </div>
                        )}
                      </div>

                      <button
                        css={styles.btnArrow}
                        onClick={() =>
                          showHideCourseDetails(
                            course?.courseGroupId,
                            course?.locationLat,
                            course?.locationLon,
                            course?.venueConfirmed,
                            course?.venueId
                          )
                        }
                      >
                        {courseDetailsShowHash[course?.courseGroupId] &&
                        courseDetailsShowHash[course?.courseGroupId] ===
                          true ? (
                          <img src="/images/common/list/buttonup.svg" />
                        ) : (
                          <img src="/images/common/list/button.svg" />
                        )}
                      </button>
                      <div style={{ position: 'relative' }}>
                        <button
                          css={styles.courseEditBtn}
                          onClick={e =>
                            showHideCourseEdit(course?.courseGroupId, e)
                          }
                          className="editCourseToggle"
                        >
                          <Ellipsis
                            className="editCourseToggle"
                            css={styles.ellipsisSvg}
                          />
                        </button>
                        {courseEditShowId === course?.courseGroupId && (
                          <div css={styles.courseTooltip} ref={editCourseRef}>
                            <label className="editTooltipLbl">
                              Edit course
                            </label>
                            <button
                              className="editCourseBtn"
                              onClick={() => {
                                handleEditCourse({
                                  id: course?.courseGroupId,
                                  courseGroupName: course?.courseGroupName,
                                  courseId: course?.courseId,
                                  courseType: courseName,
                                  noOfCoach: course?.noTrainers,
                                  courseDates: courseDateList,
                                  courseLocation: course?.venueName,
                                  courseLocationId: course?.venueId,
                                  locationLon: course?.locationLon,
                                  locationLat: course?.locationLat,
                                  startDate: course?.startDate,
                                  endDate: course?.endDate,
                                  venueConfirmed:
                                    course?.venueConfirmed === 1 ? true : false,
                                });
                              }}
                            >
                              Edit
                            </button>
                            <button
                              className="deleteCourseBtn"
                              onClick={() => {
                                handleDeleteCourse(course);
                              }}
                            >
                              <Delete />
                              Delete course
                            </button>
                          </div>
                        )}
                      </div>
                      {courseDetailsShowHash[course?.courseGroupId] &&
                        courseDetailsShowHash[course?.courseGroupId] ===
                          true && (
                          <>
                            {trainersCourseInstanceHash[
                              course?.courseGroupId
                            ] &&
                            (trainersCourseInstanceHash[course?.courseGroupId]
                              ?.trainers?.length > 0 ||
                              nearestTrainers?.length > 0) ? (
                              <div
                                css={styles.firstbox(isEven)}
                                id={`courseDetails_${course?.courseInstanceId}`}
                                className="innerBox"
                              >
                                <div css={styles.titleadjustment}>
                                  <div css={styles.title}>
                                    Coach developers list
                                  </div>
                                  <div css={styles.border}></div>
                                </div>
                                <div css={styles.head}>
                                  <div css={styles.name}>Name</div>
                                  <div css={styles.phone}>Phone</div>
                                  <div css={styles.email}>Email</div>
                                  <div css={styles.date}>Date contacted</div>
                                  <div css={styles.phone}>Status</div>
                                </div>
                                {trainersCourseInstanceHash[
                                  course?.courseGroupId
                                ]?.trainers?.map((coach, index) => {
                                  const date = getDatefromData(
                                    coach?.trainerId,
                                    trainersCourseInstanceHash[
                                      course?.courseGroupId
                                    ]?.courseGroupTrainer,
                                    'trainer'
                                  );

                                  const status = getStatusfromData(
                                    coach?.trainerId,
                                    trainersCourseInstanceHash[
                                      course?.courseGroupId
                                    ]?.courseGroupTrainer,
                                    'trainer'
                                  );

                                  return (
                                    <>
                                      <div css={styles.borders} />
                                      <div css={styles.content}>
                                        <div css={styles.width}>
                                          <img
                                            //src="/images/common/list/Jorge Fernandez.svg"
                                            src="/images/common/list/user.png"
                                            className="innerimg"
                                            css={styles.coachIcon}
                                          />
                                          <div css={styles.name1}>
                                            {coach?.fullName}
                                          </div>
                                        </div>
                                        <div css={styles.width2}>
                                          <div css={styles.number}>
                                            {coach?.contactNumber}
                                          </div>
                                        </div>
                                        <div css={styles.width1}>
                                          <div css={styles.emailid}>
                                            {coach?.email}
                                          </div>
                                        </div>
                                        <div css={styles.dates}>
                                          {date === '' ? (
                                            <span>—</span>
                                          ) : (
                                            <div css={styles.month}>
                                              <div css={styles.image}>
                                                <img src="/images/common/list/month.svg" />
                                                <div css={styles.june}>
                                                  {date}
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        {/* {
                                          status === 3 ?
                                          <button
                                          css={styles.venueSelectBtn('confirmed')}
                                        >
                                          {'Confirmed'}
                                        </button>
                                          : */}
                                        <CustomStatusDropDown
                                          id="trainerStatusSelect1"
                                          name="trainerStatusSelect1"
                                          class="trainerStatusSelect"
                                          optionsList={statusList}
                                          selectedDefaultValue={status}
                                          onChange={onStatusMenuChange}
                                          type="trainer"
                                          refObject={
                                            trainersCourseInstanceHash[
                                              course?.courseGroupId
                                            ]?.courseGroupTrainer
                                          }
                                          locationObj={{
                                            latitude: course?.locationLat,
                                            longitude: course?.locationLon,
                                          }}
                                          refId={coach?.trainerId}
                                          ref={null}
                                          action="update"
                                        />
                                        {/* } */}
                                      </div>
                                    </>
                                  );
                                })}

                                {nearestTrainers?.map((coach, index) => {
                                  trainerCount++;
                                  if (trainerCount >= nearestTrainersLimit)
                                    return;
                                  return (
                                    <>
                                      <div css={styles.borders} />
                                      <div css={styles.content}>
                                        <div css={styles.width}>
                                          <img
                                            //src="/images/common/list/Jorge Fernandez.svg"
                                            src="/images/common/list/user.png"
                                            className="innerimg"
                                            css={styles.coachIcon}
                                          />
                                          <div css={styles.name1}>
                                            {coach?.fullName}
                                          </div>
                                        </div>
                                        <div css={styles.width2}>
                                          <div css={styles.number}>
                                            {coach?.contactNumber}
                                          </div>
                                        </div>
                                        <div css={styles.width1}>
                                          <div css={styles.emailid}>
                                            {coach?.email}
                                          </div>
                                        </div>
                                        <div css={styles.dates}>
                                          <span>—</span>
                                        </div>
                                        <CustomStatusDropDown
                                          id="trainerStatusSelect1"
                                          name="trainerStatusSelect1"
                                          class="trainerStatusSelect"
                                          optionsList={statusList}
                                          selectedDefaultValue={1}
                                          onChange={onStatusMenuChange}
                                          type="trainer"
                                          refObject={{
                                            courseGroupId:
                                              course?.courseGroupId,
                                          }}
                                          locationObj={{
                                            latitude: course?.locationLat,
                                            longitude: course?.locationLon,
                                          }}
                                          refId={coach?.trainerId}
                                          ref={null}
                                          action="create"
                                        />
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            ) : (
                              <div css={styles.firstbox(isEven)}>
                                <Loader message="Loading trainers..."></Loader>
                              </div>
                            )}
                            {confirmedTrainers?.length > 0 && (
                              <div
                                css={styles.firstbox(isEven)}
                                id={`courseDeveloperSchedule_${course?.courseGroupId}`}
                                className="innerBox"
                                style={{ gap: 0 }}
                              >
                                <div
                                  css={styles.titleadjustment}
                                  style={{ marginBottom: '8px' }}
                                >
                                  <div css={styles.title}>
                                    Coach developers schedule
                                  </div>
                                  <div css={styles.border}></div>
                                </div>
                                <div css={styles.developerSceduleArea}>
                                  <div css={styles.developerScheduleNames}>
                                    {confirmedTrainers?.map((coach, index) => {
                                      return (
                                        <>
                                          <div
                                            css={styles.content4(index === 0)}
                                          >
                                            <div css={styles.width}>
                                              <img
                                                //src="/images/common/list/Jorge Fernandez.svg"
                                                src="/images/common/list/user.png"
                                                className="innerimg"
                                                css={styles.coachIcon}
                                              />
                                              <div css={styles.name1}>
                                                {getCoachname(
                                                  coach?.trainerId,
                                                  trainersCourseInstanceHash[
                                                    course?.courseGroupId
                                                  ]?.trainers
                                                )}
                                              </div>
                                            </div>
                                            <div
                                              css={styles.mask(isEven)}
                                            ></div>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                  <div css={styles.developerScheduleCourses}>
                                    {confirmedTrainers?.map((coach, index) => {
                                      return (
                                        <>
                                          <div
                                            css={styles.content2(index === 0)}
                                          >
                                            {courseGroupDetailHash[
                                              course?.courseGroupId
                                            ]?.courseInstanceFull?.map(
                                              instance => {
                                                const trainersCourseInstance =
                                                  instance?.trainersCourseInstance?.find(
                                                    trainer =>
                                                      trainer?.trainerId ===
                                                      coach?.trainerId
                                                  );

                                                const isConfirmed =
                                                  trainersCourseInstance?.confirmed ===
                                                  1;

                                                return (
                                                  <button
                                                    css={styles.month}
                                                    style={{
                                                      cursor: 'pointer',
                                                      color: isConfirmed
                                                        ? '#ffffff'
                                                        : '#4f4f4e',
                                                    }}
                                                    onClick={() =>
                                                      toggleTrainerCourseInstance(
                                                        trainersCourseInstance,
                                                        isConfirmed
                                                          ? 'disable'
                                                          : 'enable',
                                                        course?.courseGroupId
                                                      )
                                                    }
                                                  >
                                                    <DateIconSmall
                                                      color={
                                                        isConfirmed
                                                          ? '#20BE56'
                                                          : '#4f4f4e'
                                                      }
                                                    />
                                                    {getDateFormat(
                                                      instance?.courseInstance
                                                        ?.courseDate
                                                    )}
                                                  </button>
                                                );
                                              }
                                            )}
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                  <div css={styles.developerScheduleNames}>
                                    {confirmedTrainers?.map((coach, index) => {
                                      return (
                                        <>
                                          <div
                                            css={styles.content4(index === 0)}
                                          >
                                            <div
                                              css={styles.maskRight(isEven)}
                                            ></div>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            )}
                            {venuesCourseInstanceHash[course?.courseGroupId] &&
                            (venuesCourseInstanceHash[course?.courseGroupId]
                              ?.venues?.length > 0 ||
                              nearestVenues?.length > 0) ? (
                              <div
                                css={styles.secondbox(isEven)}
                                className="innerBox"
                              >
                                <div css={styles.titleadjustment}>
                                  <div css={styles.title}>Venue list</div>
                                  <div css={styles.border}></div>
                                </div>
                                <div css={styles.head}>
                                  <div css={styles.name}>Name</div>
                                  <div css={styles.phone}>Phone</div>
                                  <div css={styles.email}>Email</div>
                                  <div css={styles.date}>Date contacted</div>
                                  <div css={styles.phone}>Status</div>
                                </div>

                                {/*Venues instance commented*/}
                                {/* {venuesCourseInstanceHash[
                                  course?.courseGroupId
                                ].venues?.map((venue, index) => {
                                  const date = getDatefromData(
                                    venue?.venueId,
                                    venuesCourseInstanceHash[
                                      course?.courseGroupId
                                    ]?.venuesCourseInstance,
                                    'venue'
                                  );

                                  const status = getStatusfromData(
                                    venue?.venueId,
                                    venuesCourseInstanceHash[
                                      course?.courseGroupId
                                    ]?.venuesCourseInstance,
                                    'venue'
                                  );

                                  return (
                                    <>
                                      <div css={styles.borders} />
                                      <div css={styles.content}>
                                        <div css={styles.width}>
                                          <div css={styles.name1}>
                                            {`${venue?.venue1}, ${venue?.region}, ${venue?.address}, ${venue?.postCode}`}
                                          </div>
                                        </div>
                                        <div css={styles.width2}>
                                          <div css={styles.number}>
                                            {venue?.phone}
                                          </div>
                                        </div>
                                        <div css={styles.width1}>
                                          <div css={styles.emailid}>
                                            {venue?.email}
                                          </div>
                                        </div>
                                        <div css={styles.dates}>
                                          {date === '' ? (
                                            <span>—</span>
                                          ) : (
                                            <div css={styles.month}>
                                              <div css={styles.image}>
                                                <img src="/images/common/list/month.svg" />
                                                <div css={styles.june}>
                                                  {date}
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        <CustomStatusDropDown
                                          id="trainerStatusSelect1"
                                          name="trainerStatusSelect1"
                                          class="trainerStatusSelect"
                                          optionsList={statusList}
                                          selectedDefaultValue={status}
                                          onChange={onStatusMenuChange}
                                          type="venue"
                                          refObject={
                                            venuesCourseInstanceHash[
                                              course?.courseGroupId
                                            ]?.venuesCourseInstance
                                          }
                                          locationObj={{
                                            latitude: course?.locationLat,
                                            longitude: course?.locationLon,
                                          }}
                                          refId={venue?.venueId}
                                          ref={null}
                                          action="update"
                                        />
                                      </div>
                                    </>
                                  );
                                })} */}

                                {/* {
                                  course?.venueConfirmed === 0
                                } */}
                                {nearestVenues?.map((venue, index) => {
                                  venueCount++;
                                  let status = 'default';

                                  if (venue?.venueId === course?.venueId) {
                                    if (course?.venueConfirmed === 1) {
                                      status = 'confirmed';
                                    } else {
                                      status = 'pending';
                                    }
                                  }
                                  if (venueCount >= nearestVenuesLimit) return;
                                  return (
                                    <>
                                      <div css={styles.borders} />
                                      <div css={styles.content}>
                                        <div css={styles.width}>
                                          <div css={styles.name1}>
                                            {`${venue?.venue1}, ${venue?.region}, ${venue?.address}, ${venue?.postCode}`}
                                          </div>
                                        </div>
                                        <div css={styles.width2}>
                                          <div css={styles.number}>
                                            {venue?.phone}
                                          </div>
                                        </div>
                                        <div css={styles.width1}>
                                          <div css={styles.emailid}>
                                            {venue?.email}
                                          </div>
                                        </div>
                                        <div css={styles.dates}>
                                          <span>—</span>
                                        </div>
                                        {/* <CustomStatusDropDown
                                          id="venueStatusSelect1"
                                          name="venueStatusSelect1"
                                          class="trainerStatusSelect"
                                          optionsList={statusList}
                                          selectedDefaultValue={1}
                                          onChange={onStatusMenuChange}
                                          type="venue"
                                          refObject={{
                                            courseInstanceId:
                                              course?.courseGroupId,
                                          }}
                                          locationObj={{
                                            latitude: course?.locationLat,
                                            longitude: course?.locationLon,
                                          }}
                                          refId={venue?.venueId}
                                          ref={null}
                                          action="create"
                                        /> */}
                                        <button
                                          css={styles.venueSelectBtn(status)}
                                          onClick={() =>
                                            changeCourseVenue(venue, course)
                                          }
                                        >
                                          {venue?.venueId === course?.venueId &&
                                          course?.venueConfirmed === 1
                                            ? 'Confirmed venue'
                                            : 'Confirm venue'}
                                        </button>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            ) : (
                              <div css={styles.secondbox(isEven)}>
                                <Loader message="Loading venues..."></Loader>
                              </div>
                            )}
                            {
                              <div
                                css={styles.thirdbox(isEven)}
                                className="innerBox"
                              >
                                <div css={styles.titleadjustment}>
                                  <div css={styles.title}>
                                    Course instance notes
                                  </div>
                                  <div css={styles.border}></div>
                                </div>
                                <div css={styles.box1}>
                                  <div css={styles.notesLeftSection}>
                                    <div css={styles.note}>Add a note</div>
                                    <div css={styles.innerborder}>
                                      <div css={styles.outer}>
                                        <textarea
                                          css={styles.noteArea}
                                          placeholder="Add note text"
                                          maxLength={300}
                                          value={
                                            notesInstanceHash[
                                              course?.courseGroupId
                                            ]
                                          }
                                          onChange={e => {
                                            setNotes(
                                              e.target.value,
                                              course?.courseGroupId
                                            );
                                          }}
                                        />
                                        <div css={styles.pencil}>
                                          <img src="/images/common/list/pencil.svg" />
                                        </div>
                                        <div css={styles.bottom}>
                                          {
                                            notesInstanceHash[
                                              course?.courseGroupId
                                            ]?.length
                                          }{' '}
                                          / 300
                                        </div>
                                      </div>
                                    </div>
                                    <button
                                      css={styles.whiteborder(
                                        notesInstanceHash[course?.courseGroupId]
                                          ?.length === 0
                                      )}
                                      onClick={() =>
                                        notesInstanceHash[course?.courseGroupId]
                                          ?.length > 0 &&
                                        updateNotes(course?.courseGroupId)
                                      }
                                    >
                                      Save note
                                    </button>
                                  </div>
                                  <div css={styles.content3}>
                                    <div css={styles.note}>Previous notes</div>
                                    <div css={styles.height}>
                                      {notesList?.length > 0 ? (
                                        notesList?.map(note => {
                                          return (
                                            <div css={styles.box2}>{note}</div>
                                          );
                                        })
                                      ) : (
                                        <label css={styles.notesUnavailable}>
                                          There are no notes to display.
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </>
                        )}
                    </div>
                  )}
                </>
              );
            })}
            {rowCount === 0 && (
              <label className="noAvalableCoursesLbl">
                {`No ${type} courses available for your selection`}
              </label>
            )}
          </div>
        }
      </div>
    );
  };

  const updateCourseConfirmModal = val => {
    showCourseConfirmModal(false);
    showCourseInfoModal(true);
  };

  const handleEditCourse = courseObject => {
    console.log(courseObject);
    showCourseConfirmModal(true);
    showCourseInfoModal(false);
    showCourseDeleteModal(false);
    setCourseInfoData(courseObject);
    setCurrentCourseInstanceDelete([]);

    if (!courseDatesHash[courseObject?.id]) {
      getCourseInstanceByGroup(courseObject?.id)
        .then(({ data }) => {
          console.log(
            `Course instance for courseID ${courseObject?.id} -`,
            data
          );
          const courseDates = data?.map(course => course?.courseDate)?.sort();

          setCourseDatesHash({
            ...courseDatesHash,
            [courseObject?.id]: courseDates,
          });
          const Datearray = courseDates?.map((ele, index) => ({
            id: index + 1,
            date: ele?.courseDate,
            courseGroupId: ele?.courseGroupId,
            courseInstanceId: ele?.courseInstanceId,
          }));

          setCourseInfoData({ ...courseObject, courseDates: Datearray });
        })
        .catch(ex =>
          console.log('Error occured in getCourseInstanceApi call -', ex)
        );
    } else {
      const Datearray = courseDatesHash[courseObject.id]?.map((ele, index) => ({
        id: index + 1,
        date: ele?.courseDate,
        courseGroupId: ele?.courseGroupId,
        courseInstanceId: ele?.courseInstanceId,
      }));
      setCourseInfoData({ ...courseObject, courseDates: Datearray });
    }
  };

  const handleDeleteCourse = course => {
    showCourseConfirmModal(false);
    showCourseInfoModal(false);
    showCourseDeleteModal(true);
    setCurrentCourseDelete(course);
  };

  const cancelDelete = () => {
    showCourseDeleteModal(false);
    setCurrentCourseDelete(null);
  };

  const cancelCourseEdit = () => {
    showCourseConfirmModal(false);
    setCourseInfoData(null);
    setDeleteInstances([]);
  };

  const setDeleteInstances = deleteInstance => {
    const courseInstances = courseDatesHash[deleteInstance[0]?.courseGroupId];
    const instanceToDelete = courseInstances?.find(
      item => item?.courseInstanceId === deleteInstance[0]?.courseInstanceId
    );
    if (instanceToDelete) {
      const instancesToDelete = [
        ...currentCourseInstanceDelete,
        instanceToDelete,
      ];
      console.log('instancesToDelete - ', instancesToDelete);

      setCurrentCourseInstanceDelete(instancesToDelete);
    }
  };

  const handleDatepickerChange = (val, identifier) => {
    const selMonth = val.month > 9 ? val.month : `0${val.month}`;
    const selDay = val.day > 9 ? val.day : `0${val.day}`;

    if (identifier === 'from') setFromDate(`${val.year}-${selMonth}-${selDay}`);
    else setToDate(`${val.year}-${selMonth}-${selDay}`);
  };

  return (
    <div css={styles.outerWrapper}>
      <div css={styles.filterSection}>
        <div id="filtersInnerWrapper" css={styles.filtersInnerWrapper}>
          <div css={styles.filterList}>
            {filterOptions.length > 0 &&
              filterOptions.map(filter => (
                <FilterOptionsList
                  filterOptions={filter.filterList}
                  filterTitle={filter.title}
                  handleClick={filterCourseInstances}
                  resetFilters={resetFilters}
                />
              ))}
          </div>
        </div>

        <label css={styles.labelDatepicker}>COURSE START DATE RANGE</label>
        <div css={styles.datePickerDiv}>
          <div css={styles.datePicker_icon}>
            <DatePicker
              value={fromDate || datePickerValue}
              onChange={val => {
                handleDatepickerChange(val, 'from');
                setdatePickerValue(
                  `${day.getFullYear()}/${fullMonth}/${fulldt}`
                );
              }}
              maxDate={toDate ? new Date(toDate) : undefined}
              //render={<Icon />}
              render={(value, openCalendar) => {
                console.log(value);
                return (
                  <button onClick={openCalendar} css={styles.datepickerBtn}>
                    <label>
                      {fromDate === '' ? 'From' : getDateFormat(fromDate)}
                    </label>
                    <DateIcon color="#DAAAFF" />
                  </button>
                );
              }}
            />
          </div>
          <div css={styles.datePicker_icon}>
            <DatePicker
              value={toDate || datePickerValue}
              minDate={fromDate ? new Date(fromDate) : undefined}
              onChange={val => {
                handleDatepickerChange(val, 'to');
                setdatePickerValue(
                  `${day.getFullYear()}/${fullMonth}/${fulldt}`
                );
              }}
              render={(value, openCalendar) => {
                console.log(value);
                return (
                  <button onClick={openCalendar} css={styles.datepickerBtn} aria-label="To date">
                    <label>
                      {toDate === '' ? 'To' : getDateFormat(toDate)}
                    </label>
                    <DateIcon color="#DAAAFF" />
                  </button>
                );
              }}
            />
          </div>
        </div>
        <div css={styles.bottomborder}></div>

        <button css={styles.Restfilters} onClick={handleResetFilters}>
          Reset filters
        </button>
      </div>
      <div css={styles.venuelist}>
        {confirmedCourses && confirmedCourses.length > 0 && (
          <>
            <div css={styles.border1} />
            {buildCourses('confirmed')}
          </>
        )}
        {pendingCourses && pendingCourses.length > 0 && (
          <>
            <div css={styles.border1} />
            {buildCourses('pending')}
          </>
        )}
      </div>
      {(courseInfoModal === true || courseConfirmModal === true) && (
        <div css={styles.overlay}>
          {courseConfirmModal === true && (
            <div css={styles.overlay}>
              <CourseInfoPopup
                updateCourseConfirmModal={updateCourseConfirmModal}
                updateCourseData={setCourseInfoData}
                courseData={courseInfoData}
                data={courseInfoData}
                setDeleteInstances={setDeleteInstances}
                cancelCourseEdit={cancelCourseEdit}
                mode="edit"
                courseTypes={courses}
              />
            </div>
          )}
        </div>
      )}
      {courseInfoModal === true && (
        <div css={styles.overlay}>
          <CourseInfoConfirmPopup
            data={courseInfoData}
            confirmCourse={updateCourse}
          />
        </div>
      )}
      {courseDeleteModal === true && (
        <div css={styles.overlay}>
          <DeleteCoursePopup
            cancelDelete={cancelDelete}
            confirmDelete={deleteCourse}
          />
        </div>
      )}
    </div>
  );
};

export default CourseManagementList;
