import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const { colors, spacing, breakpoints, screenSizes, utility } = useTheme();

  return {
    venueActionCta: css`
      display: flex;
      //margin-left: 50px;
      //margin-top: 32px;
      gap: 32px;
      button {
        max-width: 150px;
        cursor: pointer;
      }

      // display: flex;
      // flex-direction: column;
      // color: ${colors.white};
      // padding: 53px 24px 53px 48px;
      // background: ${colors.black};
      // transform: translateX(-394px);
      // transition: transform 0.3s ease-in-out;

      position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    height: 100%;
    background: #1D1D1B;
    padding: 32px;
}
    `,

    filterWrapper: css`
      width: 226px;
    `,

    filterTitle: css`
      font-size: 15.63px;
      line-height: 15.63px;
      letter-spacing: -0.156px;
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid #4f4f4e;
      color: #ffffff;
    `,

    filterList: css`
      display: flex;
      flex-direction: column;
      gap: 32px;
      max-height: calc(100vh - 220px);
      //height: 100vh;
      overflow-y: auto;
      padding-bottom: 32px;
      padding-right: 24px;

      ::-webkit-scrollbar {
        width: 4px;
      }
      ::-webkit-scrollbar-thumb {
        background: ${colors.grey};
        cursor: pointer;
      }
      ::-webkit-scrollbar-track {
        background-color: ${colors.darkGrey};
        -webkit-border-radius: 1rem;
        border-radius: 1rem;
      }

      label {
        height: 48px;
      }
    `,
    trainerMap: css`
      color: ${colors.darkBlue};
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.125rem;
      letter-spacing: 0.02em;
      text-transform: initial;
      position: relative;
    `,
    trainerMapWrapper: css`
      height: calc(100vh);
      min-height: 400px;

      .MicrosoftMap .Infobox {
        position: relative;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);
        border-radius: 24px;
        left: 0;
        top: -50px;
        pointer-events: auto;
        background-color: #fff;
        font-family: 'FS Dillon';
        max-width: 464px;
      }
      .MicrosoftMap .Infobox .infobox-body {
        max-height: initial !important;
        max-width: initial !important;
        width: initial !important;
        padding: 40px;
      }
      .MicrosoftMap .Infobox .infobox-info {
        font-family: 'FS Dillon';
        max-height: initial !important;
        //padding: 40px;
        overflow: hidden !important;
      }

      .MicrosoftMap .Infobox .infobox-actions {
        height: auto !important;
        border: none !important;

        li {
          width: 100%;
          margin: 4px 0 !important;

          a {
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #8f00ff;
            border-radius: 4px;
            font-family: FS Dillon;
            font-size: 16px;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: -0.01em;
            color: ${colors.white};
            cursor: pointer;

            &:hover,
            :focus {
              text-decoration: none;
              color: ${colors.white};
            }
          }
        }
      }
      .MicrosoftMap .Infobox .infobox-title {
        display: none;
      }
      .MicrosoftMap .infobox-close {
        color: #777;
        height: 14px;
        outline: medium none;
        position: absolute;
        right: 16px;
        text-align: center;
        text-decoration: none;
        top: 16px;
        width: 14px;
        pointer-events: auto;
      }
      .MicrosoftMap .Infobox .infobox-title {
        color: #333;
      }
      .MicrosoftMap .Infobox .infobox-actions {
        border-top: 1px solid #888;
        margin: 0 0 -4px;
        padding: 4px 0;
        font-size: 0.9em;
        clear: both;
        padding: 4px 8px 8px;
        color: #676767;
        height: 15px;
        overflow: auto;
      }
      stalk,
      .MicrosoftMap .InfoboxCustom .infobox-stalk {
        width: 14px;
        height: 16px;
        background: no-repeat;
        position: absolute;
        bottom: 1px;
      }
      .tooltipWrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      .tooltipTitle {
        font-size: 24.41px;
        font-weight: 700;
        line-height: 31.4px;
      }
      .tooltipAddress {
        display: flex;
        flex-direction: row;
        gap: 32px;
        margin-top: 8px;
      }
      .addressTitle {
        color: ${colors.black};
        font-size: 15.63px;
        font-weight: 700;
        line-height: 15.63px;
        letter-spacing: -0.156px;
        padding-bottom: 8px;
        margin-bottom: 8px;
        border-bottom: 1px solid #e6c6ff;
      }
      .addressDetails {
        p {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.16px;
          color: #4f4f4e;
        }
        &.bluetext {
          p {
            color: #8f00ff;
            font-weight: 700;
          }
        }
      }
      .facilitiesWrapper {
        margin-top: 8px;
      }
      .facilitiesDetails {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        p {
          display: flex;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.16px;
          color: #4f4f4e;
          min-width: 185px;
          &:before {
            content: '';
            display: flex;
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
          &.type {
            &:before {
              background: transparent
                url(${publicPath('/images/common/venue/sports_soccer.svg')})
                no-repeat center center;
            }
          }
          &.treatment {
            &:before {
              background: transparent
                url(${publicPath('/images/common/venue/medical_services.svg')})
                no-repeat center center;
            }
          }
          &.facility {
            &:before {
              background: transparent
                url(${publicPath('/images/common/venue/school.svg')}) no-repeat
                center center;
            }
          }
        }
      }
      .actionCta {
        margin-top: 8px;
        width: 392px;
      }
      .createCta {
        width: 90%;
        height: 54px;
        border-radius: 4px;
        color: ${colors.black};
        background: #f3e3ff;
        border: none;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 366px;
        min-height: 44px;
        text-transform: uppercase;
        text-decoration: none;
        transition: background-color 0.2s ease-in;
        position: relative;
        white-space: nowrap;
        padding: 0 20px;
        box-sizing: border-box;
        span {
          display: flex;
          &:before {
            content: '';
            display: flex;
            width: 24px;
            height: 24px;
            margin-right: 8px;
            background: transparent
              url(${publicPath('/images/common/venue/school.svg')}) no-repeat
              center center;
          }
        }
      }
    `,
    filterPopup: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    dropdownWrapper: css`
      display: flex;
      flex-direction: column;
      margin-top: 36px;
    `,
    lblNumberofCoaches: css`
      font-family: FS Dillon;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.01em;
      text-align: left;
      margin-bottom: 12px;
      color: ${colors.white};
    `,
    backBtn: css`
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      stylename: CD - H3 Bold;
      font-family: FS Dillon;
      font-size: 18px;
      font-weight: 700;
      line-height: 23px;
      letter-spacing: -0.01em;
      text-align: left;
      color: ${colors.white};
      background: transparent;
      gap: 12px;

      img {
        width: 24px;
        height: 24px;
      }
    `,

    overlay: css`
      width: 100%;
      height: 100%;
      background-color: rgba(29, 29, 27, 0.9);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
    `,

    popup2: css`
      position: fixed;
      top: 50%;
      left: 60%;
      transform: translate(-50%, -50%);
      display: flex;
      display: flex;
      //width: 511px;
      width: 30%;
      padding: 2%;
      flex-direction: column;
      align-items: flex-start;
      // gap: var(--Spacing-Spacing---lg, 24px);
      //gap: 2%;
      border-radius: var(--Spacing-Spacing---lg, 24px);
      border: 2px solid var(--Border-Border---primary, #e6c6ff);
      background: var(--Surface-Surface---white, #fff);
      max-height: 90%;
    `,

    formField: css`
      height: 100%;
      overflow: auto;
      padding: 0 2%;
      ::-webkit-scrollbar {
        width: 4px;
      }
      ::-webkit-scrollbar-thumb {
        background: #cd8eff;
        cursor: pointer;
      }
    `,

    divider: css`
      height: 0.5em;
      width: 100%;
      background: transparent;
    `,

    courseinfo: css`
      color: var(--Text-Text---invert, #1d1d1b);
      font-family: FS Dillon;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.244px;
    `,

    Coursename: css`
      color: var(--Text-Text---secondary, #4f4f4e);
      font-family: FS Dillon;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 130% */
      letter-spacing: -0.18px;
    `,

    text: css`
      display: flex;
      height: 80px;
      //padding:var(--Spacing-2, 16px);
      padding: 4%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      //gap: 10px;
      align-self: stretch;
      border-radius: var(--Radius-SM, 4px);
      border: 1px solid var(--Border-Border---white-bg, #b4b4b3);
      position: relative;

      .placeholder {
        &:focus-visible {
          outline: none;
          border: none;
        }
        border: none;
        resize: none;
        //padding-top:10px;

        color: var(--Text-Text---secondary, #4f4f4e);
        font-family: FS Dillon;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.16px;
        width: 100%;
        height: 100%;

        overflow: auto;
        ::-webkit-scrollbar {
          width: 4px;
        }
        ::-webkit-scrollbar-thumb {
          background: #cd8eff;
          cursor: pointer;
        }
      }
    `,

    pencil: css`
      //margin-left:366px;
      //margin-top:-62px;
      position: absolute;
      top: 8px;
      right: 8px;
    `,

    datebox: css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    `,

    date: css`
      color: var(--Text-Text---secondary, #4f4f4e);
      font-family: FS Dillon;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 130% */
      letter-spacing: -0.18px;
    `,

    content: css`
      color: var(--Text-Text---secondary, #4f4f4e);
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      //line-height: 24px;
      letter-spacing: -0.16px;
    `,
    addNewDate: css`
      color: var(--Text-Text---secondary, #4f4f4e);
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      //line-height: 24px;
      letter-spacing: -0.16px;
      text-decoration: underline;
    `,
    calender: css`
      display: flex;
      //width:415px;
      height: var(--Spacing-7, 56px);
      padding: 0px var(--Spacing-2, 16px);
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      border-radius: var(--Radius-SM, 4px);
      border: 1px solid var(--Border-Border---white-bg, #b4b4b3);
    `,

    calenders: css`
      //margin-left: 346px;
      //margin-top: 1px;
      position: fixed;
      display: flex;
      height: var(--Spacing-7, 56px);
      padding: 0px var(--Spacing-2, 16px);
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
    `,

    calendarWrappers: css`
      width: 100%;
      color: black;
      .react-calendar {
        position: absolute;
        //width: 415px;;
        z-index: 2;
        margin-bottom: -276px;
        margin-top: 2px;
        //margin-left:-17px;
        border-radius: var(--Radius-SM, 4px);
      }
      .react-calendar__tile--active {
        background: #e6e6e6;
        color: ${colors.black};
      }
      .react-calendar__tile--now {
        background: #8f00ff;
        color: ${colors.white};
      }
    `,
    danger: css`
      border: 2px solid ${colors.red};

      &:focus,
      &:focus-visible {
        border: 2px solid ${colors.red};
        outline: 0;
      }
    `,
    inputField: css`
      border: 2px solid ${colors.lightBlue};
    `,

    inputFields: css`
      border: none;
      //width: 367px;
      margin-top: 3px;
      height: 48px;

      &:focus-visible {
        outline: none;
        border: none;
      }
    `,

    datePickerDiv: css`
      display: flex;
      width: 100%;
      border-radius: var(--Radius-SM, 4px);
      border: 1px solid var(--Border-Border---white-bg, #b4b4b3);
      background-color: ${colors.white};
      padding: 1px;
      margin-bottom: 8px;

      .rmdp-week-day,
      .rmdp-day {
        color: ${colors.lightGrey};
      }
      .rmdp-container {
        width: 100% !important;
        display: block !important;
      }
      .rmdp-arrow-container:hover {
        background-color: var(--TT---PURPLE, #8f00ff);
        .rmdp-arrow {
          border-color: ${colors.white};
        }
      }
      .rmdp-header span .rmdp-arrow {
        border: solid var(--TT---PURPLE, #8f00ff);
        border-width: 0 2px 2px 0;
      }
      .rmdp-day.rmdp-selected span:not(.highlight) {
        background-color: var(--TT---PURPLE, #8f00ff);
        box-shadow: 0 0 3px #8798ad;
        color: #000;
      }
      .rmdp-day.rmdp-today span {
        background-color: var(--TT---PURPLE, #8f00ff);
      }
    `,

    datepickerBtn: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      padding: 0px var(--Spacing-2, 16px);
      //width:100%;
      width: 100%;
      border: none;
      background-color: ${colors.white};
      height: var(--Spacing-7, 48px);

      label {
        cursor: pointer;
        font-family: FS Dillon;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #4f4f4e;
      }
    `,
    dropDownButton: css`
      color: #1d1d1b;
      background: transparent;
      padding: 10px 16px;
      border-radius: 4px;
      border: 1px solid var(--Border-Border---white-bg, #b4b4b3);
      width: 100%;
      max-width: 100% !important;
      justify-content: flex-start;
      text-align: left;
      line-height: 24px;
      height: 50px;
      font-family: FS Dillon;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: -0.01em;

      &:hover,
      &:not(:disabled):hover {
        cursor: pointer;
      }
    `,

    datepickrdeleteBtn: css`
      background: none;
    `,

    submitbutton: isDisabled => css`
      padding: 16px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #8f00ff;
      font-family: FS Dillon;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      background: ${isDisabled
        ? 'var(--Surface-Surface---light-grey, #E6E6E6)'
        : '#8F00FF'};
      border: none;
      cursor: ${isDisabled ? 'default' : 'pointer'};
      width: 100%;
      color: #fff;
    `,

    popupConfirm: css`
      display: flex;
      position: fixed;
      top: 50%;
      left: 60%;
      transform: translate(-50%, -50%);
      width: fixed 511px;
      height: auto 350px;
      padding: 40px 48px;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-Spacing---md, 16px);

      border-radius: var(--Spacing-Spacing---lg, 24px);
      border: 2px solid var(--Border-Border---primary, #e6c6ff);
      background: var(--Surface-Surface---white, #fff);

      h2 {
        color: var(--Text-Text---invert, #1d1d1b);
        font-family: FS Dillon;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 31px;
        letter-spacing: -0.244px;
      }
    `,

    heading: css`
      color: var(--Text-Text---invert, #1d1d1b);
      font-family: FS Dillon;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 23.4px;
      letter-spacing: -0.18px;
    `,

    section1: css`
      padding: var(--Spacing-Spacing-xl, 32px) var(--Spacing-Spacing---lg, 24px);
      align-self: stretch;
      border-radius: var(--Spacing-Spacing---xs, 4px);
      background: var(--Surface-Surface---light-grey, #e6e6e6);
      display: grid;
      grid-template-columns: 50% 50%;
      row-gap: 24px;
      column-gap: 24px;

      img {
        width: var(--Spacing-Spacing-xl, 32px);
        height: var(--Spacing-Spacing-xl, 32px);
      }

      h3 {
        color: #1d1d1b;
        font-family: FS Dillon;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 23.4px; /* 130% */
        letter-spacing: -0.18px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    `,

    box1: css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-Spacing---lg, 24px);
      width: 176px;
    `,

    line: css`
      display: flex;
      padding: 0px 0px 0px 0px;
      align-items: center;
      gap: 8px;
    `,

    box2: css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-Spacing---lg, 24px);
      width: 50% 145px;
    `,

    buttonWrapper: css`
      width: 100%;
    `,

    buttons: css`
      display: flex;
      height: 54px;
      width: 100%;
      padding: 22px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border: none;
      border-radius: 4px;
      background: var(--Surface-Surface---brand, #8f00ff);
      cursor: pointer;
    `,

    titlebutton: css`
      color: var(--Text-Text---primary, #fff);
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    `,
  };
};
