import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const { colors, spacing, breakpoints, screenSizes, utility } = useTheme();

  return {
    venuelist: css`
      color: ${colors.white};
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.125rem;
      letter-spacing: 0.02em;
      text-transform: initial;
      padding: 140px 2% 240px 2%;
      //padding: 140px 2% 112px 2%;
      //padding: 140px 10%;
      background: ${colors.black};
      min-height: 100vh;
      height: max-content;
      //display: grid;
      justify-content: center;
      //width: 100%;

      width: 83%;
      margin-left: 17%;
      z-index:0;
    `,

    headerWrapper: css`
      width: 100%;
      //height: 133px;
      padding: 0 0 45px 0;
      display: flex;
    `,
    headerBtnSectionLeft: css`
      display: flex;
      width: 50%;
      justify-content: start;
    `,
    headerBtnSectionRight: css`
      display: flex;
      width: 50%;
      justify-content: end;
      gap: 7px;
    `,
    viewBtn: css`
    display: flex;
    min-width: 120px;
    height: 56px;
    padding: var(--Spacing-Spacing---md, 16px) var(--Spacing-Spacing---lg, 24px);
    justify-content: center;
    align-items: center;
    gap: 7px;
    flex-shrink: 0;
    border-radius: var(--Spacing-Spacing---sm, 8px);
    border: 2px solid var(--Border-Border---input, #E6E6E6);
    background: rgba(29, 29, 27, 0.70);
    cursor:pointer;

    //background: url(${publicPath('/images/common/leftLinks/edit.svg')})
                    no-repeat;

    color: var(--Text-Text---primary, #FFF);
    font-family: FS Dillon;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.14px;
    `,
    viewBtnActive: css`
      background: var(--Surface-Surface---tertiery, #b7c5e9);
      border: none;
      color: var(--Text-Text---invert, #1d1d1b);
    `,
    addCourseBtn: css`
      display: inline-flex;
      padding: var(--Spacing-Spacing---md, 16px)
        var(--Spacing-Spacing---lg, 24px) var(--Spacing-Spacing---md, 16px)
        var(--Spacing-Spacing-xl, 32px);
      align-items: center;
      position: fixed;
      right: 33px;
      bottom: 33px;
      border-radius: var(--Spacing-Spacing---sm, 8px);
      background: var(--TT---PURPLE, #8f00ff);
      box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.75),
        0px 1px 10px 0px rgba(95, 8, 164, 0.47);
      border-style: none;
      color: var(--UI-PL-UI-White, #fff);
      leading-trim: both;
      text-edge: cap;
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
      text-transform: uppercase;
      cursor: pointer;
    `,
    footerWrapper: css``,
    list: css`
      display: flex;
      flex-direction: column;
      border: 1px solid #4f4f4e;
    `,
    listhead: css`
      display: grid;
      border-bottom: 1px solid #4f4f4e;
      grid-template-columns: 20% 20% 20% 20% 10% 10%;
      background-color: #f3e3ff;
      color: ${colors.black};

      > div {
        border-right: 1px solid #4f4f4e;
        padding: 0.8rem;
        font-weight: 700;
      }
    `,
    listrow: css`
      display: grid;
      border-bottom: 1px solid #4f4f4e;
      grid-template-columns: 20% 20% 20% 20% 10% 10%;
      &.even {
        background: #2d2d2b;
      }
      > div {
        border-right: 1px solid #4f4f4e;
        padding: 0.8rem;
      }
    `,
    even: css`
      background: #2d2d2b;
    `,
    editlist: css`
      color: #b7c5e9;
      text-decoration: underline;
      cursor: pointer;
      display: flex;
      &:before {
        content: '';
        display: flex;
        width: 16px;
        height: 16px;
        margin-right: 8px;
        background: url(${publicPath('/images/common/leftLinks/edit.svg')})
          no-repeat;
        background-size: contain;
      }
    `,

    border1: css`
      width: 100%;
      height: 3px;
      border-top: 2px solid #4f4f4e;
    `,

    courses: css`
      color: var(--Text-Text---primary, #fff);
      /* CD - H2 Bold */
      font-family: FS Dillon;
      font-size: 24.41px;
      font-style: normal;
      font-weight: 700;
      line-height: 31.4px; /* 128.636% */
      letter-spacing: -0.244px;
    `,

    confirmedcourse: css`
      color: var(--Text-Text---tertiary, #b7c5e9);
      /* CD - Paragraph */
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
    `,

    table: css`
      width: 100%;
      display: flex;
      padding: var(--Spacing-Spacing---md, 16px) var(--Spacing-4, 32px);
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-radius: 0px 0px 0px var(--Number, 0px);
      border: 1px solid var(--Border-Border---divder-dark-bg, #4f4f4e);
      background: var(--Surface-Surface---grey, #1d1d1b);
    `,

    coursename: css`
      width: 160px;
      height: 24px;
      color: #fff;
      /* CD - Paragraph */
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
    `,

    coursetype: css`
      display: flex;
      color: #fff;
      align-items: center;
      /* CD - Paragraph */
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
    `,

    month: css`
      display: flex;
      padding: var(--Spacing-Spacing---sm, 8px)
        var(--Spacing-Spacing---md, 16px);
      align-items: center;
      gap: var(--Spacing-Spacing---sm, 8px);
      border-radius: var(--Spacing-Spacing-xl, 32px);
      border: 1px solid var(--Border-Border---divder-dark-bg, #4f4f4e);
      align-items: center;
      justify-content: center;
      height: 36px;
      //max-width: 100px;
      background-color: inherit;
      position: relative;
      width: fit-content;
      min-width: 94px;
    `,

    dateContainer: css`
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-row-gap: 16px;
      grid-column-gap: 8px;
    `,

    datePill: css`
      display: flex;
      padding: var(--Spacing-Spacing---sm, 8px)
        var(--Spacing-Spacing---md, 16px);
      align-items: center;
      gap: var(--Spacing-Spacing---sm, 8px);
      border-radius: var(--Spacing-Spacing-xl, 32px);
      border: 1px solid var(--Border-Border---divder-dark-bg, #4f4f4e);
      align-items: center;
      justify-content: center;
      height: 32px;
      //width: 94px;
      width: 120px;
      background-color: inherit;
      color: #4f4f4e;
    `,

    image: css`
      width: var(--Spacing-Spacing---md, 16px);
      height: var(--Spacing-Spacing---md, 16px);
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      gap: 8px;
    `,

    june: css`
      color: #fff;
      /* CD - Paragraph M */
      font-family: FS Dillon;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.14px;
    `,

    city: venueId => css`
      color: #fff;
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      //line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
      text-align: ${venueId === 0 ? 'center' : 'left'};
    `,

    coaches: status => css`
      display: flex;
      // width: 172px;
      height: 36px;
      padding: var(--Spacing-1, 8px) 16px var(--Spacing-1, 8px) 8px;
      align-items: center;
      justify-content: center;
      gap: var(--Spacing-05, 4px);
      flex-shrink: 0;
      border-radius: var(--Spacing-7, 56px);
      border: 1px solid;
      border-color: ${status === 'confirmed'
        ? '#22AC51'
        : status === 'pending'
        ? '#FFB800'
        : status === 'declined'
        ? '#DF1C41'
        : '#B4B4B3'};
    `,

    coach1: status => css`
      display: flex;
      color: #fff;
      /* CD - Paragraph M bold */
      font-family: FS Dillon;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.14px;
      align-items: center;
      justify-content: center;
      gap: 8px;

      ::before {
        content: ${status === 'pending'
          ? 'url(/images/common/list/orange-triangle.svg)'
          : status === 'confirmed'
          ? 'url(/images/common/list/green-dot.svg)'
          : status === 'declined'
          ? 'url(/images/common/list/red-square.svg)'
          : 'url(/images/common/list/gray-dot.svg)'};
      }
    `,

    venue1: css`
      display: flex;
      color: #fff;
      /* CD - Paragraph M bold */
      font-family: FS Dillon;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.14px;
      gap: 8px;
    `,

    venue: css`
      height: 36px;
      display: flex;
      padding: 4px 12px;
      justify-content: center;
      align-items: center;
      gap: var(--Spacing-05, 4px);
      border-radius: var(--Spacing-7, 56px);
      border: 1px solid var(--Colour-Secondary-Grey---700, #4f4f4e);
    `,

    btnArrow: css`
      display: flex;
      width: fit-content;
      padding: 0;
      background: transparent;
      border: none;
      border-radius: 100%;
      cursor: pointer;
    `,
    tables: css`
      &:nth-child(even) {
        background: #2d2d2b;
      }
      &:nth-child(odd) {
        background: var(--surface-surface-grey, #1d1d1b);
      }
      display: flex;
      padding: var(--Spacing-Spacing---md, 16px) var(--Spacing-4, 32px);
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-radius: 0px 0px 0px var(--Number, 0px);
      border: 1px solid var(--Border-Border---divder-dark-bg, #4f4f4e);
      background: var(--Surface-Surface---grey, #1d1d1b);
      display: grid;
      align-content: center;
      //grid-template-columns: 15% 15% 10% 20% 17% 15% 2% 1%;
      grid-template-columns: 15% 15% 15% 20% 12% 12% 2% 1%;
      position: relative;
      // z-index: 1;
    `,

    course: css`
      width: 160px;
      color: #fff;
      /* CD - Paragraph */
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      //line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
      display: flex;
      align-items: center;
    `,

    coursetypes: css`
      display: flex;
      align-items: center;
      gap: 2px;
    `,

    coursetypes1: css`
      //height:24px;
      color: #fff;
      /* CD - Paragraph */
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      //line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
      padding: 0 4px;
    `,

    tableRow: css`
      display: flex;
      padding: var(--Spacing-1, 8px) var(--Spacing-4, 32px)
        var(--Spacing-4, 32px) var(--Spacing-4, 32px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Number, 0px);
      align-self: stretch;
      border-radius: var(--Number, 0px);
      border-right: 1px solid var(--Border-Border---divder-dark-bg, #4f4f4e);
      border-bottom: 1px solid var(--Border-Border---divder-dark-bg, #4f4f4e);
      border-left: 1px solid var(--Border-Border---divder-dark-bg, #4f4f4e);
      background: var(--Surface-list-tint, #2d2d2b);
      width: 1026px;
    `,

    firstbox: isEven => css`
      display: flex;
      padding: var(--Spacing-3, 24px);
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
      border-radius: var(--Spacing-1, 8px);
      border: 1px solid var(--Colour-Secondary-Grey---700, #4f4f4e);
      background-color: ${isEven === true
        ? '#2d2d2b'
        : 'var(--surface-surface-grey, #1d1d1b)'};
      grid-column: 1/8;
      margin-top: var(--Spacing-3, 24px);
    `,

    title: css`
      color: var(--Text-Text---primary, #fff);
      /* CD - H3 Bold */
      font-family: FS Dillon;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 23.4px; /* 130% */
      letter-spacing: -0.18px;
      width: 256px;
      height: 24px;
    `,

    border: css`
      border-bottom: 2px solid #4f4f4e;
      width: 100%;
    `,

    borders: css`
      border-bottom: 1px solid #4f4f4e;
      width: 100%;
    `,

    titleadjustment: css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-2, 16px);
      align-self: stretch;
      width: 100%;
    `,

    developerSceduleArea: css`
      display: grid;
      grid-template-columns: 29% 70% 1%;
      width: 100%;
      height: auto;
    `,

    developerScheduleNames: css`
      display: flex;
      flex-direction: column;
      //gap: 12px;
    `,

    developerScheduleCourses: css`
      display: flex;
      flex-direction: column;
      //gap: 12px;
      width: 100%;
      height: 102%;
      max-width: 60vw;
      overflow-x: auto;
      position: relative;
      padding-bottom: 8px;

      ::-webkit-scrollbar {
        width: 115px;
        height: 4px;
        position: absolute;
        top: 12px;
      }
      ::-webkit-scrollbar-thumb {
        background: #cd8eff;
        cursor: pointer;
        width: 115px;
        position: absolute;
        top: 12px;
        border-radius: 10px;
      }
    `,

    mask: isEven => css`
      position: absolute;
      top: 0;
      right: -20px;
      width: 6%;
      height: 100%;
      background: ${isEven === true
        ? 'linear-gradient(to right,transparent,#2d2d2b 0%,#2d2d2b 20%,transparent)'
        : 'linear-gradient(to right,transparent,#1d1d1b 0%,#1d1d1b 20%,transparent)'};
      pointer-events: none;
      z-index: 1;
    `,

    maskRight: isEven => css`
      position: absolute;
      top: 0;
      right: 12px;
      width: 100%;
      height: 100%;
      background: ${isEven === true
        ? 'linear-gradient(to left,transparent,#2d2d2b 0%,#2d2d2b 20%,transparent)'
        : 'linear-gradient(to left,transparent,#1d1d1b 0%,#1d1d1b 20%,transparent)'};
      pointer-events: none;
      z-index: 1;
    `,

    venueSelectBtn: status => css`
      width: 135px;
      height: 32px;
      margin: 0;
      padding: 4px 18px 4px 8px;
      background-color: transparent;
      border-radius: var(--Spacing-7, 56px);
      border: 1px solid;
      border-color: ${status === 'pending'
        ? '#FFB800'
        : status === 'confirmed'
        ? '#20BE56'
        : '#B4B4B3'};
      color: #fff;
      font-family: FS Dillon;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      text-align: left;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: ${status === 'confirmed' ? 'default' : 'pointer'};

      ::before {
        content: ${status === 'pending'
          ? 'url(/images/common/list/orange-triangle.svg)'
          : status === 'confirmed'
          ? 'url(/images/common/list/green-dot.svg)'
          : 'url(/images/common/list/gray-dot.svg)'};
        position: relative;
        margin-right: 12px;
      }
    `,

    head: css`
      //width:914px;
      display: grid;
      grid-template-columns: 30% 15% 25% 15% 15%;
      align-content: center;
      padding-top: var(--Spacing-1, 8px);
      //gap: 4px;
      align-self: stretch;
    `,

    name: css`
      width: 256px;
      color: var(--Text-Text---primary, #fff);
      /* CD - H4 Bold */
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 100% */
      letter-spacing: -0.156px;
    `,

    phone: css`
      color: var(--Text-Text---primary, #fff);
      /* CD - H4 Bold */
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 100% */
      letter-spacing: -0.156px;
      width: 94px;
      height: 16px;
    `,

    email: css`
      width: 234px;
      color: var(--Text-Text---primary, #fff);
      /* CD - H4 Bold */
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 100% */
      letter-spacing: -0.156px;
    `,

    date: css`
      width: 140px;
      color: var(--Text-Text---primary, #fff);
      /* CD - H4 Bold */
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 100% */
      letter-spacing: -0.156px;
    `,

    content: css`
      display: grid;
      grid-template-columns: 30% 15% 25% 15% 15%;
      align-content: center;
      padding: var(--Spacing-Spacing---xs, 4px) 0px var(--Spacing-05, 4px) 0px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      .innerimg {
        width: 36px;
        height: auto;
        flex-shrink: 0;
        gap: 4px;
      }
    `,

    coachIcon: css`
        width:36px;
        height:auto;
        flex-shrink: 0;
        gap: 4px;
    `,

    content1: css`
      display: flex;
      padding: var(--Spacing-Spacing---xs, 4px) 0px var(--Spacing-05, 4px) 0px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      height: 48px;
      position: relative;
    `,

    content4: isFirst => css`
      display: flex;
      padding: var(--Spacing-Spacing---xs, 4px) 0px var(--Spacing-05, 4px) 0px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      height: 72px;
      position: relative;
      border-top: ${isFirst ? 'none' : '1px solid #4f4f4e'};
    `,

    content2: isFirst => css`
      display: flex;
      padding: var(--Spacing-Spacing---xs, 4px) 0px var(--Spacing-05, 4px) 16px;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      height: 72px;
      gap: 24px;
      width: max-content;
      min-width: 100%;
      border-top: ${isFirst ? 'none' : '1px solid #4f4f4e'};
    `,

    width: css`
      display: flex;
      //width: 218px;
      padding: 0px var(--Spacing-Spacing---none, 0px) 0px 0px;
      align-items: center;
      gap: var(--Spacing-Spacing---md, 16px);
      align-self: stretch;
    `,

    name1: css`
      color: var(--Text-Text---tertiary, #b7c5e9);
      /* CD - Paragraph */
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
      word-wrap: break-word;
      width: 90%;
    `,

    number: css`
      color: var(--Colour-Brand-Purple---400, #cd8eff);
      /* CD - Paragraph bold */
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
      word-wrap: break-word;
      width: 90%;
    `,

    emailid: css`
      color: var(--Colour-Brand-Purple---400, #cd8eff);
      /* CD - Paragraph bold */
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
      word-wrap: break-word;
      width: 90%;
    `,

    width1: css`
      //width: 245px;
      //height: 32px;
    `,

    width2: css`
      //width: 100px;
      //height: 24px;
    `,

    dates: css`
      //width:94px;
      height: auto;
      span {
        position: relative;
        left: 25%;
      }
    `,

    dropdown: css`
      display: flex;
      padding: 4px 14px 4px 8px;
      align-items: center;
      gap: var(--Spacing-05, 4px);
      border-radius: var(--Spacing-7, 56px);
      border: 1px solid var(--Colour-Secondary-Grey---400, #b4b4b3);
      .innerimage {
        width: 20px;
      }
    `,

    contract: css`
      color: #fff;
      /* CD - Paragraph M bold */
      font-family: FS Dillon;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.14px;
    `,

    secondbox: isEven => css`
      //margin-top:20px;
      display: flex;
      padding: var(--Spacing-3, 24px);
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
      border-radius: var(--Spacing-1, 8px);
      border: 1px solid var(--Colour-Secondary-Grey---700, #4f4f4e);
      background-color: ${isEven === true
        ? '#2d2d2b'
        : 'var(--surface-surface-grey, #1d1d1b)'};
      grid-column: 1/8;
      margin-top: var(--Spacing-3, 24px);
    `,

    address: css`
      color: var(--Text-Text---tertiary, #b7c5e9);
      /* CD - Paragraph */
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
      width: 218px;
    `,

    thirdbox: isEven => css`
      //margin-top:20px;
      display: flex;
      //height: 370px;
      padding: var(--Spacing-3, 24px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-1, 8px);
      align-self: stretch;
      border-radius: var(--Spacing-1, 8px);
      border: 1px solid var(--Colour-Secondary-Grey---700, #4f4f4e);
      background-color: ${isEven === true
        ? '#2d2d2b'
        : 'var(--surface-surface-grey, #1d1d1b)'};
      grid-column: 1/8;
      margin-top: var(--Spacing-3, 24px);
      margin-bottom: 16px;
    `,

    box1: css`
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: flex-start;
      gap: 5%;
      margin-top: 12px;
    `,

    notesLeftSection: css`
      display: flex;
      flex-direction: column;
      gap: var(--Spacing-2, 16px);
      width: 100%;
    `,

    note: css`
      color: var(--Text-Text---primary, #fff);
      /* CD - H4 Bold */
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 100% */
      letter-spacing: -0.156px;
    `,

    innerborder: css`
      display: flex;
      height: 138px;
      //width: 424px;
      //padding: 0px var(--Spacing-2, 16px);
      padding: var(--Spacing-2, 16px);
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      border-radius: var(--Radius-SM, 4px);
      border: 1px solid var(--Border-Border---input, #e6e6e6);
    `,

    outer: css`
      // display: flex;
      // height: var(--Spacing-5, 40px);
      // flex-direction: column;
      // justify-content: center;
      // align-items: flex-start;
      // flex: 1 0 0;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 12fr 1fr;
      grid-template-rows: 1fr;
    `,

    text: css`
      display: flex;
      padding: 4px 0px;
      align-items: flex-start;
      margin-top: 10px;
      flex: 1 0 0;
      align-self: stretch;
    `,

    addtext: css`
      width: 74px;
      height: 24px;
      color: var(--Text-Text---primary, #fff);
      /* CD - Paragraph */
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
    `,

    pencil: css`
display: flex;
align-items: flex-start;
justify-content: flex-end;
}`,

    bottom: css`
      //padding-bottom: 10px;
      //margin-left:350px;
      color: var(--Text-Text---primary, #fff);
      /* CD - Paragraph XS */
      font-family: FS Dillon;
      font-size: 12.5px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 160% */
      letter-spacing: -0.125px;
      grid-column: 1/8;
      display: flex;
      justify-content: flex-end;
    `,

    whiteborder: isDisabled => css`
      display: flex;
      //width: 423px;
      height: 54px;
      padding: 22px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      color: var(--Text-Text---primary, #fff);
      /* CD - Paragraph */
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      background: ${isDisabled
        ? 'var(--Surface-Surface---light-grey, #E6E6E6)'
        : '#8F00FF'};
      border: none;
      cursor: ${isDisabled ? 'default' : 'pointer'};
    `,

    content3: css`
      width: 100%;
    `,

    box2: css`
      margin-top: 14px;
      width: 95%;
      padding: var(--Spacing-2, 16px);
      border-radius: var(--Radius-SM, 4px);
      border: 1px solid var(--Border-Border---input, #e6e6e6);
      color: var(--Text-Text---primary, #fff);
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.16px;
    `,

    notesUnavailable: css`
      color: var(--Text-Text---primary, #fff);
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      position: relative;
      top: 16px;
    `,

    availability: css`
      display: flex;
      height: var(--Spacing-6, 48px);
      flex-direction: column;
      justify-content: center;
      align-self: stretch;
      color: var(--Text-Text---primary, #fff);
      /* CD - Paragraph */
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
    `,

    player: css`
      color: var(--Text-Text---tertiary, #b7c5e9);
      /* CD - Paragraph XS Bold */
      font-family: FS Dillon;
      font-size: 12.5px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 160% */
      letter-spacing: -0.125px;
    `,

    adjustment: css`
      display: flex;
      padding-top: var(--Spacing-1, 8px);
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    `,

    matchdate: css`
      display: flex;
      color: var(--Text-Text---tertiary, #b7c5e9);
      /* CD - Paragraph XS Bold */
      font-family: FS Dillon;
      font-size: 12.5px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 160% */
      letter-spacing: -0.125px;
    `,

    height: css`
      height: 256px;
      //width: 420px;
      overflow: auto;
      ::-webkit-scrollbar {
        width: 8px;
      }
      ::-webkit-scrollbar-thumb {
        background: #cd8eff;
        cursor: pointer;
      }
    `,

    courses: css`
      color: var(--Text-Text---primary, #fff);
      /* CD - H2 Bold */
      font-family: FS Dillon;
      font-size: 24.41px;
      font-style: normal;
      font-weight: 700;
      line-height: 31.4px; /* 128.636% */
      letter-spacing: -0.244px;
    `,

    border1: css`
      width: 100%;
      height: 3px;
      border-top: 2px solid #4f4f4e;
    `,

    confirmedcourse: css`
      color: var(--Text-Text---tertiary, #b7c5e9);
      /* CD - Paragraph */
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
    `,

    topadjustment: css`
      display: flex;
      padding-top: var(--Spacing-Spacing-xl, 32px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-Spacing---xs, 4px);
      align-self: stretch;
      background: var(--Surface-Surface---grey, #1d1d1b);
      box-shadow: 0px 4px 25px 0px #1d1d1b;
      padding-bottom: 24px;
    `,

    noteArea: css`
      width: 100%;
      height: 100%;
      resize: none;
      //width: 391px;
      background: transparent;
      border: none;
      color: var(--Text-Text---primary, #fff);
      font-family: FS Dillon;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
      overflow: auto;
      ::-webkit-scrollbar {
        width: 4px;
      }
      ::-webkit-scrollbar-thumb {
        background: #cd8eff;
        cursor: pointer;
      }

      &:focus {
        border: none;
        outline: none;
      }
      &:focus-visible {
        border: none;
        outline: none;
      }

      &::placeholder,
      &::-webkit-input-placeholder {
        color: var(--Text-Text---primary, #fff);
      }
    `,

    customdropdown: status => css`
      width: 135px;
      height: 32px;
      margin: 0;
      padding: 4px 8px 4px 14px;
      background-color: transparent;
      border-radius: var(--Spacing-7, 56px);
      border: 1px solid;
      border-color: ${status === 0
        ? '#B4B4B3'
        : status === 1
        ? '#FFB800'
        : status === 2
        ? '#20BE56'
        : '#DF1C41'};
      color: #fff;
      font-family: FS Dillon;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      text-align: left;

      ::before {
        content: ${status === 0
          ? 'url(/images/common/list/gray-dot.svg)'
          : status === 1
          ? 'url(/images/common/list/orange-triangle.svg)'
          : status === 2
          ? 'url(/images/common/list/green-dot.svg)'
          : 'url(/images/common/list/red-square.svg)'};
        position: relative;
        margin-right: 12px;
      }
    `,

    venueActionCta: css`
      display: flex;
      //margin-left: 50px;
      //margin-top: 32px;
      gap: 32px;
      button {
        max-width: 150px;
        cursor: pointer;
      }

      // display: flex;
      // flex-direction: column;
      // color: ${colors.white};
      // padding: 53px 24px 53px 48px;
      // background: ${colors.black};
      // transform: translateX(-394px);
      // transition: transform 0.3s ease-in-out;

      position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    height: 100%;
    background: #1D1D1B;
    padding: 32px;
}
    `,

    filterWrapper: css`
      width: 226px;
    `,

    filterTitle: css`
      font-size: 16px;
      line-height: 16px;
      letter-spacing: -0.156px;
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid #4f4f4e;
      color: #ffffff;
    `,

    filterList: css`
      display: flex;
      flex-direction: column;
      gap: 32px;
      //max-height: calc(100vh - 150px);
      max-height: calc(100vh - 275px);
      height: auto;
      overflow-y: auto;
      padding-bottom: 32px;
      //padding-right: 16px;
      padding-right: 8px;
      margin-bottom: 24px;

      ::-webkit-scrollbar {
        width: 4px;
      }
      ::-webkit-scrollbar-thumb {
        background: ${colors.grey};
        cursor: pointer;
      }
      ::-webkit-scrollbar-track {
        background-color: ${colors.darkGrey};
        -webkit-border-radius: 1rem;
        border-radius: 1rem;
      }

      label {
        height: 48px;
      }
    `,

    outerWrapper: css`
      display: flex;
      background-color: var(--Surface-Surface---grey, #1d1d1b);
      //width: max-content;
      min-width: 100%;
    `,

    filterSection: css`
      display: flex;
      flex-direction: column;
      //width: 20%;
      width: 17%;
      background: #1d1d1b;
      padding: 32px;
      border-right: 1px solid #4f4f4e;
      padding-right: 24px;

      position: fixed;
      z-index:2;
    `,

    filtersInnerWrapper: css`
    width: 100%;,
    `,

    inputContainer: css`
      position: relative;
      margin-bottom: 32px;
      width: 366px;
      border-radius: 4px;
    `,
    inputLabel: css`
      position: absolute;
      top: -19px;
      left: 10px;
      background-color: ${colors.black};
      padding: 0 5px;
      color: ${colors.white};
      z-index: 1;
    `,
    inputLabelName: css`
      color: ${colors.white};
      font-size: 16px;
      line-height: 16px;
    `,
    calendarWrapper: css`
      width: 100%;
      position: relative;
      .react-calendar {
        position: absolute;
        width: 366px;
        z-index: 2;
      }
      .react-calendar__tile--active {
        background: #e6e6e6;
        color: ${colors.black};
      }
      .react-calendar__tile--now {
        background: #8f00ff;
        color: ${colors.white};
      }
    `,
    danger: css`
      border: 2px solid ${colors.red};

      &:focus,
      &:focus-visible {
        border: 2px solid ${colors.red};
        outline: 0;
      }
    `,
    inputField: css`
      border: 2px solid ${colors.lightBlue};
    `,

    datePickerDiv: css`
      display: flex;
      width: 100%;
      position: relative;
      gap: 8px;
      padding: 16px 0;
      div {
        //width: 100% !important;
        //height: 100% !important;
      }
      button {
        width: 100% !important;
        height: 100% !important;
      }
      .rmdp-week-day,
      .rmdp-day {
        color: ${colors.lightGrey};
      }
      .rmdp-arrow-container:hover {
        background-color: var(--TT---PURPLE, #8f00ff);
        .rmdp-arrow {
          border-color: ${colors.white};
        }
      }
      .rmdp-ep-arrow {
        border: none !important;
      }
      .rmdp-header span .rmdp-arrow {
        border: solid var(--TT---PURPLE, #8f00ff);
        border-width: 0 2px 2px 0;
      }
      .rmdp-day.rmdp-selected span:not(.highlight) {
        background-color: var(--TT---PURPLE, #8f00ff);
        box-shadow: 0 0 3px #8798ad;
        color: #fff;
      }
      .rmdp-disabled {
        opacity:0.5;
      }
    `,
    day_scroll: css`
      overflow-x: scroll;
      position: relative;
      overflow-y: hidden;
      ::-webkit-scrollbar {
        width: 1px;
        height: 2px;
      }
      ::-webkit-scrollbar-track {
        background: #375578;
      }
      ::-webkit-scrollbar-thumb {
        background: #888;
      }
      button,
      span {
        display: none;
      }
    `,

    datePicker_icon: css`
      display: grid;
      align-self: center;
      justify-self: center;
      width:100%;
    `,
    fixtureData: css`
      padding: 0;
      border: 5px solid ${colors.SecondaryColor};
    `,

    datepickerBtn: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: transparent;
      font-family: FS Dillon;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.01em;
      text-align: left;
      color: ${colors.white};
      gap: 4px;
      border: 2px solid ${colors.white};
      border-radius: 4px;
      //height: 80px;
      cursor: pointer;
      //width: 100%;
      padding: 12px;
    `,

    dateRangePickerDiv: css`
      display: flex;

      .rmdp-week-day,
      .rmdp-day {
        color: ${colors.lightGrey};
      }

      .rmdp-arrow-container:hover {
        background-color: var(--TT---PURPLE, #8f00ff);
        .rmdp-arrow {
          border-color: ${colors.white};
        }
      }
      .rmdp-header span .rmdp-arrow {
        border: solid var(--TT---PURPLE, #8f00ff);
        border-width: 0 2px 2px 0;
      }
      .rmdp-day.rmdp-selected span:not(.highlight) {
        background-color: var(--TT---PURPLE, #8f00ff);
        box-shadow: 0 0 3px #8798ad;
        color: #fff;
      }
      .rmdp-day.rmdp-today span {
        background-color: var(--TT---PURPLE, #8f00ff);
      }
    `,

    daterangepickerBtn: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      padding: 0px var(--Spacing-2, 16px);
      //width:100%;
      width: 100%;
      border: none;
      background-color: ${colors.white};
      height: var(--Spacing-7, 52px);

      label {
        cursor: pointer;
        font-family: FS Dillon;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #4f4f4e;
      }
    `,

    bottomborder: css`
      border-bottom: 2px solid #818180;
      width: 100%;
      height: 1px;
      margin-bottom: 16px;
    `,

    Restfilters: css`
      display: flex;
      height: 54px;
      padding: var(--Spacing-Spacing---none, 0px) var(--Number, 0px);
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 4px;
      border: 2px solid var(--Border-Border---input, #e6e6e6);
      width: 100%;
      height: 54px;
      cursor: pointer;
      background: transparent;
      font-family: FS Dillon;
      color: ${colors.white};
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
    `,

    labelDatepicker: css`
      font-family: FS Dillon;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.01em;
      text-align: left;
      color: ${colors.white};
    `,

    courseTable: css`
      position: relative;
      margin-bottom: 24px;

      .noAvalableCoursesLbl {
        font-size: 1.2em;
      }
    `,

    courseEditBtn: css`
      background: none;
      border: none;
      width: fit-content;
      height: fit-content;
      padding: 0;
      margin: 0;
      cursor: pointer;
    `,

    datePillContainer: css`
      position: relative;
      width: max-content;
      cursor: pointer;
      max-width:100%;
    `,

    ellipsisSvg: css`
      pointer-events: none;
    `,

    courseTooltip: css`
      min-width: 288px;
      min-height: 150px;
      background-color: #fff;
      border-radius: 24px;
      padding: 40px;
      position: absolute;
      z-index: 1;
      right: -42px;
      top: 48px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .editTooltipLbl {
        font-family: FS Dillon;
        font-size: 18px;
        font-weight: 700;
        line-height: 23.4px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #1d1d1b;
      }

      .editCourseBtn {
        width: 100%;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: none;
        background: var(--Surface-Surface---brand, #8f00ff);
        cursor: pointer;
        font-family: FS Dillon;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        color: #fff;
        cursor: pointer;
      }

      .deleteCourseBtn {
        width: 100%;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border-radius: 4px;
        border: none;
        background: transparent;
        cursor: pointer;
        font-family: FS Dillon;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        color: #1d1d1b;
        border: 2px solid var(--Border-Border---primary, #e6c6ff);
        cursor: pointer;
      }

      :after {
        content: '';
        position: absolute;
        top: 0;
        left: 85%;
        width: 0;
        height: 0;
        border: 16px solid transparent;
        border-bottom-color: #fff;
        border-top: 0;
        margin-left: -16px;
        margin-top: -16px;
      }
    `,

    overlay: css`
      width: 100%;
      height: 100%;
      background-color: rgba(29, 29, 27, 0.5);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 5;
    `,

    pdfDownload: utility.checkboxHidden,
  };
};
