/** @jsxImportSource @emotion/react */
import { useState, useReducer, useEffect } from 'react';
import 'react-calendar/dist/Calendar.css';
import useStyles from './styles';

const CourseInfoConfirmPopup = props => {
  const styles = useStyles();
  const [selectedId, setSelectedId] = useState();
  const [numberOfTrainers, setNumberOfTrainers] = useState(0);
  const [selectedVenueDetails, setSelectedVenueDetails] = useState(null);
  const [courseTypeSelected, setCourseTypeSelected] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  

  const getDateFormat = (date, format = 'mm dd') => {
    const dt = new Date(date);
    const yyyy = dt.getFullYear();
    let mm =
      format === 'mm dd'
        ? dt.toLocaleString('default', { month: 'short' })
        : dt.getMonth() + 1;
    let dd = dt.getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    if (format === 'dd-mm-yyyy') return `${dd}-${mm}-${yyyy}`;

    if (format === 'yyyy-mm-dd') return `${yyyy}-${mm}-${dd}`;

    return `${dd} - ${mm} - ${yyyy}`;
  };

  return (
    <div css={styles.popupConfirm}>
      <div css={styles.heading}></div>
      <h2>Course summary</h2>
      <div css={styles.section1}>
        <div css={styles.line}>
          <img src="/images/common/location.svg"></img>      
          <div>
            <h3 style={{ width: '136px' }}>{props.data?.courseLocation}</h3>
          </div>
        </div>

        <div css={styles.line}>
          <img src="/images/common/calender.svg"></img>

          <div>
            <h3>{getDateFormat(props.data?.courseDate)}</h3>
          </div>
        </div>

        <div css={styles.line}>
          <img src="/images/common/cap.svg"></img>

          <div>
            <h3>{props.data?.courseType}</h3>
          </div>
        </div>

        <div css={styles.line}>
          <img src="/images/common/mask.svg"></img>

          <div>
            <h3>{props.data?.noOfCoach} Coach developers</h3>
          </div>
        </div>
        {/* </div> */}
      </div>
      <div css={styles.buttonWrapper}>
        <button css={styles.buttons} onClick={() => props?.confirmCourse()}>
          <p css={styles.titlebutton}>Confirm course</p>
        </button>
      </div>
    </div>
  );
};

export default CourseInfoConfirmPopup;
