import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const { colors, spacing, breakpoints, screenSizes, utility } = useTheme();

  return {
    venueMap: css`
      color: ${colors.darkBlue};
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.125rem;
      letter-spacing: 0.02em;
      text-transform: initial;
      position: relative;
    `,
    venueMapWrapper: css`
      .mapContainer {
        height: calc(100vh);
        min-height: 400px;
      }
      .MicrosoftMap .Infobox {
        position: relative;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);
        border-radius: 24px;
        left: 0;
        top: -70px;
        pointer-events: auto;
        background-color: #fff;
        font-family: 'FS Dillon';
        max-width: 464px;
      }
      .MicrosoftMap .Infobox .infobox-body {
        max-height: initial !important;
        max-width: initial !important;
        width: initial !important;
      }
      .MicrosoftMap .Infobox .infobox-info {
        font-family: 'FS Dillon';
        max-height: initial !important;
        padding: 40px
      }
      .MicrosoftMap .Infobox .infobox-title {
        display: none;
      }
      .MicrosoftMap .infobox-close {
        color: #777;
        height: 14px;
        outline: medium none;
        position: absolute;
        right: 16px;
        text-align: center;
        text-decoration: none;
        top: 16px;
        width: 14px;
        pointer-events: auto;
      }
      .MicrosoftMap .Infobox .infobox-title {
        color: #333;
      }
      .MicrosoftMap .Infobox .infobox-actions {
        border-top: 1px solid #888;
        margin: 0 0 -4px;
        padding: 4px 0;
        font-size: .9em;
        clear: both;
        padding: 4px 8px 8px;
        color: #676767;
        height: 15px;
        overflow: auto;
      }
      stalk, .MicrosoftMap .InfoboxCustom .infobox-stalk {
        width: 14px;
        height: 16px;
        background: no-repeat;
        position: absolute;
        bottom: 1px;
      }
      .tooltipWrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      .tooltipTitle{
        font-size: 24.41px;
        font-weight: 700;
        line-height: 31.4px;
      }
      .tooltipAddress {
        display: flex;
        flex-direction: row;
        gap: 32px;
        margin-top: 8px;
      }
      .addressTitle {
        color: ${colors.black};
        font-size: 15.63px;
        font-weight: 700;
        line-height: 15.63px;
        letter-spacing: -0.156px;
        padding-bottom: 8px;
        margin-bottom: 8px;
        border-bottom: 1px solid #E6C6FF;
      }
      .addressDetails {
        p {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.16px;
          color: #4F4F4E;
        }
        &.bluetext {
          p {
            color: #8F00FF;
            font-weight: 700;
          }
        }
      }
      .facilitiesWrapper {
        margin-top: 8px;
      }
      .facilitiesDetails {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        p {
          display: flex;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.16px;
          color: #4F4F4E;
          min-width: 185px;
          &:before {
            content: '';
            display: flex;
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
          &.type {
            &:before {
              background: transparent url(${publicPath('/images/common/venue/sports_soccer.svg')}) no-repeat
                center center;
            }
          }
          &.treatment {
            &:before {
              background: transparent url(${publicPath('/images/common/venue/medical_services.svg')}) no-repeat
                center center;
            }
          }
          &.facility {
            &:before {
              background: transparent url(${publicPath('/images/common/venue/school.svg')}) no-repeat
                center center;
            }
          }
        }
      }
      .actionCta {
        margin-top: 8px;
        width: 392px;
      }
      .createCta {
        width: 100%;
        height: 54px;
        border-radius: 4px;
        color: ${colors.black};
        background: #F3E3FF;
        border: none;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 366px;
        min-height: 44px;
        text-transform: uppercase;
        text-decoration: none;
        transition: background-color 0.2s ease-in;
        position: relative;
        white-space: nowrap;
        padding: 0 20px;
        box-sizing: border-box;
        span {
          display: flex;
          &:before {
            content: '';
            display: flex;
            width: 24px;
            height: 24px;
            margin-right: 8px;
            background: transparent url(${publicPath('/images/common/venue/school.svg')}) no-repeat
                center center;
          }
        }
      }
    `,
    list: css`
      display: flex;
      flex-direction: column;
      border: 1px solid ${colors.grey};
    `,
    listhead: css`
      display: grid;
      border-bottom: 1px solid ${colors.grey};
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      background-color: ${colors.lightGrey};

      > div {
        border-right: 1px solid ${colors.grey};
        padding: 0.8rem;
        font-weight: 700;
      }
    `,
    listrow: css`
      display: grid;
      border-bottom: 1px solid ${colors.grey};
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

      > div {
        border-right: 1px solid ${colors.grey};
        padding: 0.8rem;
      }
    `,
    editlist: css`
      color: ${colors.blue};
      text-decoration: underline;
    `,
    filterPopup: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    pdfDownload: utility.checkboxHidden,
  };
};
