import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const { colors, spacing, breakpoints, screenSizes, utility } = useTheme();

  return {
    venuelist: css`
      color: ${colors.white};
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.125rem;
      letter-spacing: 0.02em;
      text-transform: initial;
      padding: 58px 33px 40px 33px;
      background: ${colors.black};
      min-height: 100vh;
    `,
    footerWrapper: css`
    `,
    list: css`
      display: flex;
      flex-direction: column;
      border: 1px solid #4F4F4E;
    `,
    listhead: css`
      display: grid;
      border-bottom: 1px solid #4F4F4E;
      grid-template-columns: 20% 20% 10% 10% 20% 10% 10%;
      background-color: #F3E3FF;
      color: ${colors.black};

      > div {
        border-right: 1px solid #4F4F4E;
        padding: 0.8rem;
        font-weight: 700;
      }
    `,
    listrow: css`
      display: grid;
      border-bottom: 1px solid #4F4F4E;
      grid-template-columns: 20% 20% 10% 10% 20% 10% 10%;

      > div {
        border-right: 1px solid #4F4F4E;
        padding: 0.8rem;
      }
    `,
    even: css`
      background: #2D2D2B;
    `,
    editlist: css`
      color: #B7C5E9;
      text-decoration: underline;
      cursor: pointer;
      display: flex;
      &:before {
        content: '';
        display: flex;
        width: 16px;
        height: 16px;
        margin-right: 8px;
        background: url(${publicPath('/images/common/leftLinks/edit.svg')})
          no-repeat;
        background-size: contain;
      }
    `,
    pdfDownload: utility.checkboxHidden,
  };
};
