/** @jsxImportSource @emotion/react */
import { useState, useEffect, useRef } from 'react';
import { ArrowTriangle } from '../Icons';
import useStyles from './styles';

const CustomStatusDropDown = ({
  id,
  optionsList,
  selectedDefaultValue,
  ...props
}) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOptionId, setSelectedOptionId] =
    useState(selectedDefaultValue);
  const styles = useStyles();

  let menuRef = useRef();

  useEffect(() => {
    let handler = e => {
      if (!menuRef.current.contains(e.target)) {
        setIsOptionsOpen(false);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const handleOptionSelect = optionId => {
    setSelectedOptionId(optionId);
    setIsOptionsOpen(false);

    props?.onChange(
      optionId,
      props?.type,
      props?.refObject,
      props?.refId,
      props?.action,
      props?.locationObj
    );
  };

  const selectedOption = optionsList.find(
    option => option.id === selectedOptionId
  );

  return (
    <div css={styles.wrapper} id={id} ref={menuRef}>
      <div css={styles.container}>
        <button
          type="button"
          onClick={toggleOptions}
          css={
            props.style ? props.style : styles.dropDownButton(selectedOptionId)
          }
          aria-haspopup="listbox"
          aria-expanded={isOptionsOpen}
          tabIndex={0}
          //onKeyDown={toggleOptions}
          className={props.class}
        >
          {optionsList.length > 0 && selectedOption?.option}
          <span css={styles.arrow(isOptionsOpen)}>
            <ArrowTriangle width={10} height={5} />
          </span>
        </button>
        {isOptionsOpen && optionsList.length > 0 && (
          <ul
            role="listbox"
            css={styles.list}
            tabIndex={-1}
          >
            {optionsList.map((data, index) => {
              return (
                <li
                  key={data.id}
                  role="option"
                  tabIndex={0}
                  //aria-selected={selectedDefaultValue === index}
                  onClick={() => handleOptionSelect(data.id)}
                  onKeyDown={() => handleOptionSelect(data.id)}
                >
                  {data.option}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CustomStatusDropDown;
