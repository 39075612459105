import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors, spacing, typography, borderRadius } = useTheme();

  return {
    searchBoxContainer: css`
      width: 100%;
      max-width: 366px;
    `,
    inputWrapper: css`
      display: flex;
      border: 1px solid rgba(228, 228, 228, 0.60);
      border-radius: 5px;
      align-items: center;
      padding: 0 8px;
      width: 100%;
      margin-top: -10px;

      svg {
        color: ${colors.darkGrey};
      }
    `,
    input: css`
      ${typography.base};
      appearance: none;
      background-color: transparent !important;
      border: none !important;
      font: inherit;
      color: ${colors.lightBlue};
      line-height: inherit;
      padding: 10px 0 0 16px !important;
      transition-duration: 0.2s;
      transition-property: border-color;
      width: 100%;
      margin-top: 0;

      &:hover,
      &:focus {
        border-color: ${colors.navy};
        outline: none;
      }

      /* Chrome/Opera/Safari */
      &::-webkit-input-placeholder {
        color: ${colors.lightBlue};
      }

      /* firefox */
      &::-moz-placeholder {
        color: ${colors.lightBlue};
        opacity: 1;
      }

      /* IE 10+ */
      :-ms-input-placeholder {
        color: ${colors.lightBlue};
      }
    `,
    error: css`
      color: ${colors.red};
    `,
  };
};
