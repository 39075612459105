export const filterOptionsList = [
    {
      title: 'VENUE STATUS',
      filterList: {
        options: [
          {
            id: 1,
            optionVal: 'allVenues',
            optionLabel: 'ALL',
            icon: '',
          },
          {
            id: 2,
            optionVal: 'confirmedVenues',
            optionLabel: 'CONFIRMED',
            icon: '',
            images: '/images/common/list/green-dot.svg',
          },
          {
            id: 3,
            optionVal: 'pendingVenues',
            optionLabel: 'PENDING',
            icon: '',
            images: '/images/common/list/orange-triangle.svg',
          },
          // {
          //   id: 4,
          //   optionVal: 'declinedVenues',
          //   optionLabel: 'DECLINED',
          //   icon: '',
          //   images: '/images/common/list/red-square.svg',
          // },
        ],
      },
    },
    {
      title: 'COACH DEVELOPER STATUS',
      filterList: {
        options: [
          {
            id: 1,
            optionVal: 'allCoaches',
            optionLabel: 'ALL',
            icon: '',
          },
          {
            id: 2,
            optionVal: 'confirmedCoaches',
            optionLabel: 'CONFIRMED',
            icon: '',
            images: '/images/common/list/green-dot.svg',
          },
          {
            id: 3,
            optionVal: 'pendingCoaches',
            optionLabel: 'PENDING',
            icon: '',
            images: '/images/common/list/orange-triangle.svg',
          },
          {
            id: 4,
            optionVal: 'declinedCoaches',
            optionLabel: 'DECLINED',
            icon: '',
            images: '/images/common/list/red-square.svg',
          },
          {
            id: 4,
            optionVal: 'noncontactedCoaches',
            optionLabel: 'NOT CONTACTED',
            icon: '',
            images: '/images/common/list/gray-dot.svg',
          },
        ],
      },
    },
    {
      title: 'COURSE TYPE',
      filterList: {
        options: [
          {
            id: 0,
            optionVal: 'all',
            optionLabel: 'ALL',
            icon: '',
          },
          // {
          //   id: 1,
          //   optionVal: 'faplaymaker',
          //   optionLabel: 'The FA Playmaker',
          //   icon: '/images/common/filter/uefac.svg',
          // },
          // {
          //   id: 2,
          //   optionVal: 'faLevel1',
          //   optionLabel: 'The FA Level 1 in Coaching Football',
          //   icon: '/images/common/filter/gk.svg',
          // },
          // {
          //   id: 3,
          //   optionVal: 'introductiontocoaching',
          //   optionLabel: 'Introduction to Coaching Football',
          //   icon: '/images/common/filter/efaif.svg',
          // },
          // {
          //   id: 4,
          //   optionVal: 'nonFaUefaC',
          //   optionLabel: 'Non-FA UEFA C licence',
          //   icon: '/images/common/filter/i2cf.svg',
          // },
          // {
          //   id: 5,
          //   optionVal: 'faLevel2',
          //   optionLabel: 'The FA Level 2 in Coaching Football',
          //   icon: '/images/common/filter/uefab.svg',
          // },
          // {
          //   id: 6,
          //   optionVal: 'uefacLicence',
          //   optionLabel: 'UEFA C Licence',
          //   icon: '/images/common/filter/woc.svg',
          // },
          // {
          //   id: 7,
          //   optionVal: 'nonFaUefaB',
          //   optionLabel: 'Non-FA UEFA B Licence',
          //   icon: '/images/common/filter/futsal.svg',
          // },
          // {
          //   id: 8,
          //   optionVal: 'uefaBLicence',
          //   optionLabel: 'UEFA B Licence',
          //   icon: '/images/common/filter/uefac.svg',
          // },
          // {
          //   id: 9,
          //   optionVal: 'nonFaUefaA',
          //   optionLabel: 'Non-FA UEFA A Licence',
          //   icon: '/images/common/filter/gk.svg',
          // },
          // {
          //   id: 10,
          //   optionVal: 'faLevel4',
          //   optionLabel: 'The FA Level 4 (UEFA A) in Coaching Football',
          //   icon: '/images/common/filter/efaif.svg',
          // },
          // {
          //   id: 11,
          //   optionVal: 'nonFaUefaPro',
          //   optionLabel: 'Non-FA UEFA Pro Licence',
          //   icon: '/images/common/filter/i2cf.svg',
          // },
          // {
          //   id: 12,
          //   optionVal: 'faLevel5',
          //   optionLabel: 'The FA Level 5 (UEFA Pro) in Coaching Football',
          //   icon: '/images/common/filter/uefab.svg',
          // },
        ],
      },
    },
  ];

export const courseGroupDetails = 
[
  {
    courseGroupId: 18,
    courseId: 6,
    venueId: 34,
    courseGroupName: "Course 1 23/04/24",
    courseName: "UEFA C Licence",
    venueName: "Cheshire FA",
    plannedAttendance: 0,
    noTrainers: 2,
    noRunDays: 0,
    season: 0,
    startDate: "2024-04-24T00:00:00",
    endDate: "2024-04-29T00:00:00",
    resourced: 0,
    finalised: 0,
    locationLon: -2.531428,
    locationLat: 53.256707,
    notes: ""
  }
];

export const courseInstaceDetails =
[
  {
    courseInstanceId: 1,
    courseId: 1,
    venueId: 1,
    courseType: "The FA Playmaker",
    courseName: "Birmingham - play maker",
    locationLon: -1.92789,
    locationLat: 52.453282,
    startDate: "2023-11-06T19:36:16.067",
    endDate: "2023-11-09T19:36:16.067",
    noTrainers: 3,
    finalised: 0,
    trainers: [
      {
        trainersCourseInstanceId: 1,
        trainerId: 1,
        courseInstanceId: 1,
        confirmed: 1,
        declined: 0,
        contacted: 0,
        lastContacted: "2024-01-29T00:00:00"
      },
      {
        trainersCourseInstanceId: 2,
        trainerId: 2,
        courseInstanceId: 1,
        confirmed: 1,
        declined: 0,
        contacted: 0,
        lastContacted: "2024-01-29T00:00:00"
      }
    ],
    venues: [
      {
        venuesCourseInstanceId: 1,
        venueId: 1,
        courseInstanceId: 1,
        confirmed: 1,
        declined: 0,
        contacted: 0,
        lastContacted: "2024-01-29T00:00:00"
      }
    ]
  },
  {
    courseInstanceId: 2,
    courseId: 2,
    venueId: 0,
    courseType: "The FA Level 1 in Coaching Football",
    courseName: "Birmingham - FA level 1",
    locationLon: -1.961782,
    locationLat: 52.542679,
    startDate: "2023-11-17T00:00:00",
    endDate: "2023-11-22T00:00:00",
    noTrainers: 3,
    finalised: 0,
    trainers: [
      {
        trainersCourseInstanceId: 3,
        trainerId: 129,
        courseInstanceId: 2,
        confirmed: 1,
        declined: 0,
        contacted: 0,
        lastContacted: "2024-01-29T00:00:00"
      },
      {
        trainersCourseInstanceId: 4,
        trainerId: 90,
        courseInstanceId: 2,
        confirmed: 1,
        declined: 0,
        contacted: 0,
        lastContacted: "2024-02-02T00:00:00"
      },
      {
        trainersCourseInstanceId: 5,
        trainerId: 116,
        courseInstanceId: 2,
        confirmed: 1,
        declined: 0,
        contacted: 0,
        lastContacted: "2024-01-29T00:00:00"
      }
    ],
    venues: []
  },
  {
    courseInstanceId: 3,
    courseId: 3,
    venueId: 0,
    courseType: "Introduction to Coaching Football",
    courseName: null,
    locationLon: null,
    locationLat: null,
    startDate: "2023-11-20T00:00:00",
    endDate: "2023-11-26T00:00:00",
    noTrainers: 3,
    finalised: 0,
    trainers: [
      {
        trainersCourseInstanceId: 6,
        trainerId: 116,
        courseInstanceId: 3,
        confirmed: 0,
        declined: 0,
        contacted: 0,
        lastContacted: "2024-01-29T00:00:00"
      },
      {
        trainersCourseInstanceId: 7,
        trainerId: 129,
        courseInstanceId: 3,
        confirmed: 1,
        declined: 0,
        contacted: 0,
        lastContacted: "2024-02-05T00:00:00"
      }
    ],
    venues: []
  },
  {
    courseInstanceId: 34,
    courseId: 1,
    venueId: 0,
    courseType: "The FA Playmaker",
    courseName: null,
    locationLon: null,
    locationLat: null,
    startDate: "2024-01-31T00:00:00",
    endDate: "2024-02-03T00:00:00",
    noTrainers: 3,
    finalised: 0,
    trainers: [],
    venues: []
  },
  {
    courseInstanceId: 36,
    courseId: 4,
    venueId: 0,
    courseType: "Non-FA UEFA C licence",
    courseName: null,
    locationLon: null,
    locationLat: null,
    startDate: "2024-02-12T00:00:00",
    endDate: "2024-02-20T00:00:00",
    noTrainers: 5,
    finalised: 0,
    trainers: [],
    venues: []
  },
  {
    courseInstanceId: 37,
    courseId: 6,
    venueId: 0,
    courseType: "UEFA C Licence",
    courseName: "Test course 1",
    locationLon: -2.224464,
    locationLat: 51.872481,
    startDate: "2024-02-04T00:00:00",
    endDate: "2024-02-08T00:00:00",
    noTrainers: 4,
    finalised: 0,
    trainers: [],
    venues: []
  },
  {
    courseInstanceId: 38,
    courseId: 5,
    venueId: 0,
    courseType: "The FA Level 2 in Coaching Football",
    courseName: "Test course instance 2",
    locationLon: -2.531428,
    locationLat: 53.256707,
    startDate: "2024-02-04T00:00:00",
    endDate: "2024-02-12T00:00:00",
    noTrainers: 2,
    finalised: 0,
    trainers: [],
    venues: []
  },
  {
    courseInstanceId: 39,
    courseId: 5,
    venueId: 52,
    courseType: "The FA Level 2 in Coaching Football",
    courseName: "test course instance 3",
    locationLon: -2.224464,
    locationLat: 51.872481,
    startDate: "2024-02-13T00:00:00",
    endDate: "2024-02-17T00:00:00",
    noTrainers: 1,
    finalised: 0,
    trainers: [],
    venues: [
      {
        venuesCourseInstanceId: 5,
        venueId: 52,
        courseInstanceId: 39,
        confirmed: 1,
        declined: 0,
        contacted: 0,
        lastContacted: "2024-02-01T00:00:00"
      }
    ]
  },
  {
    courseInstanceId: 41,
    courseId: 5,
    venueId: 0,
    courseType: "The FA Level 2 in Coaching Football",
    courseName: "test course instance 3",
    locationLon: -2.224464,
    locationLat: 51.872481,
    startDate: "2024-02-13T00:00:00",
    endDate: "2024-02-17T00:00:00",
    noTrainers: 1,
    finalised: 0,
    trainers: [],
    venues: []
  },
  {
    courseInstanceId: 42,
    courseId: 7,
    venueId: 7,
    courseType: "Non-FA UEFA B Licence",
    courseName: "test course instance 4",
    locationLon: -1.22952,
    locationLat: 52.764828,
    startDate: "2024-02-05T00:00:00",
    endDate: "2024-02-12T00:00:00",
    noTrainers: 6,
    finalised: 0,
    trainers: [],
    venues: [
      {
        venuesCourseInstanceId: 6,
        venueId: 7,
        courseInstanceId: 42,
        confirmed: 1,
        declined: 0,
        contacted: 0,
        lastContacted: "2024-02-04T00:00:00"
      }
    ]
  },
  {
    courseInstanceId: 43,
    courseId: 5,
    venueId: 12,
    courseType: "The FA Level 2 in Coaching Football",
    courseName: "course test ",
    locationLon: -0.641202,
    locationLat: 53.559932,
    startDate: "2024-02-04T00:00:00",
    endDate: "2024-02-08T00:00:00",
    noTrainers: 5,
    finalised: 0,
    trainers: [
      {
        trainersCourseInstanceId: 98,
        trainerId: 47,
        courseInstanceId: 43,
        confirmed: 0,
        declined: 0,
        contacted: 1,
        lastContacted: "2024-02-05T00:00:00"
      },
      {
        trainersCourseInstanceId: 99,
        trainerId: 29,
        courseInstanceId: 43,
        confirmed: 0,
        declined: 0,
        contacted: 1,
        lastContacted: "2024-02-05T00:00:00"
      }
    ],
    venues: [
      {
        venuesCourseInstanceId: 7,
        venueId: 12,
        courseInstanceId: 43,
        confirmed: 1,
        declined: 0,
        contacted: 0,
        lastContacted: "2024-02-02T00:00:00"
      },
      {
        venuesCourseInstanceId: 8,
        venueId: 56,
        courseInstanceId: 43,
        confirmed: 0,
        declined: 0,
        contacted: 1,
        lastContacted: "2024-02-05T00:00:00"
      },
      {
        venuesCourseInstanceId: 9,
        venueId: 54,
        courseInstanceId: 43,
        confirmed: 0,
        declined: 0,
        contacted: 1,
        lastContacted: "2024-02-05T00:00:00"
      }
    ]
  },
  {
    courseInstanceId: 45,
    courseId: 6,
    venueId: 0,
    courseType: "UEFA C Licence",
    courseName: "New course 05/02/24",
    locationLon: -1.238123,
    locationLat: 53.170047,
    startDate: "2024-02-07T00:00:00",
    endDate: "2024-02-14T00:00:00",
    noTrainers: 5,
    finalised: 0,
    trainers: [
      {
        trainersCourseInstanceId: 100,
        trainerId: 47,
        courseInstanceId: 45,
        confirmed: 0,
        declined: 0,
        contacted: 0,
        lastContacted: "2024-02-05T00:00:00"
      },
      {
        trainersCourseInstanceId: 101,
        trainerId: 29,
        courseInstanceId: 45,
        confirmed: 0,
        declined: 0,
        contacted: 1,
        lastContacted: "2024-02-05T00:00:00"
      }
    ],
    venues: [
      {
        venuesCourseInstanceId: 10,
        venueId: 10,
        courseInstanceId: 45,
        confirmed: 0,
        declined: 0,
        contacted: 1,
        lastContacted: "2024-02-05T00:00:00"
      }
    ]
  },
  {
    courseInstanceId: 46,
    courseId: 1,
    venueId: 0,
    courseType: "The FA Playmaker",
    courseName: "New Course  06 Feb 24",
    locationLon: -0.553674,
    locationLat: 53.228303,
    startDate: "2024-02-08T00:00:00",
    endDate: "2024-02-14T00:00:00",
    noTrainers: 5,
    finalised: 0,
    trainers: [],
    venues: [
      {
        venuesCourseInstanceId: 11,
        venueId: 23,
        courseInstanceId: 46,
        confirmed: 0,
        declined: 0,
        contacted: 1,
        lastContacted: "2024-02-06T00:00:00"
      }
    ]
  },
  {
    courseInstanceId: 47,
    courseId: 1,
    venueId: 36,
    courseType: "The FA Playmaker",
    courseName: "My new course for FEb",
    locationLon: -2.181159,
    locationLat: 53.516384,
    startDate: "2024-02-07T00:00:00",
    endDate: "2024-02-17T00:00:00",
    noTrainers: 5,
    finalised: 1,
    trainers: [
      {
        trainersCourseInstanceId: 102,
        trainerId: 47,
        courseInstanceId: 47,
        confirmed: 1,
        declined: 0,
        contacted: 0,
        lastContacted: "2024-02-06T00:00:00"
      },
      {
        trainersCourseInstanceId: 103,
        trainerId: 29,
        courseInstanceId: 47,
        confirmed: 1,
        declined: 0,
        contacted: 0,
        lastContacted: "2024-02-06T00:00:00"
      },
      {
        trainersCourseInstanceId: 104,
        trainerId: 47,
        courseInstanceId: 47,
        confirmed: 1,
        declined: 0,
        contacted: 0,
        lastContacted: "2024-02-06T00:00:00"
      },
      {
        trainersCourseInstanceId: 105,
        trainerId: 30,
        courseInstanceId: 47,
        confirmed: 1,
        declined: 0,
        contacted: 0,
        lastContacted: "2024-02-06T00:00:00"
      },
      {
        trainersCourseInstanceId: 106,
        trainerId: 28,
        courseInstanceId: 47,
        confirmed: 1,
        declined: 0,
        contacted: 0,
        lastContacted: "2024-02-06T00:00:00"
      },
      {
        trainersCourseInstanceId: 107,
        trainerId: 124,
        courseInstanceId: 47,
        confirmed: 1,
        declined: 0,
        contacted: 0,
        lastContacted: "2024-02-06T00:00:00"
      }
    ],
    venues: [
      {
        venuesCourseInstanceId: 12,
        venueId: 36,
        courseInstanceId: 47,
        confirmed: 1,
        declined: 0,
        contacted: 0,
        lastContacted: "2024-02-06T00:00:00"
      }
    ]
  }
];

export const venueInstanceByDist =
[
  {
    venueId: 48,
    region: "South East",
    venue1: "Bay Point Sports Club",
    address: "Ramsgate Road, Sandwich, Kent",
    postCode: "CT13 9QL",
    locationLon: 1.35269,
    locationLat: 51.311025,
    contactName: "Contact48",
    email: "Contact48@zonedigital.com",
    phone: "16879736",
    classroomSize: null,
    _3g: false,
    grassPitch: false,
    futsal: false,
    costperday: null,
    comments: null
  },
  {
    venueId: 18,
    region: "East",
    venue1: "Barnards Soccer Centre",
    address: "Barnards Soccer Centre - Barnards Way, Lowestoft",
    postCode: "NR32 2HF",
    locationLon: 1.73218,
    locationLat: 52.478355,
    contactName: "Contact18",
    email: "Contact18@zonedigital.com",
    phone: "93833649",
    classroomSize: 25,
    _3g: false,
    grassPitch: true,
    futsal: false,
    costperday: 200,
    comments: null
  },
  {
    venueId: 46,
    region: "South East",
    venue1: "Heybridge Swifts FC",
    address: "Scraley Road, Heybridge, Maldon, Essex",
    postCode: "CM9 8JA",
    locationLon: 0.708223,
    locationLat: 51.749005,
    contactName: "Contact46",
    email: "Contact46@zonedigital.com",
    phone: "89187419",
    classroomSize: 34,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: 355,
    comments: null
  },
  {
    venueId: 19,
    region: "East",
    venue1: "Uni of East Anglia",
    address: "Norwich Sports Park (Uni of East Anglia), Norwich Research Park",
    postCode: "NR4 7TJ",
    locationLon: 1.241391,
    locationLat: 52.622369,
    contactName: "Contact19",
    email: "Contact19@zonedigital.com",
    phone: "72043269",
    classroomSize: 37,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: 532.5,
    comments: null
  },
  {
    venueId: 16,
    region: "East",
    venue1: "Norfolk FA",
    address: "Bowthorpe Park, Clover Hill Road, New Costessey, Norwich",
    postCode: "NR5 9ED",
    locationLon: 1.221983,
    locationLat: 52.64201,
    contactName: "Contact16",
    email: "Contact16@zonedigital.com",
    phone: "28159041",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: null,
    comments: null
  },
  {
    venueId: 62,
    region: "London",
    venue1: "Dunraven School",
    address: "94/98 Leigham Court Road Streatham, London",
    postCode: "SW16 2QB",
    locationLon: -0.121593,
    locationLat: 51.433597,
    contactName: "Contact62",
    email: "Contact62@zonedigital.com",
    phone: "67950265",
    classroomSize: null,
    _3g: false,
    grassPitch: false,
    futsal: true,
    costperday: null,
    comments: null
  },
  {
    venueId: 47,
    region: "South East",
    venue1: "Surrey FA",
    address: "Meadowbank Football Ground, Mill Lane, Dorking",
    postCode: "RH4 1DX",
    locationLon: -0.331673,
    locationLat: 51.233759,
    contactName: "Contact47",
    email: "Contact47@zonedigital.com",
    phone: "88047216",
    classroomSize: 24,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: 287.5,
    comments: null
  },
  {
    venueId: 60,
    region: "London",
    venue1: "New River Sports and Fitness",
    address: "White Hart Lane, London",
    postCode: "N22 5QW",
    locationLon: -0.100032,
    locationLat: 51.604659,
    contactName: "Contact60",
    email: "Contact60@zonedigital.com",
    phone: "32663589",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 550,
    comments: null
  },
  {
    venueId: 61,
    region: "London",
    venue1: "Gunnersbury Park Sports Hub",
    address: "Popes Lane, London W3 8LQ",
    postCode: "W3 8LQ",
    locationLon: -0.286985,
    locationLat: 51.499474,
    contactName: "Contact61",
    email: "Contact61@zonedigital.com",
    phone: "43952249",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 543,
    comments: null
  },
  {
    venueId: 44,
    region: "South ",
    venue1: "Priory School",
    address: "Fawcett Road, Southsea, Hants",
    postCode: "PO4 0DL",
    locationLon: -1.076776,
    locationLat: 50.79501,
    contactName: "Contact44",
    email: "Contact44@zonedigital.com",
    phone: "29533243",
    classroomSize: null,
    _3g: true,
    grassPitch: false,
    futsal: true,
    costperday: 188,
    comments: null
  },
  {
    venueId: 41,
    region: "South ",
    venue1: "Navy FA ",
    address: "Burnaby Rd, Portsmouth",
    postCode: "PO1 2HB",
    locationLon: -1.098859,
    locationLat: 50.794697,
    contactName: "Contact41",
    email: "Contact41@zonedigital.com",
    phone: "39793435",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: null,
    comments: null
  },
  {
    venueId: 22,
    region: "East",
    venue1: "Cambourne Sports Centre",
    address: "Centre, Back Ln, Great Cambourne, Cambourne, Cambridge",
    postCode: "CB23 6FY",
    locationLon: -0.064567,
    locationLat: 52.22036,
    contactName: "Contact22",
    email: "Contact22@zonedigital.com",
    phone: "36867355",
    classroomSize: 24,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: 435.6,
    comments: null
  },
  {
    venueId: 59,
    region: "London",
    venue1: "University of Southampton Sports Ground",
    address: "Wide Lane, Eastleigh",
    postCode: "SO50 5PE",
    locationLon: -1.364333,
    locationLat: 50.955416,
    contactName: "Contact59",
    email: "Contact59@zonedigital.com",
    phone: "39147836",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: true,
    costperday: 595.4,
    comments: null
  },
  {
    venueId: 21,
    region: "East",
    venue1: "University of Bedfordshire",
    address: "Bedford Campus, Polhill Avenue, Bedford",
    postCode: "MK41 9EA",
    locationLon: -0.442323,
    locationLat: 52.141357,
    contactName: "Contact21",
    email: "Contact21@zonedigital.com",
    phone: "46004637",
    classroomSize: 28,
    _3g: false,
    grassPitch: true,
    futsal: true,
    costperday: 346.67,
    comments: null
  },
  {
    venueId: 51,
    region: "South West",
    venue1: "Bradfield College",
    address: "Bradfield, Berkshire",
    postCode: "RG7 6AU",
    locationLon: -1.131851,
    locationLat: 51.448748,
    contactName: "Contact51",
    email: "Contact51@zonedigital.com",
    phone: "67477459",
    classroomSize: 34,
    _3g: false,
    grassPitch: true,
    futsal: true,
    costperday: 450,
    comments: null
  },
  {
    venueId: 20,
    region: "East",
    venue1: "Bedfordshire FA",
    address: "Cranfield Pavilion, Lower Breaches Gardens, Cranfield",
    postCode: "MK43 1AH",
    locationLon: -0.603947,
    locationLat: 52.07808,
    contactName: "Contact20",
    email: "Contact20@zonedigital.com",
    phone: "84315901",
    classroomSize: 37,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 300,
    comments: null
  },
  {
    venueId: 57,
    region: "South West",
    venue1: "Sir Herbet Leon Academy",
    address: "Fern Grove, Bletchley",
    postCode: "MK2 3HQ",
    locationLon: -0.727178,
    locationLat: 51.980264,
    contactName: "Contact57",
    email: "Contact57@zonedigital.com",
    phone: "20869602",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 270,
    comments: null
  },
  {
    venueId: 43,
    region: "South ",
    venue1: "Dorset FA",
    address: "Blandford Close, Poole, Dorset",
    postCode: "BH15 4BF",
    locationLon: -2.002327,
    locationLat: 50.714499,
    contactName: "Contact43",
    email: "Contact43@zonedigital.com",
    phone: "37307192",
    classroomSize: 28,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: 420,
    comments: null
  },
  {
    venueId: 42,
    region: "South ",
    venue1: "Potterne Park",
    address: "Potterne Way, Verwood",
    postCode: "BH21 6RS",
    locationLon: -1.866261,
    locationLat: 50.866469,
    contactName: "Contact42",
    email: "Contact42@zonedigital.com",
    phone: "96001271",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: null,
    comments: null
  },
  {
    venueId: 58,
    region: "South West",
    venue1: "St Edwards School",
    address: "Woodstock Road, Oxford ",
    postCode: "OX2 7NN",
    locationLon: -1.267934,
    locationLat: 51.776993,
    contactName: "Contact58",
    email: "Contact58@zonedigital.com",
    phone: "93605212",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: true,
    costperday: 250,
    comments: null
  },
  {
    venueId: 17,
    region: "East",
    venue1: "Moulton College",
    address: "West Street, Moulton, Northamptonshire",
    postCode: "NN3 7RR",
    locationLon: -0.857756,
    locationLat: 52.289955,
    contactName: "Contact17",
    email: "Contact17@zonedigital.com",
    phone: "56400331",
    classroomSize: 37,
    _3g: true,
    grassPitch: true,
    futsal: true,
    costperday: 576,
    comments: null
  },
  {
    venueId: 35,
    region: "North West",
    venue1: "Wiltshire FA ",
    address: "Green Lane Playing Fields, Green Ln, Devizes ",
    postCode: "SN10 5EP",
    locationLon: -1.983636,
    locationLat: 51.338904,
    contactName: "Contact35",
    email: "Contact35@zonedigital.com",
    phone: "92946371",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 600,
    comments: null
  },
  {
    venueId: 9,
    region: "East Midlands",
    venue1: "Leicester City Women's Training Ground",
    address: "BELVOIR DRIVE, LEICESTER",
    postCode: "LE2 8PA  ",
    locationLon: -1.151668,
    locationLat: 52.602164,
    contactName: "Contact9",
    email: "Contact9@zonedigital.com",
    phone: "42308998",
    classroomSize: 37,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 596.32,
    comments: null
  },
  {
    venueId: 15,
    region: "East Midlands",
    venue1: "Queen Elizabeth II Diamond Jubilee Centre",
    address: "50 Duns Lane, Leicester ",
    postCode: "LE3 5LX",
    locationLon: -1.144253,
    locationLat: 52.633019,
    contactName: "Contact15",
    email: "Contact15@zonedigital.com",
    phone: "80629485",
    classroomSize: null,
    _3g: false,
    grassPitch: false,
    futsal: true,
    costperday: null,
    comments: null
  },
  {
    venueId: 23,
    region: "East",
    venue1: "University of Lincoln",
    address: "TBC",
    postCode: "LN6 7BP",
    locationLon: -0.553674,
    locationLat: 53.228303,
    contactName: "Contact23",
    email: "Contact23@zonedigital.com",
    phone: "24697487",
    classroomSize: null,
    _3g: false,
    grassPitch: false,
    futsal: true,
    costperday: null,
    comments: null
  },
  {
    venueId: 53,
    region: "South West",
    venue1: "Strode College",
    address: "Church Road, Street, Somerset",
    postCode: "BA16 0AB",
    locationLon: -2.734039,
    locationLat: 51.130495,
    contactName: "Contact53",
    email: "Contact53@zonedigital.com",
    phone: "76927230",
    classroomSize: 34,
    _3g: true,
    grassPitch: false,
    futsal: true,
    costperday: 360,
    comments: null
  },
  {
    venueId: 7,
    region: "East Midlands",
    venue1: "Loughborough University",
    address: "Sir John Beckwith Centre for Sport, Loughborough University, University Rd, Loughborough",
    postCode: "LE11 3TU ",
    locationLon: -1.22952,
    locationLat: 52.764828,
    contactName: "Contact7",
    email: "Contact7@zonedigital.com",
    phone: "65241348",
    classroomSize: 37,
    _3g: true,
    grassPitch: true,
    futsal: true,
    costperday: 687,
    comments: null
  },
  {
    venueId: 11,
    region: "East Midlands",
    venue1: "Highfields (Uni of Notts)",
    address: "Highfields Sports Complex, Beeston",
    postCode: "NG7 2PS",
    locationLon: -1.197303,
    locationLat: 52.931986,
    contactName: "Contact11",
    email: "Contact11@zonedigital.com",
    phone: "60936904",
    classroomSize: 37,
    _3g: false,
    grassPitch: true,
    futsal: false,
    costperday: 480,
    comments: null
  },
  {
    venueId: 52,
    region: "South West",
    venue1: "University of Gloucestershire Oxstalls Campus",
    address: "Oxstalls Ln, Longlevens ",
    postCode: "GL2 9HW",
    locationLon: -2.224464,
    locationLat: 51.872481,
    contactName: "Contact52",
    email: "Contact52@zonedigital.com",
    phone: "12644392",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: true,
    costperday: 567.6,
    comments: null
  },
  {
    venueId: 55,
    region: "South West",
    venue1: "Gloucestershire FA",
    address: "Gloucester Road, Almondsbury, Bristol",
    postCode: "BS32 4AG",
    locationLon: -2.571002,
    locationLat: 51.548586,
    contactName: "Contact55",
    email: "Contact55@zonedigital.com",
    phone: "48608912",
    classroomSize: 34,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: null,
    comments: null
  },
  {
    venueId: 14,
    region: "East Midlands",
    venue1: "Gainsborough Trinity FC",
    address: "Roses Sports Ground, North Warren Road, Gainsborough",
    postCode: "DN21 2TU",
    locationLon: -0.786657,
    locationLat: 53.408758,
    contactName: "Contact14",
    email: "Contact14@zonedigital.com",
    phone: "54397823",
    classroomSize: 28,
    _3g: false,
    grassPitch: false,
    futsal: false,
    costperday: null,
    comments: null
  },
  {
    venueId: 12,
    region: "East Midlands",
    venue1: "Frederick Gough School",
    address: "Grange Lane South, Scunthorpe",
    postCode: "DN16 3NG",
    locationLon: -0.641202,
    locationLat: 53.559932,
    contactName: "Contact12",
    email: "Contact12@zonedigital.com",
    phone: "98444232",
    classroomSize: 32,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 294,
    comments: null
  },
  {
    venueId: 4,
    region: "West Midlands",
    venue1: "Tamworth Enterprise College",
    address: "Birds Bush Rd, Tamworth",
    postCode: "B77 2NE",
    locationLon: -1.670292,
    locationLat: 52.615565,
    contactName: "Contact4",
    email: "Contact4@zonedigital.com",
    phone: "22526382",
    classroomSize: 37,
    _3g: false,
    grassPitch: false,
    futsal: false,
    costperday: 440,
    comments: null
  },
  {
    venueId: 54,
    region: "South West",
    venue1: "Devon FA",
    address: "Coach Rd, Newton Abbot",
    postCode: "TQ12 1EJ",
    locationLon: -3.606502,
    locationLat: 50.522468,
    contactName: "Contact54",
    email: "Contact54@zonedigital.com",
    phone: "12980745",
    classroomSize: 34,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: 150,
    comments: null
  },
  {
    venueId: 1,
    region: "West Midlands",
    venue1: "Birmingham University Sport & Fitness ",
    address: "University of, Bristol Rd, Birmingham",
    postCode: "B15 2TT",
    locationLon: -1.92789,
    locationLat: 52.453282,
    contactName: "Contact1",
    email: "Contacttest@zonedigital.com",
    phone: "76108167",
    classroomSize: 35,
    _3g: true,
    grassPitch: false,
    futsal: true,
    costperday: 380.8,
    comments: null
  },
  {
    venueId: 63,
    region: "West Midlands",
    venue1: "Birmingham University Sport & Fitness update",
    address: "University of, Bristol Rd, Birmingham",
    postCode: "B15 2TT",
    locationLon: -1.92789,
    locationLat: 52.453282,
    contactName: "Contact1",
    email: "Contact1@zonedigital.com",
    phone: "76108167",
    classroomSize: 37,
    _3g: true,
    grassPitch: false,
    futsal: true,
    costperday: 380.8,
    comments: null
  },
  {
    venueId: 64,
    region: "West Midlands",
    venue1: "Birmingham University Sport & Fitness update",
    address: "University of, Bristol Rd, Birmingham",
    postCode: "B15 2TT",
    locationLon: -1.92789,
    locationLat: 52.453282,
    contactName: "Contact1",
    email: "Contact1@zonedigital.com",
    phone: "76108167",
    classroomSize: 37,
    _3g: true,
    grassPitch: false,
    futsal: true,
    costperday: 380.8,
    comments: null
  },
  {
    venueId: 10,
    region: "East Midlands",
    venue1: "Mansfield Town Academy",
    address: "Mansfield RH Academy, Woburn Ln, Pleasley, Mansfield",
    postCode: "NG19 7RT",
    locationLon: -1.238123,
    locationLat: 53.170047,
    contactName: "Contact10",
    email: "Contact10@zonedigital.com",
    phone: "18554886",
    classroomSize: 37,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 250,
    comments: null
  },
  {
    venueId: 6,
    region: "West Midlands",
    venue1: "Worcestershire County Sports Ground",
    address: "Claines Ln, Worcester ",
    postCode: "WR3 7SS",
    locationLon: -2.212325,
    locationLat: 52.223533,
    contactName: "Contact6",
    email: "Contact6@zonedigital.com",
    phone: "11895456",
    classroomSize: 37,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 490,
    comments: null
  },
  {
    venueId: 5,
    region: "West Midlands",
    venue1: "Birmingham CFA",
    address: "Ray Hall Lane, Great Barr, Birmingham",
    postCode: "B43 6JF",
    locationLon: -1.961782,
    locationLat: 52.542679,
    contactName: "Contact5",
    email: "Contact5@zonedigital.com",
    phone: "53630723",
    classroomSize: 37,
    _3g: false,
    grassPitch: true,
    futsal: false,
    costperday: 400,
    comments: null
  },
  {
    venueId: 8,
    region: "East Midlands",
    venue1: "St George's Park",
    address: "Newborough Rd, Needwood, Burton-on-Trent",
    postCode: "DE13 9PD",
    locationLon: -1.760102,
    locationLat: 52.817733,
    contactName: "Contact8",
    email: "Contact8@zonedigital.com",
    phone: "36886835",
    classroomSize: 37,
    _3g: true,
    grassPitch: true,
    futsal: true,
    costperday: 420,
    comments: null
  },
  {
    venueId: 3,
    region: "West Midlands",
    venue1: "Wolverhampton College",
    address: "Wellington Road Campus, Bilston, Wolverhampton ",
    postCode: "WV14 6BT",
    locationLon: -2.091896,
    locationLat: 52.572549,
    contactName: "Contact3",
    email: "Contact3@zonedigital.com",
    phone: "18897335",
    classroomSize: 37,
    _3g: true,
    grassPitch: true,
    futsal: true,
    costperday: 125,
    comments: null
  },
  {
    venueId: 56,
    region: "South West",
    venue1: "Plymouth Marjon University",
    address: "Plymbridge Lane, Plymouth ",
    postCode: "PL6 8BH",
    locationLon: -4.110758,
    locationLat: 50.420866,
    contactName: "Contact56",
    email: "Contact56@zonedigital.com",
    phone: "73854534",
    classroomSize: 32,
    _3g: true,
    grassPitch: true,
    futsal: true,
    costperday: 400,
    comments: null
  },
  {
    venueId: 13,
    region: "East Midlands",
    venue1: "SGP Thorncliffe",
    address: "Pack Horse Lane, High Green, Sheffield",
    postCode: "S35 3HY",
    locationLon: -1.48341,
    locationLat: 53.475219,
    contactName: "Contact13",
    email: "Contact13@zonedigital.com",
    phone: "50038221",
    classroomSize: 37,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 313,
    comments: null
  },
  {
    venueId: 27,
    region: "North East",
    venue1: "Barnsley FC ",
    address: "Oakwell Stadium, Grove St, Barnsley",
    postCode: "S71 1ET",
    locationLon: -1.468631,
    locationLat: 53.552266,
    contactName: "Contact27",
    email: "Contact27@zonedigital.com",
    phone: "64131470",
    classroomSize: 20,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: 560,
    comments: "&Sheet1!M3&"
  },
  {
    venueId: 26,
    region: "North East",
    venue1: "York Stadium - LNER",
    address: "Kathryn Avenue, Monks Cross, York ",
    postCode: " YO32 9AF",
    locationLon: -1.052638,
    locationLat: 53.983465,
    contactName: "Contact26",
    email: "Contact26@zonedigital.com",
    phone: "26889376",
    classroomSize: 34,
    _3g: false,
    grassPitch: true,
    futsal: false,
    costperday: 500,
    comments: null
  },
  {
    venueId: 2,
    region: "West Midlands",
    venue1: "Lillehall National Sports Centre",
    address: "Near Newport",
    postCode: "TF10 9AT",
    locationLon: -2.37207,
    locationLat: 52.72799,
    contactName: "Contact2",
    email: "Contact2@zonedigital.com",
    phone: "6926680",
    classroomSize: 37,
    _3g: false,
    grassPitch: true,
    futsal: false,
    costperday: 461,
    comments: null
  },
  {
    venueId: 30,
    region: "North East",
    venue1: "John Charles Centre for Sport",
    address: "Middleton Grove, Belle Isle, Leeds",
    postCode: "LS11 5DJ",
    locationLon: -1.541945,
    locationLat: 53.764305,
    contactName: "Contact30",
    email: "Contact30@zonedigital.com",
    phone: "824640",
    classroomSize: null,
    _3g: false,
    grassPitch: false,
    futsal: false,
    costperday: null,
    comments: null
  },
  {
    venueId: 25,
    region: "North East",
    venue1: "Leeds Beckett University",
    address: "City Campus, Leeds",
    postCode: "LS1 3HE",
    locationLon: -1.548578,
    locationLat: 53.80402,
    contactName: "Contact25",
    email: "Contact25@zonedigital.com",
    phone: "67533804",
    classroomSize: 34,
    _3g: true,
    grassPitch: false,
    futsal: true,
    costperday: 484,
    comments: null
  },
  {
    venueId: 36,
    region: "North West",
    venue1: "FC United of Manchester",
    address: "Broadhurst Park, 310 Lightbowne Road, Moston, Manchester",
    postCode: "M40 0FJ",
    locationLon: -2.181159,
    locationLat: 53.516384,
    contactName: "Contact36",
    email: "Contact36@zonedigital.com",
    phone: "2243802",
    classroomSize: 34,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: 334.4,
    comments: null
  },
  {
    venueId: 28,
    region: "North East",
    venue1: "Redcar & Cleveland College",
    address: "Corporation Rd, Redcar",
    postCode: "TS10 1EZ",
    locationLon: -1.075195,
    locationLat: 54.614542,
    contactName: "Contact28",
    email: "Contact28@zonedigital.com",
    phone: "24239888",
    classroomSize: 28,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 230,
    comments: null
  },
  {
    venueId: 34,
    region: "North West",
    venue1: "Cheshire FA",
    address: "Cheshire FA, Northwich",
    postCode: "CW8 4BG",
    locationLon: -2.531428,
    locationLat: 53.256707,
    contactName: "Contact34",
    email: "Contact34@zonedigital.com",
    phone: "2212638",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 565,
    comments: null
  },
  {
    venueId: 37,
    region: "North West",
    venue1: "Hope Sports Park",
    address: "Liverpool Hope University, Hope Park, Liverpool",
    postCode: "L16 9JD",
    locationLon: -2.891907,
    locationLat: 53.391527,
    contactName: "Contact37",
    email: "Contact37@zonedigital.com",
    phone: "15805522",
    classroomSize: null,
    _3g: false,
    grassPitch: false,
    futsal: false,
    costperday: null,
    comments: null
  },
  {
    venueId: 31,
    region: "North East",
    venue1: "Beacon of Light",
    address: "Stadium Way, Monkwearmouth, Sunderland",
    postCode: "SR5 1SN",
    locationLon: -1.389229,
    locationLat: 54.915491,
    contactName: "Contact31",
    email: "Contact31@zonedigital.com",
    phone: "99554876",
    classroomSize: null,
    _3g: true,
    grassPitch: false,
    futsal: true,
    costperday: null,
    comments: null
  },
  {
    venueId: 24,
    region: "North East",
    venue1: "Durham University ",
    address: "Graham Sports Centre, Maiden Castle, Stockton Road, Durham",
    postCode: "DH1 3SE",
    locationLon: -1.558689,
    locationLat: 54.767121,
    contactName: "Contact24",
    email: "Contact24@zonedigital.com",
    phone: "35301400",
    classroomSize: 34,
    _3g: false,
    grassPitch: true,
    futsal: true,
    costperday: 570,
    comments: null
  },
  {
    venueId: 29,
    region: "North East",
    venue1: "Ford Quarry Hub",
    address: "Keelmans Lane, South Hylton, Sunderland",
    postCode: "SR4 0RW",
    locationLon: -1.437029,
    locationLat: 54.908632,
    contactName: "Contact29",
    email: "Contact29@zonedigital.com",
    phone: "5631950",
    classroomSize: null,
    _3g: false,
    grassPitch: false,
    futsal: false,
    costperday: null,
    comments: null
  },
  {
    venueId: 33,
    region: "North West",
    venue1: "Lancashire FA",
    address: "County Ground, Thurston Road, Leyland",
    postCode: "PR25 2LF",
    locationLon: -2.693803,
    locationLat: 53.693159,
    contactName: "Contact33",
    email: "Contact33@zonedigital.com",
    phone: "36549454",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 250,
    comments: null
  },
  {
    venueId: 38,
    region: "North West",
    venue1: "LCFA Sefton",
    address: "Drummond Road, Thornton, Liverpool",
    postCode: "L23 9YP",
    locationLon: -2.998916,
    locationLat: 53.493667,
    contactName: "Contact38",
    email: "Contact38@zonedigital.com",
    phone: "92186740",
    classroomSize: 34,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: 400,
    comments: null
  },
  {
    venueId: 32,
    region: "North East",
    venue1: "Northumbria University- Sport Central",
    address: "Northumberland Rd, Newcastle upon Tyne",
    postCode: "NE1 8ST",
    locationLon: -1.60745,
    locationLat: 54.976634,
    contactName: "Contact32",
    email: "Contact32@zonedigital.com",
    phone: "19668330",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: true,
    costperday: 680,
    comments: null
  },
  {
    venueId: 39,
    region: "North West",
    venue1: "The Grizedale Hub",
    address: "Newton Rigg, Penrith, Cumbria",
    postCode: "CA11 0AH",
    locationLon: -2.788515,
    locationLat: 54.67166,
    contactName: "Contact39",
    email: "Contact39@zonedigital.com",
    phone: "72039337",
    classroomSize: null,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: null,
    comments: null
  }
];


export const trainerInstByDistance =
[
  {
    venueId: 48,
    region: "South East",
    venue1: "Bay Point Sports Club",
    address: "Ramsgate Road, Sandwich, Kent",
    postCode: "CT13 9QL",
    locationLon: 1.35269,
    locationLat: 51.311025,
    contactName: "Contact48",
    email: "Contact48@zonedigital.com",
    phone: "16879736",
    classroomSize: null,
    _3g: false,
    grassPitch: false,
    futsal: false,
    costperday: null,
    comments: null
  },
  {
    venueId: 18,
    region: "East",
    venue1: "Barnards Soccer Centre",
    address: "Barnards Soccer Centre - Barnards Way, Lowestoft",
    postCode: "NR32 2HF",
    locationLon: 1.73218,
    locationLat: 52.478355,
    contactName: "Contact18",
    email: "Contact18@zonedigital.com",
    phone: "93833649",
    classroomSize: 25,
    _3g: false,
    grassPitch: true,
    futsal: false,
    costperday: 200,
    comments: null
  },
  {
    venueId: 46,
    region: "South East",
    venue1: "Heybridge Swifts FC",
    address: "Scraley Road, Heybridge, Maldon, Essex",
    postCode: "CM9 8JA",
    locationLon: 0.708223,
    locationLat: 51.749005,
    contactName: "Contact46",
    email: "Contact46@zonedigital.com",
    phone: "89187419",
    classroomSize: 34,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: 355,
    comments: null
  },
  {
    venueId: 19,
    region: "East",
    venue1: "Uni of East Anglia",
    address: "Norwich Sports Park (Uni of East Anglia), Norwich Research Park",
    postCode: "NR4 7TJ",
    locationLon: 1.241391,
    locationLat: 52.622369,
    contactName: "Contact19",
    email: "Contact19@zonedigital.com",
    phone: "72043269",
    classroomSize: 37,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: 532.5,
    comments: null
  },
  {
    venueId: 16,
    region: "East",
    venue1: "Norfolk FA",
    address: "Bowthorpe Park, Clover Hill Road, New Costessey, Norwich",
    postCode: "NR5 9ED",
    locationLon: 1.221983,
    locationLat: 52.64201,
    contactName: "Contact16",
    email: "Contact16@zonedigital.com",
    phone: "28159041",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: null,
    comments: null
  },
  {
    venueId: 62,
    region: "London",
    venue1: "Dunraven School",
    address: "94/98 Leigham Court Road Streatham, London",
    postCode: "SW16 2QB",
    locationLon: -0.121593,
    locationLat: 51.433597,
    contactName: "Contact62",
    email: "Contact62@zonedigital.com",
    phone: "67950265",
    classroomSize: null,
    _3g: false,
    grassPitch: false,
    futsal: true,
    costperday: null,
    comments: null
  },
  {
    venueId: 47,
    region: "South East",
    venue1: "Surrey FA",
    address: "Meadowbank Football Ground, Mill Lane, Dorking",
    postCode: "RH4 1DX",
    locationLon: -0.331673,
    locationLat: 51.233759,
    contactName: "Contact47",
    email: "Contact47@zonedigital.com",
    phone: "88047216",
    classroomSize: 24,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: 287.5,
    comments: null
  },
  {
    venueId: 60,
    region: "London",
    venue1: "New River Sports and Fitness",
    address: "White Hart Lane, London",
    postCode: "N22 5QW",
    locationLon: -0.100032,
    locationLat: 51.604659,
    contactName: "Contact60",
    email: "Contact60@zonedigital.com",
    phone: "32663589",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 550,
    comments: null
  },
  {
    venueId: 61,
    region: "London",
    venue1: "Gunnersbury Park Sports Hub",
    address: "Popes Lane, London W3 8LQ",
    postCode: "W3 8LQ",
    locationLon: -0.286985,
    locationLat: 51.499474,
    contactName: "Contact61",
    email: "Contact61@zonedigital.com",
    phone: "43952249",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 543,
    comments: null
  },
  {
    venueId: 44,
    region: "South ",
    venue1: "Priory School",
    address: "Fawcett Road, Southsea, Hants",
    postCode: "PO4 0DL",
    locationLon: -1.076776,
    locationLat: 50.79501,
    contactName: "Contact44",
    email: "Contact44@zonedigital.com",
    phone: "29533243",
    classroomSize: null,
    _3g: true,
    grassPitch: false,
    futsal: true,
    costperday: 188,
    comments: null
  },
  {
    venueId: 41,
    region: "South ",
    venue1: "Navy FA ",
    address: "Burnaby Rd, Portsmouth",
    postCode: "PO1 2HB",
    locationLon: -1.098859,
    locationLat: 50.794697,
    contactName: "Contact41",
    email: "Contact41@zonedigital.com",
    phone: "39793435",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: null,
    comments: null
  },
  {
    venueId: 22,
    region: "East",
    venue1: "Cambourne Sports Centre",
    address: "Centre, Back Ln, Great Cambourne, Cambourne, Cambridge",
    postCode: "CB23 6FY",
    locationLon: -0.064567,
    locationLat: 52.22036,
    contactName: "Contact22",
    email: "Contact22@zonedigital.com",
    phone: "36867355",
    classroomSize: 24,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: 435.6,
    comments: null
  },
  {
    venueId: 59,
    region: "London",
    venue1: "University of Southampton Sports Ground",
    address: "Wide Lane, Eastleigh",
    postCode: "SO50 5PE",
    locationLon: -1.364333,
    locationLat: 50.955416,
    contactName: "Contact59",
    email: "Contact59@zonedigital.com",
    phone: "39147836",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: true,
    costperday: 595.4,
    comments: null
  },
  {
    venueId: 21,
    region: "East",
    venue1: "University of Bedfordshire",
    address: "Bedford Campus, Polhill Avenue, Bedford",
    postCode: "MK41 9EA",
    locationLon: -0.442323,
    locationLat: 52.141357,
    contactName: "Contact21",
    email: "Contact21@zonedigital.com",
    phone: "46004637",
    classroomSize: 28,
    _3g: false,
    grassPitch: true,
    futsal: true,
    costperday: 346.67,
    comments: null
  },
  {
    venueId: 51,
    region: "South West",
    venue1: "Bradfield College",
    address: "Bradfield, Berkshire",
    postCode: "RG7 6AU",
    locationLon: -1.131851,
    locationLat: 51.448748,
    contactName: "Contact51",
    email: "Contact51@zonedigital.com",
    phone: "67477459",
    classroomSize: 34,
    _3g: false,
    grassPitch: true,
    futsal: true,
    costperday: 450,
    comments: null
  },
  {
    venueId: 20,
    region: "East",
    venue1: "Bedfordshire FA",
    address: "Cranfield Pavilion, Lower Breaches Gardens, Cranfield",
    postCode: "MK43 1AH",
    locationLon: -0.603947,
    locationLat: 52.07808,
    contactName: "Contact20",
    email: "Contact20@zonedigital.com",
    phone: "84315901",
    classroomSize: 37,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 300,
    comments: null
  },
  {
    venueId: 57,
    region: "South West",
    venue1: "Sir Herbet Leon Academy",
    address: "Fern Grove, Bletchley",
    postCode: "MK2 3HQ",
    locationLon: -0.727178,
    locationLat: 51.980264,
    contactName: "Contact57",
    email: "Contact57@zonedigital.com",
    phone: "20869602",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 270,
    comments: null
  },
  {
    venueId: 43,
    region: "South ",
    venue1: "Dorset FA",
    address: "Blandford Close, Poole, Dorset",
    postCode: "BH15 4BF",
    locationLon: -2.002327,
    locationLat: 50.714499,
    contactName: "Contact43",
    email: "Contact43@zonedigital.com",
    phone: "37307192",
    classroomSize: 28,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: 420,
    comments: null
  },
  {
    venueId: 42,
    region: "South ",
    venue1: "Potterne Park",
    address: "Potterne Way, Verwood",
    postCode: "BH21 6RS",
    locationLon: -1.866261,
    locationLat: 50.866469,
    contactName: "Contact42",
    email: "Contact42@zonedigital.com",
    phone: "96001271",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: null,
    comments: null
  },
  {
    venueId: 58,
    region: "South West",
    venue1: "St Edwards School",
    address: "Woodstock Road, Oxford ",
    postCode: "OX2 7NN",
    locationLon: -1.267934,
    locationLat: 51.776993,
    contactName: "Contact58",
    email: "Contact58@zonedigital.com",
    phone: "93605212",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: true,
    costperday: 250,
    comments: null
  },
  {
    venueId: 17,
    region: "East",
    venue1: "Moulton College",
    address: "West Street, Moulton, Northamptonshire",
    postCode: "NN3 7RR",
    locationLon: -0.857756,
    locationLat: 52.289955,
    contactName: "Contact17",
    email: "Contact17@zonedigital.com",
    phone: "56400331",
    classroomSize: 37,
    _3g: true,
    grassPitch: true,
    futsal: true,
    costperday: 576,
    comments: null
  },
  {
    venueId: 35,
    region: "North West",
    venue1: "Wiltshire FA ",
    address: "Green Lane Playing Fields, Green Ln, Devizes ",
    postCode: "SN10 5EP",
    locationLon: -1.983636,
    locationLat: 51.338904,
    contactName: "Contact35",
    email: "Contact35@zonedigital.com",
    phone: "92946371",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 600,
    comments: null
  },
  {
    venueId: 9,
    region: "East Midlands",
    venue1: "Leicester City Women's Training Ground",
    address: "BELVOIR DRIVE, LEICESTER",
    postCode: "LE2 8PA  ",
    locationLon: -1.151668,
    locationLat: 52.602164,
    contactName: "Contact9",
    email: "Contact9@zonedigital.com",
    phone: "42308998",
    classroomSize: 37,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 596.32,
    comments: null
  },
  {
    venueId: 15,
    region: "East Midlands",
    venue1: "Queen Elizabeth II Diamond Jubilee Centre",
    address: "50 Duns Lane, Leicester ",
    postCode: "LE3 5LX",
    locationLon: -1.144253,
    locationLat: 52.633019,
    contactName: "Contact15",
    email: "Contact15@zonedigital.com",
    phone: "80629485",
    classroomSize: null,
    _3g: false,
    grassPitch: false,
    futsal: true,
    costperday: null,
    comments: null
  },
  {
    venueId: 23,
    region: "East",
    venue1: "University of Lincoln",
    address: "TBC",
    postCode: "LN6 7BP",
    locationLon: -0.553674,
    locationLat: 53.228303,
    contactName: "Contact23",
    email: "Contact23@zonedigital.com",
    phone: "24697487",
    classroomSize: null,
    _3g: false,
    grassPitch: false,
    futsal: true,
    costperday: null,
    comments: null
  },
  {
    venueId: 53,
    region: "South West",
    venue1: "Strode College",
    address: "Church Road, Street, Somerset",
    postCode: "BA16 0AB",
    locationLon: -2.734039,
    locationLat: 51.130495,
    contactName: "Contact53",
    email: "Contact53@zonedigital.com",
    phone: "76927230",
    classroomSize: 34,
    _3g: true,
    grassPitch: false,
    futsal: true,
    costperday: 360,
    comments: null
  },
  {
    venueId: 7,
    region: "East Midlands",
    venue1: "Loughborough University",
    address: "Sir John Beckwith Centre for Sport, Loughborough University, University Rd, Loughborough",
    postCode: "LE11 3TU ",
    locationLon: -1.22952,
    locationLat: 52.764828,
    contactName: "Contact7",
    email: "Contact7@zonedigital.com",
    phone: "65241348",
    classroomSize: 37,
    _3g: true,
    grassPitch: true,
    futsal: true,
    costperday: 687,
    comments: null
  },
  {
    venueId: 11,
    region: "East Midlands",
    venue1: "Highfields (Uni of Notts)",
    address: "Highfields Sports Complex, Beeston",
    postCode: "NG7 2PS",
    locationLon: -1.197303,
    locationLat: 52.931986,
    contactName: "Contact11",
    email: "Contact11@zonedigital.com",
    phone: "60936904",
    classroomSize: 37,
    _3g: false,
    grassPitch: true,
    futsal: false,
    costperday: 480,
    comments: null
  },
  {
    venueId: 52,
    region: "South West",
    venue1: "University of Gloucestershire Oxstalls Campus",
    address: "Oxstalls Ln, Longlevens ",
    postCode: "GL2 9HW",
    locationLon: -2.224464,
    locationLat: 51.872481,
    contactName: "Contact52",
    email: "Contact52@zonedigital.com",
    phone: "12644392",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: true,
    costperday: 567.6,
    comments: null
  },
  {
    venueId: 55,
    region: "South West",
    venue1: "Gloucestershire FA",
    address: "Gloucester Road, Almondsbury, Bristol",
    postCode: "BS32 4AG",
    locationLon: -2.571002,
    locationLat: 51.548586,
    contactName: "Contact55",
    email: "Contact55@zonedigital.com",
    phone: "48608912",
    classroomSize: 34,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: null,
    comments: null
  },
  {
    venueId: 14,
    region: "East Midlands",
    venue1: "Gainsborough Trinity FC",
    address: "Roses Sports Ground, North Warren Road, Gainsborough",
    postCode: "DN21 2TU",
    locationLon: -0.786657,
    locationLat: 53.408758,
    contactName: "Contact14",
    email: "Contact14@zonedigital.com",
    phone: "54397823",
    classroomSize: 28,
    _3g: false,
    grassPitch: false,
    futsal: false,
    costperday: null,
    comments: null
  },
  {
    venueId: 12,
    region: "East Midlands",
    venue1: "Frederick Gough School",
    address: "Grange Lane South, Scunthorpe",
    postCode: "DN16 3NG",
    locationLon: -0.641202,
    locationLat: 53.559932,
    contactName: "Contact12",
    email: "Contact12@zonedigital.com",
    phone: "98444232",
    classroomSize: 32,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 294,
    comments: null
  },
  {
    venueId: 4,
    region: "West Midlands",
    venue1: "Tamworth Enterprise College",
    address: "Birds Bush Rd, Tamworth",
    postCode: "B77 2NE",
    locationLon: -1.670292,
    locationLat: 52.615565,
    contactName: "Contact4",
    email: "Contact4@zonedigital.com",
    phone: "22526382",
    classroomSize: 37,
    _3g: false,
    grassPitch: false,
    futsal: false,
    costperday: 440,
    comments: null
  },
  {
    venueId: 54,
    region: "South West",
    venue1: "Devon FA",
    address: "Coach Rd, Newton Abbot",
    postCode: "TQ12 1EJ",
    locationLon: -3.606502,
    locationLat: 50.522468,
    contactName: "Contact54",
    email: "Contact54@zonedigital.com",
    phone: "12980745",
    classroomSize: 34,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: 150,
    comments: null
  },
  {
    venueId: 1,
    region: "West Midlands",
    venue1: "Birmingham University Sport & Fitness ",
    address: "University of, Bristol Rd, Birmingham",
    postCode: "B15 2TT",
    locationLon: -1.92789,
    locationLat: 52.453282,
    contactName: "Contact1",
    email: "Contacttest@zonedigital.com",
    phone: "76108167",
    classroomSize: 35,
    _3g: true,
    grassPitch: false,
    futsal: true,
    costperday: 380.8,
    comments: null
  },
  {
    venueId: 63,
    region: "West Midlands",
    venue1: "Birmingham University Sport & Fitness update",
    address: "University of, Bristol Rd, Birmingham",
    postCode: "B15 2TT",
    locationLon: -1.92789,
    locationLat: 52.453282,
    contactName: "Contact1",
    email: "Contact1@zonedigital.com",
    phone: "76108167",
    classroomSize: 37,
    _3g: true,
    grassPitch: false,
    futsal: true,
    costperday: 380.8,
    comments: null
  },
  {
    venueId: 64,
    region: "West Midlands",
    venue1: "Birmingham University Sport & Fitness update",
    address: "University of, Bristol Rd, Birmingham",
    postCode: "B15 2TT",
    locationLon: -1.92789,
    locationLat: 52.453282,
    contactName: "Contact1",
    email: "Contact1@zonedigital.com",
    phone: "76108167",
    classroomSize: 37,
    _3g: true,
    grassPitch: false,
    futsal: true,
    costperday: 380.8,
    comments: null
  },
  {
    venueId: 10,
    region: "East Midlands",
    venue1: "Mansfield Town Academy",
    address: "Mansfield RH Academy, Woburn Ln, Pleasley, Mansfield",
    postCode: "NG19 7RT",
    locationLon: -1.238123,
    locationLat: 53.170047,
    contactName: "Contact10",
    email: "Contact10@zonedigital.com",
    phone: "18554886",
    classroomSize: 37,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 250,
    comments: null
  },
  {
    venueId: 6,
    region: "West Midlands",
    venue1: "Worcestershire County Sports Ground",
    address: "Claines Ln, Worcester ",
    postCode: "WR3 7SS",
    locationLon: -2.212325,
    locationLat: 52.223533,
    contactName: "Contact6",
    email: "Contact6@zonedigital.com",
    phone: "11895456",
    classroomSize: 37,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 490,
    comments: null
  },
  {
    venueId: 5,
    region: "West Midlands",
    venue1: "Birmingham CFA",
    address: "Ray Hall Lane, Great Barr, Birmingham",
    postCode: "B43 6JF",
    locationLon: -1.961782,
    locationLat: 52.542679,
    contactName: "Contact5",
    email: "Contact5@zonedigital.com",
    phone: "53630723",
    classroomSize: 37,
    _3g: false,
    grassPitch: true,
    futsal: false,
    costperday: 400,
    comments: null
  },
  {
    venueId: 8,
    region: "East Midlands",
    venue1: "St George's Park",
    address: "Newborough Rd, Needwood, Burton-on-Trent",
    postCode: "DE13 9PD",
    locationLon: -1.760102,
    locationLat: 52.817733,
    contactName: "Contact8",
    email: "Contact8@zonedigital.com",
    phone: "36886835",
    classroomSize: 37,
    _3g: true,
    grassPitch: true,
    futsal: true,
    costperday: 420,
    comments: null
  },
  {
    venueId: 3,
    region: "West Midlands",
    venue1: "Wolverhampton College",
    address: "Wellington Road Campus, Bilston, Wolverhampton ",
    postCode: "WV14 6BT",
    locationLon: -2.091896,
    locationLat: 52.572549,
    contactName: "Contact3",
    email: "Contact3@zonedigital.com",
    phone: "18897335",
    classroomSize: 37,
    _3g: true,
    grassPitch: true,
    futsal: true,
    costperday: 125,
    comments: null
  },
  {
    venueId: 56,
    region: "South West",
    venue1: "Plymouth Marjon University",
    address: "Plymbridge Lane, Plymouth ",
    postCode: "PL6 8BH",
    locationLon: -4.110758,
    locationLat: 50.420866,
    contactName: "Contact56",
    email: "Contact56@zonedigital.com",
    phone: "73854534",
    classroomSize: 32,
    _3g: true,
    grassPitch: true,
    futsal: true,
    costperday: 400,
    comments: null
  },
  {
    venueId: 13,
    region: "East Midlands",
    venue1: "SGP Thorncliffe",
    address: "Pack Horse Lane, High Green, Sheffield",
    postCode: "S35 3HY",
    locationLon: -1.48341,
    locationLat: 53.475219,
    contactName: "Contact13",
    email: "Contact13@zonedigital.com",
    phone: "50038221",
    classroomSize: 37,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 313,
    comments: null
  },
  {
    venueId: 27,
    region: "North East",
    venue1: "Barnsley FC ",
    address: "Oakwell Stadium, Grove St, Barnsley",
    postCode: "S71 1ET",
    locationLon: -1.468631,
    locationLat: 53.552266,
    contactName: "Contact27",
    email: "Contact27@zonedigital.com",
    phone: "64131470",
    classroomSize: 20,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: 560,
    comments: "&Sheet1!M3&"
  },
  {
    venueId: 26,
    region: "North East",
    venue1: "York Stadium - LNER",
    address: "Kathryn Avenue, Monks Cross, York ",
    postCode: " YO32 9AF",
    locationLon: -1.052638,
    locationLat: 53.983465,
    contactName: "Contact26",
    email: "Contact26@zonedigital.com",
    phone: "26889376",
    classroomSize: 34,
    _3g: false,
    grassPitch: true,
    futsal: false,
    costperday: 500,
    comments: null
  },
  {
    venueId: 2,
    region: "West Midlands",
    venue1: "Lillehall National Sports Centre",
    address: "Near Newport",
    postCode: "TF10 9AT",
    locationLon: -2.37207,
    locationLat: 52.72799,
    contactName: "Contact2",
    email: "Contact2@zonedigital.com",
    phone: "6926680",
    classroomSize: 37,
    _3g: false,
    grassPitch: true,
    futsal: false,
    costperday: 461,
    comments: null
  },
  {
    venueId: 30,
    region: "North East",
    venue1: "John Charles Centre for Sport",
    address: "Middleton Grove, Belle Isle, Leeds",
    postCode: "LS11 5DJ",
    locationLon: -1.541945,
    locationLat: 53.764305,
    contactName: "Contact30",
    email: "Contact30@zonedigital.com",
    phone: "824640",
    classroomSize: null,
    _3g: false,
    grassPitch: false,
    futsal: false,
    costperday: null,
    comments: null
  },
  {
    venueId: 25,
    region: "North East",
    venue1: "Leeds Beckett University",
    address: "City Campus, Leeds",
    postCode: "LS1 3HE",
    locationLon: -1.548578,
    locationLat: 53.80402,
    contactName: "Contact25",
    email: "Contact25@zonedigital.com",
    phone: "67533804",
    classroomSize: 34,
    _3g: true,
    grassPitch: false,
    futsal: true,
    costperday: 484,
    comments: null
  },
  {
    venueId: 36,
    region: "North West",
    venue1: "FC United of Manchester",
    address: "Broadhurst Park, 310 Lightbowne Road, Moston, Manchester",
    postCode: "M40 0FJ",
    locationLon: -2.181159,
    locationLat: 53.516384,
    contactName: "Contact36",
    email: "Contact36@zonedigital.com",
    phone: "2243802",
    classroomSize: 34,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: 334.4,
    comments: null
  },
  {
    venueId: 28,
    region: "North East",
    venue1: "Redcar & Cleveland College",
    address: "Corporation Rd, Redcar",
    postCode: "TS10 1EZ",
    locationLon: -1.075195,
    locationLat: 54.614542,
    contactName: "Contact28",
    email: "Contact28@zonedigital.com",
    phone: "24239888",
    classroomSize: 28,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 230,
    comments: null
  },
  {
    venueId: 34,
    region: "North West",
    venue1: "Cheshire FA",
    address: "Cheshire FA, Northwich",
    postCode: "CW8 4BG",
    locationLon: -2.531428,
    locationLat: 53.256707,
    contactName: "Contact34",
    email: "Contact34@zonedigital.com",
    phone: "2212638",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 565,
    comments: null
  },
  {
    venueId: 37,
    region: "North West",
    venue1: "Hope Sports Park",
    address: "Liverpool Hope University, Hope Park, Liverpool",
    postCode: "L16 9JD",
    locationLon: -2.891907,
    locationLat: 53.391527,
    contactName: "Contact37",
    email: "Contact37@zonedigital.com",
    phone: "15805522",
    classroomSize: null,
    _3g: false,
    grassPitch: false,
    futsal: false,
    costperday: null,
    comments: null
  },
  {
    venueId: 31,
    region: "North East",
    venue1: "Beacon of Light",
    address: "Stadium Way, Monkwearmouth, Sunderland",
    postCode: "SR5 1SN",
    locationLon: -1.389229,
    locationLat: 54.915491,
    contactName: "Contact31",
    email: "Contact31@zonedigital.com",
    phone: "99554876",
    classroomSize: null,
    _3g: true,
    grassPitch: false,
    futsal: true,
    costperday: null,
    comments: null
  },
  {
    venueId: 24,
    region: "North East",
    venue1: "Durham University ",
    address: "Graham Sports Centre, Maiden Castle, Stockton Road, Durham",
    postCode: "DH1 3SE",
    locationLon: -1.558689,
    locationLat: 54.767121,
    contactName: "Contact24",
    email: "Contact24@zonedigital.com",
    phone: "35301400",
    classroomSize: 34,
    _3g: false,
    grassPitch: true,
    futsal: true,
    costperday: 570,
    comments: null
  },
  {
    venueId: 29,
    region: "North East",
    venue1: "Ford Quarry Hub",
    address: "Keelmans Lane, South Hylton, Sunderland",
    postCode: "SR4 0RW",
    locationLon: -1.437029,
    locationLat: 54.908632,
    contactName: "Contact29",
    email: "Contact29@zonedigital.com",
    phone: "5631950",
    classroomSize: null,
    _3g: false,
    grassPitch: false,
    futsal: false,
    costperday: null,
    comments: null
  },
  {
    venueId: 33,
    region: "North West",
    venue1: "Lancashire FA",
    address: "County Ground, Thurston Road, Leyland",
    postCode: "PR25 2LF",
    locationLon: -2.693803,
    locationLat: 53.693159,
    contactName: "Contact33",
    email: "Contact33@zonedigital.com",
    phone: "36549454",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: false,
    costperday: 250,
    comments: null
  },
  {
    venueId: 38,
    region: "North West",
    venue1: "LCFA Sefton",
    address: "Drummond Road, Thornton, Liverpool",
    postCode: "L23 9YP",
    locationLon: -2.998916,
    locationLat: 53.493667,
    contactName: "Contact38",
    email: "Contact38@zonedigital.com",
    phone: "92186740",
    classroomSize: 34,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: 400,
    comments: null
  },
  {
    venueId: 32,
    region: "North East",
    venue1: "Northumbria University- Sport Central",
    address: "Northumberland Rd, Newcastle upon Tyne",
    postCode: "NE1 8ST",
    locationLon: -1.60745,
    locationLat: 54.976634,
    contactName: "Contact32",
    email: "Contact32@zonedigital.com",
    phone: "19668330",
    classroomSize: 34,
    _3g: true,
    grassPitch: true,
    futsal: true,
    costperday: 680,
    comments: null
  },
  {
    venueId: 39,
    region: "North West",
    venue1: "The Grizedale Hub",
    address: "Newton Rigg, Penrith, Cumbria",
    postCode: "CA11 0AH",
    locationLon: -2.788515,
    locationLat: 54.67166,
    contactName: "Contact39",
    email: "Contact39@zonedigital.com",
    phone: "72039337",
    classroomSize: null,
    _3g: true,
    grassPitch: false,
    futsal: false,
    costperday: null,
    comments: null
  }
];


