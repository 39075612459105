/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';

import HiddenContent from '../HiddenContent';
import { Magnifier } from '../Icons';
import useStyles from './styles';

const LocationAutoSuggest = ({
  placeholder,
  onError,
  setLocationData,
  ...rest
}) => {
  const styles = useStyles();

  function loadMapScenario() {
    window.Microsoft.Maps.loadModule('Microsoft.Maps.AutoSuggest', {
      callback: onLoad,
      errorCallback: onError,
    });

    function onLoad() {
      var options = { maxResults: 5 };
      var manager = new window.Microsoft.Maps.AutosuggestManager(options);

      manager.attachAutosuggest(
        '#searchBox',
        '#searchBoxContainer',
        selectedSuggestion
      );
    }

    function selectedSuggestion(suggestionResult) {
      const { location, title, subtitle } = suggestionResult;

      setLocationData({
        latitude: location?.latitude,
        longitude: location?.longitude,
        readableLocation: title || subtitle,
        secondaryLocation: subtitle,
      });
    }
  }

  useEffect(() => {
    if (!window?.Microsoft?.Maps) {
      window.loadMapScenario = loadMapScenario;
    } else {
      loadMapScenario();
    }

    return () => {
      window.loadMapScenario = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={styles.searchBoxContainer} id="searchBoxContainer">
      <div css={styles.inputWrapper} {...rest}>
        <Magnifier />
        <input
          type="text"
          id="searchBox"
          autoComplete="off"
          css={styles.input}
          placeholder={placeholder}
          aria-describedby="postcode-description"
        />
        <HiddenContent id="postcode-description">
          Use the down arrow to select one of the results
        </HiddenContent>
      </div>
    </div>
  );
};

export default LocationAutoSuggest;
