/** @jsxImportSource @emotion/react */

import Button from '../Button';
import Footer from '../Footer';
import Text from '../Text';
import useStyles from './styles';

const Page500 = ({ resetError }) => {
  const styles = useStyles();

  return (
    <main css={styles.base}>
      <div css={styles.pageWrapper('page-500-bg@2x')}>
        <div css={styles.textWrapper}>
          <Text as="h1" size={3} css={styles.mainText}>
            Something's gone wrong there
          </Text>
          <Text css={styles.subText}>
            Sorry, looks like we ran into a technical problem. Hit the button
            below and we'll get you back to the Find Football homepage.
          </Text>
          <div css={styles.bottom}>
            <Button.Link isExternal css={styles.cta} to="/">
              Find football homepage
            </Button.Link>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default Page500;
