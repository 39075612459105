/** @jsxImportSource @emotion/react */
import { useState, useReducer, useEffect } from 'react';
import React, { useLayoutEffect, useRef } from 'react';
import useStyles from './styles';
import { getVenueIcon, getVenueSelectedIcon, getTrainerIcon, getTrainerSelectedIcon } from '../../hooks/useMapPins';
import Map from '../Map';
import MapService from '../../utils/MapService';
import { getCourseVenueList } from '../../services';
import trayStateReducer, {
  EXPAND_ACTION,
  EXPAND_MAP_ACTION,
  SHRINK_ACTION,
  STANDARD,
} from '../../reducers/trayState';


import LeftFilter from '../LeftFilter';

const CourseVenueMap = ({
  setSelectedTrainer,
  trainerIds,
  selectedTrainer,
  selectedVenue,
  setSelectedVenue,
  setSelectedVenueDetails,
  trainerList,
  hideFilter,
}) => {
  const styles = useStyles();
  const [selectedId, setSelectedId] = useState();
  const [viewMap, setViewMap] = useState(false);
  const [trainers, setTrainers] = useState(false);
  const [pushPins, setPushPins] = useState();
  const [selectVenuePin, setSelectVenuePin] = useState();
  const [trainerPins, setTrainerPins] = useState();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const idref = useRef();
  const [trayState, dispatch] = useReducer(trayStateReducer, STANDARD);
  const [filterOpen, setFilterOpen] = useState(false);
  let pushedTrainer = useRef();

  const [selectedFilter, setSelectedFilter] = useState([]);

  useEffect(() => {
    getCourseVenueList()
      .then(({ data }) => {
        const courseVenueList = data;
        if (!map.current) {
          setViewMap(courseVenueList);
        }
      })
      .catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(selectedVenue);
    console.log(selectedId);
    console.log(trainerIds);
    console.log(selectedTrainer);
    if (selectedVenue && !selectedId) {
      console.log('edit');
      setTimeout(() => {
        pushedTrainer.current = selectedTrainer;
        setTrainers(true);
        setSelectedId(selectedVenue);
      }, 500);
    }
  }, []);

  const deg2rad = deg => {
    return deg * (Math.PI / 180);
  };

  const getDistanceFromLatLon = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    let dLat = deg2rad(lat2 - lat1); // deg2rad below
    let dLon = deg2rad(lon2 - lon1);
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c; // Distance in km
    d = d * 0.621371; // Distance in Miles
    return d;
  };

  const handleTrainerSelect = (e, trainerId, mapdata) => {
    // console.log(trainerId);
    console.log(pushedTrainer);
    // console.log(trainerIds.includes(trainerId));

    map.current.infobox.setOptions({
      visible: false,
    });

    if (pushedTrainer.current.includes(trainerId)) {
      // const removeId = trainerIds.filter(val => val !== trainerId);
      // trainerIds = removeId;
      setSelectedTrainer(selectedTrainer =>
        selectedTrainer.filter(val => val !== trainerId),
      );

      // setSelectedTrainer(removeId);
      e.target?.setOptions({ icon: getTrainerIcon(mapdata.discipline) });
    } else {
      // trainerIds.push(trainerId);
      setSelectedTrainer(selectedTrainer => [...selectedTrainer,trainerId] );
      // setSelectedTrainer(trainerIds);
      e.target?.setOptions({
        icon: getTrainerSelectedIcon(mapdata.discipline),
      });

      showTrainerInfoBox(e.target, mapdata);

      // const pinIndex = map.current.pushpinObjects.findIndex(pin => pin.id === `trainer_${trainerId}`);
      // console.log(pinIndex);
      // const pinToUpdate = map.current.plottedPins[pinIndex];

      // console.log(pinToUpdate);
      // const entityToUpdate = map.current.map.entities.indexOf(pinToUpdate);

      // console.log(entityToUpdate);
      // map.current.map.entities.get(entityToUpdate)?.setOptions({ icon: userIcon });
      //setSelectedTrainer(selectedTrainer.concat(trainerId))
    }
  };
  useEffect(() => {
    pushedTrainer.current = selectedTrainer;
  }, [selectedTrainer]);

  const getTrainerDetails = () => {
    const pushPins = [];
    let inc = 0;

    viewMap
      .filter(v => v.locationLon !== null && v.locationLat !== null)
      .forEach(mapdata => {
        const id = mapdata.venueId;
        pushPins[inc] = {
          id,
          longitude: mapdata.locationLon,
          latitude: mapdata.locationLat,
          capacity: mapdata.classroomSize,
          handleClick: e => handlePinSelect(e, id, mapdata),
          options: {
            icon: getVenueIcon(mapdata.classroomSize),
          },
        };
        inc += 1;
      });

    trainerList
      .filter(v => v.locationLon !== null && v.locationLat !== null)
      .forEach(mapdata => {
        const id = `trainer_${mapdata.trainerId}`;
        pushPins[inc] = {
          id,
          longitude: mapdata.locationLon,
          latitude: mapdata.locationLat,
          options: {
            icon: (selectedTrainer.includes(mapdata.trainerId)) ? getTrainerSelectedIcon(mapdata.discipline) : getTrainerIcon(mapdata.discipline) ,
          },
          handleClick: e => handleTrainerSelect(e, mapdata.trainerId, mapdata),
        };
        inc += 1;
      });
    if (pushPins.length) {
      setTrainerPins(pushPins);
      map.current.addPins(pushPins);
    }
  };

  const showInfoBox = (id, pinData) => {
    const description = `
      <div class="tooltipWrapper">
        <div class="tooltipTitle">${pinData.venue1}</div>
        <div class="tooltipAddress">
            <div class="addressWrapper">
                <div class="addressTitle">Address</div>
                <div class="addressDetails">
                    <p>${pinData.address}</p>
                    <p>${pinData.postCode}</p>
                </div>
            </div>
            <div class="addressWrapper">
                <div class="addressTitle">Contact</div>
                <div class="addressDetails bluetext">
                    <p>${pinData.phone}</p>
                    <p>${pinData.email}</p>
                </div>
            </div>
        </div>
        <div class="facilitiesWrapper">
            <div class="addressTitle">Facilities</div>
            <div class="facilitiesDetails">
                <p class="type">Full size, futsal, 3G </p>
                <p class="treatment">Treatment facilities</p>
                <p class="facility">Classroom with whiteboard</p>
            </div>
        </div>
      </div>
    `;
    //setSelectVenuePin(id);
    map.current.infobox.setOptions({
      title: pinData.venue1,
      location: id.getLocation(),
      description: description,
      visible: true,
    });
  };

  const showTrainerInfoBox = (id, pinData) => {
    const description = `
      <div class="tooltipWrapper">
        <div class="tooltipTitle">${pinData.fullName}</div>
        <div class="tooltipAddress">
            <div class="addressWrapper">
                <div class="addressTitle">Address</div>
                <div class="addressDetails">
                    <p>${pinData.address1}, ${pinData.address2}, ${pinData.address3}</p>
                    <p>${pinData.postCode}</p>
                </div>
            </div>
            <div class="addressWrapper">
                <div class="addressTitle">Contact</div>
                <div class="addressDetails bluetext">
                    <p>${pinData.contactNumber}</p>
                    <p>${pinData.email}</p>
                </div>
            </div>
        </div>
        <div class="actionCta">
          <button class="createCta"><span>${pinData.discipline} &nbsp; ${pinData.role}</span></button>
        </div>
      </div>
    `;
    //setSelectVenuePin(id);
    map.current.infobox.setOptions({
      title: pinData.venue1,
      location: id.getLocation(),
      description: description,
      visible: true,
    });
  };

  const handlePinSelect = (e, id, pinData) => {
    if (hideFilter) {
      showInfoBox(e.target, pinData);
      setSelectedVenue(id);
      setSelectedVenueDetails(pinData);

      setTrainers(true);

      setTimeout(() => {
        setSelectedId(id);
      }, 300);
    } else {
      showInfoBox(e.target, pinData);
      // setSelectedId(id);
    }
  };

  useLayoutEffect(() => {
    if (trainers) {
      getTrainerDetails();
    }
  }, [trainers]);

  const checkVenueFilterValue = data => {
    if (selectedFilter.length > 0) {
      const filterCheck = selectedFilter.filter(fil => {
        return (fil.option === 'low' && data.classroomSize != null && parseInt(data.classroomSize) < 20) ||
          (fil.option === 'good' && data.classroomSize != null && parseInt(data.classroomSize) >= 20) ||
          (fil.option === 'empty' && (data.classroomSize == 0 || data.classroomSize == null))
          ? true
          : false;
      });
      return filterCheck.length;
    }
    return true;
  };

  const generateMapPins = (viewMap) => {
    const pushPins = viewMap
        .filter(
          v =>
            v.locationLon !== null &&
            v.locationLat !== null &&
            checkVenueFilterValue(v)
            // getDistanceFromLatLon(
            //   userLocation.latitude,
            //   userLocation.longitude,
            //   v.locationLat,
            //   v.locationLon
            // ) < 40
        )
        .map((mapdata, index) => {
          const id = mapdata.venueId;
          return {
            id,
            longitude: mapdata.locationLon,
            latitude: mapdata.locationLat,
            capacity: mapdata.classroomSize,
            handleClick: e => handlePinSelect(e, id, mapdata),
            options: {
              icon: getVenueIcon(mapdata.classroomSize),
            },
          };
        });

      return pushPins;
  };

  useEffect(() => {
    console.log(selectedFilter);
    if (selectedFilter.length > 0) {
      const updatedPins = generateMapPins(viewMap);

      // removed existing  pins and regenerate it
      map.current.removePins();
      map.current.addPins(updatedPins);
    }
  }, [selectedFilter]);

  useLayoutEffect(() => {
    const currentMap = map.current;

    if (mapContainer.current !== null && viewMap) {
      const userLocation = {
        latitude: 51.5567,
        longitude: -0.2797,
      };

      const pushPins = generateMapPins(viewMap);

      map.current = new MapService(
        {
          container: mapContainer.current,
          center: userLocation,
        },
        pushPins,
        getVenueIcon,
        getVenueSelectedIcon,
        hideFilter,
      );
    }

    return () => {
      currentMap?.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMap]);

  useLayoutEffect(() => {
    if (!map.current) {
      return;
    }

    map.current.setSelected(selectedId);
  }, [selectedId]);


  const filterOptions = {
    filters: [
      {
        title: 'VENUES',
        filterList: {
          options: [
            {
              id: 1,
              optionVal: 'all',
              optionLabel: 'ALL VENUES',
              icon: '',
            },
            {
              id: 2,
              optionVal: 'good',
              optionLabel: 'GOOD CAPACITY',
              icon: '/images/common/filter/good.svg',
            },
            {
              id: 3,
              optionVal: 'low',
              optionLabel: 'LOW CAPACITY',
              icon: '/images/common/filter/low.svg',
            },
            {
              id: 4,
              optionVal: 'empty',
              optionLabel: 'EMPTY',
              icon: '/images/common/filter/empty.svg',
            }
          ]
        }
      },
    ]
  };

  return (
    <div css={styles.venueMap}>
      <div css={styles.venueMapWrapper}>
        <div
          className="mapContainer"
          ref={mapContainer}
          style={{ width: '100%' }}
        ></div>
      </div>
      {!hideFilter && (
        <div css={styles.filterPopup}>
          <LeftFilter setSelectedFilter={setSelectedFilter} filterOptions={filterOptions} setFilterOpen={setFilterOpen}  filterOpen={filterOpen} />
        </div>
      )}
    </div>
  );
};

export default CourseVenueMap;
