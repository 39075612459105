/** @jsxImportSource @emotion/react */

import useStyles from './styles';
import useCourseVenueAPI from '../../hooks/useCourseVenueAPI';
import Footer from '../Footer';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Fragment, useEffect, useRef, useState } from 'react';
import {
  updateCourseVenueData,
  getCourseList,
  courseInstanceApi,
  trainersCourseInstanceApi,
  getCourseTrainerList,
  getCourseInstanceApi,
  getCourseVenueData,
  getTrainersCourseInstanceApi,
  updateCourseInstanceApi,
  deleteTrainersCourseInstanceApi,
} from '../../services';
import LocationAutoSuggest from '../LocationAutoSuggest';
import CustomSelectDropDown from '../CustomSelectDropDown';
import CourseVenueMap from '../CourseVenueMap';
import Calendar from 'react-calendar';

import 'react-calendar/dist/Calendar.css';
import FilterOptions from '../FilterOptions';

export const venueFormOptions = {
  // courseType: { required: 'courseType is required' },
  startdate: { required: 'startDate is required' },
  places: { required: 'places is required' },
  coaches: { required: 'coaches is required' },
  duration: { required: 'duration is required' },
};

const CourseManagementNew = ({ handleSelect, selectedId }) => {
  const styles = useStyles();
  const history = useHistory();
  const { courseInstanceId, venueId, redirectPage } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    resetField,
    setFocus,
    getValues,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });
  const [locationData, setLocationData] = useState();
  const [datevalue, setDatevalue] = useState('');
  const [calendarValue, setCalendarValue] = useState('');
  const [showCalendar, setShowCalendar] = useState(false);
  const [courseTypeData, setCourseTypeData] = useState([]);
  const [courseTypeDefault, setCourseTypeDefault] = useState(0);
  const [existingcourse, setExistingcourse] = useState(false);
  const [existingcourseType, setExistingcourseType] = useState([]);
  const [showVenueMap, setShowVenueMap] = useState(true);
  const [selectedVenue, setSelectedVenue] = useState();
  const [selectedVenueDetails, setSelectedVenueDetails] = useState();
  const [trainerList, setTrainerList] = useState([]);
  const [trainerListFiltered, setTrainerListFiltered] = useState([]);
  const [selectedTrainer, setSelectedTrainer] = useState([]);
  const [existingTrainer, setExistingTrainer] = useState([]);
  const [nextStepCta, setNextStepCta] = useState(false);
  const [submitCta, setSubmitCta] = useState(false);
  const [courseTypeVal, setCourseTypeVal] = useState('');
  let trainerIds = [];
  const [inputValues, setInputValues] = useState({
    places: '',
    coaches: '',
    duration: '',
  });
  const [hideFilter, setHideFilter] = useState(true);
  const [selectedOption, setSelectedOption] = useState([]);
  let filterSelected = [];

  useEffect(() => {
    const todayDt = new Date();
    const year = todayDt.getFullYear();
    let mon = todayDt.getMonth() + 1; // Months start at 0!
    let dt = todayDt.getDate();

    if (dt < 10) dt = `0${dt}`;
    if (mon < 10) mon = `0${mon}`;

    const currentDate = `${dt}/${mon}/${year}`;

    setDatevalue(currentDate);
    setCalendarValue(`${mon}/${dt}/${year}`);

    // console.log(currentDate);
  }, []);

  useEffect(() => {
    getCourseTrainerList()
      .then(({ data }) => {
        setTrainerList(data);
        setTrainerListFiltered(data);
      })
      .catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("selectedOptions - ", selectedOption);
    if (selectedOption.length === 0 ||
      selectedOption.some(option => option.includes("all"))) {
      setTrainerListFiltered(trainerList);
    }
    else {
      const filteredList = trainerList.filter(trainer =>
        selectedOption.includes(trainer?.discipline?.toLowerCase()));

      setTrainerListFiltered(filteredList)
    }
  }, [selectedOption]);


  const populateExistingData = (courseTypeData) => {
    if (courseInstanceId) {
      getCourseInstanceApi(courseInstanceId)
        .then(({ data }) => {
          // console.log(data);
          setExistingcourse(true);
          setExistingcourseType(data);

          const startDt = new Date(data?.startDate);
          const year = startDt.getFullYear();
          let mon = startDt.getMonth() + 1; // Months start at 0!
          let dt = startDt.getDate();

          if (dt < 10) dt = `0${dt}`;
          if (mon < 10) mon = `0${mon}`;

          const currentDate = `${dt}/${mon}/${year}`;


          setDatevalue(currentDate);
          setCalendarValue(`${mon}/${dt}/${year}`);

          // console.log('11111');
          setCourseTypeVal(data.courseId);
          let courseTypeIndex = courseTypeData.findIndex(course => course.id == data.courseId);
          courseTypeIndex = courseTypeIndex > 0 ? courseTypeIndex : 0;

          //setTimeout(() => {
          setCourseTypeDefault(courseTypeIndex);
          //}, 300);


          //console.log(courseTypeData);
          // console.log(courseTypeIndex);
          // console.log(courseTypeData.map(e => e.id).indexOf(data.courseId));

          setValue('startdate', currentDate);
          // setValue('places', data?.plannedAttendance);
          // setValue('coaches', data?.noTrainers);
          // setValue('duration', data?.noRunDays);

          setInputValues({
            places: data?.plannedAttendance,
            coaches: data?.noTrainers,
            duration: data?.noRunDays,
          });

          setSelectedVenue(data.venueId);

        })
        .catch(() => { });

      getCourseVenueData(venueId)
        .then(({ data }) => {
          console.log(data);
          setSelectedVenueDetails(data);
        })
        .catch(() => { });


      getTrainersCourseInstanceApi(courseInstanceId)
        .then(({ data }) => {
          console.log(data);
          const trainerData = data.map(v => (v.trainerId));
          setSelectedTrainer(trainerData);
          setExistingTrainer(trainerData);
        })
        .catch(() => { });

      setSubmitCta(true);
    }
  };

  useEffect(() => {
    getCourseList()
      .then(({ data }) => {
        if (data) {
          const courseType = data.map(course => {
            return {
              id: course.courseId,
              option: course.name,
              minAttendance: course.minAttendance,
              minTrainers: course.minTrainers,
              minRunDays: course.minRunDays,
            };
          });

          setCourseTypeData(courseType);

          if (courseInstanceId) {
            populateExistingData(courseType);
          }
        }
      })
      .catch(() => { });
  }, []);

  const onClickCancelHandler = () => {
    history.push(`/`);
  };

  const onClickEditHandler = () => {
    setValue('startdate', datevalue);
    setInputValues({
      places: getValues('places'),
      coaches: getValues('coaches'),
      duration: getValues('duration'),
    });
    setShowVenueMap(true);
  };

  const onSubmit = handleSubmit(data => {
    console.log(data);
    if (courseInstanceId) {
      updateCourseInstance(data, courseInstanceId);
    } else {
      submitCourseInstance(data);
    }
  });

  const selectQueryOption = value => {
    if (courseTypeData && courseTypeData[value] && courseTypeData[value].id) {
      setCourseTypeVal(courseTypeData[value].id);
      setCourseTypeDefault(value);
      setValue('places', inputValues['places'] ? inputValues['places'] : courseTypeData[value]?.minAttendance);
      setValue('coaches', inputValues['coaches'] ? inputValues['coaches'] : courseTypeData[value]?.minTrainers);
      setValue('duration', inputValues['duration'] ? inputValues['duration'] : courseTypeData[value]?.minRunDays);

      // console.log(inputValues);
      // console.log(inputValues['coaches']);
      // console.log(inputValues['duration']);
    }
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleCalendarChange = dateval => {
    //setDatevalue(dateval);
    setShowCalendar(!showCalendar);
    const today = new Date(dateval);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    const formattedToday = `${dd}/${mm}/${yyyy}`;
    // console.log(formattedToday);
    setDatevalue(formattedToday);
    setCalendarValue(`${mm}/${dd}/${yyyy}`);
  };

  const nextVenueUpdate = () => {
    setValue('startdate', datevalue);

    setInputValues({
      places: getValues('places'),
      coaches: getValues('coaches'),
      duration: getValues('duration'),
    });

    // console.log(getValues('places'));
    // console.log(getValues('coaches'));
    // console.log(getValues('duration'));
    setShowVenueMap(true);
  };

  const nextTrainerUpdate = () => {
    console.log(selectedVenueDetails);
    console.log(selectedTrainer);
    console.log(trainerList);
    setShowVenueMap(false);
    setSubmitCta(true);
    console.log(courseTypeVal);
    console.log(calendarValue);
    console.log(datevalue);
  };

  const updateTrainersCourseInstance = (trainerid, instanceId) => {
    const trainerDetail = {
      trainersCourseInstanceId: 0,
      trainerId: trainerid,
      courseInstanceId: parseInt(instanceId),
    };
    console.log(trainerDetail);
    trainersCourseInstanceApi(trainerDetail)
      .then(({ data }) => {
        console.log(data);
      })
      .catch(() => { });
  };

  const deleteTrainersCourseInstance = (trainerid, instanceId) => {
    const trainerDetail = {
      trainersCourseInstanceId: 0,
      trainerId: trainerid,
      courseInstanceId: parseInt(instanceId),
    };
    console.log(trainerDetail);
    deleteTrainersCourseInstanceApi(trainerDetail)
      .then(({ data }) => {
        console.log(data);
      })
      .catch(() => { });
  };

  const updateCourseInstance = (data, courseInstanceId) => {
    const dtVal = data.startdate.split('/');
    const formattedDate = `${dtVal[2]}-${dtVal[1]}-${dtVal[0]}`;
    const dt = new Date(`${dtVal[1]}/${dtVal[0]}/${dtVal[2]}`);
    const end = dt.setDate(dt.getDate() + parseInt(data.duration));
    const endDate = new Date(end);
    const yyyy = endDate.getFullYear();
    let mm = endDate.getMonth() + 1; // Months start at 0!
    let dd = endDate.getDate();
    const todayDt = new Date();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    const endDateFormat = `${yyyy}-${mm}-${dd}`;
    const courseInstanceApiData = {
      courseInstanceId: courseInstanceId,
      courseId: courseTypeVal,
      venueId: selectedVenue,
      plannedAttendance: data.places,
      noTrainers: data.coaches,
      noRunDays: data.duration,
      season: todayDt.getFullYear(),
      startDate: formattedDate,
      endDate: endDateFormat,
      resourced: 1,
      finalised: 0,
    };

    // console.log(courseInstanceApiData);
    // console.log(selectedTrainer);
    // console.log(existingTrainer);

    updateCourseInstanceApi(courseInstanceApiData, courseInstanceId)
      .then(({ data }) => {
        console.log(data);
      })
      .catch(() => { });

    const removeTrainer = existingTrainer.filter(n => {
      return selectedTrainer.indexOf(n) === -1;
    });

    const newTrainer = selectedTrainer.filter(n => {
      return existingTrainer.indexOf(n) === -1;
    });

    // console.log(removeTrainer);

    // console.log(newTrainer);

    newTrainer.forEach(id => {
      updateTrainersCourseInstance(id, courseInstanceId);
    });

    removeTrainer.forEach(id => {
      deleteTrainersCourseInstance(id, courseInstanceId);
    });

    setTimeout(() => {
      if (redirectPage === 'map') {
        history.push(`/courses/map`);
      } else {
        history.push(`/`);
      }
    }, 300);
  };

  const submitCourseInstance = data => {
    const dtVal = data.startdate.split('/');
    const formattedDate = `${dtVal[2]}-${dtVal[1]}-${dtVal[0]}`;
    const dt = new Date(`${dtVal[1]}/${dtVal[0]}/${dtVal[2]}`);
    const end = dt.setDate(dt.getDate() + parseInt(data.duration));
    const endDate = new Date(end);
    const yyyy = endDate.getFullYear();
    let mm = endDate.getMonth() + 1; // Months start at 0!
    let dd = endDate.getDate();
    const todayDt = new Date();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    const endDateFormat = `${yyyy}-${mm}-${dd}`;
    const courseInstanceApiData = {
      courseInstanceId: 0,
      courseId: courseTypeVal,
      venueId: selectedVenue,
      plannedAttendance: data.places,
      noTrainers: data.coaches,
      noRunDays: data.duration,
      season: todayDt.getFullYear(),
      startDate: formattedDate,
      endDate: endDateFormat,
      resourced: 1,
      finalised: 0,
    };

    console.log(courseInstanceApiData);
    console.log(selectedTrainer);
    courseInstanceApi(courseInstanceApiData)
      .then(({ data }) => {
        selectedTrainer.forEach(id => {
          updateTrainersCourseInstance(id, data.courseInstanceId);
        });
        resetAllFields();
      })
      .catch(() => { });
  };

  const resetAllFields = () => {
    resetField('places');
    resetField('coaches');
    resetField('duration');
    setShowVenueMap(false);
    setSelectedVenue('');
    setSelectedVenueDetails(false);
    setSelectedTrainer([]);
    setNextStepCta(false);
    setSubmitCta(false);
    setCourseTypeVal('');
    trainerIds = [];
    history.push(`/`);
  };

  const previousStep = () => {
    setShowVenueMap(false);
  };

  const handleClick = (e, filtertype, filterValue) => {
    console.log("target value -", e.target.value);
    console.log("target text -", filterValue);
    console.log("target checked? - ", e.target.checked);
    console.log("filtertype - " - filtertype);

    if (e.target.checked) {
      setSelectedOption([...selectedOption, filterValue?.toLowerCase()]);
    } else {
      setSelectedOption(selectedOption.filter(data => data !== filterValue?.toLowerCase()));
    }
  };

  const filterOptions = {
    filters: [
      {
        title: 'Coach dev qualifications',
        filterList: {
          options: [
            {
              id: 1,
              optionVal: 'all',
              optionLabel: 'ALL COACH DEVELOPERS',
              icon: '',
            },
            {
              id: 2,
              optionVal: 'uefac',
              optionLabel: 'UEFA C',
              icon: '/images/common/filter/uefac.svg',
            },
            {
              id: 3,
              optionVal: 'gk',
              optionLabel: 'GOALKEEPING',
              icon: '/images/common/filter/gk.svg',
            },
            {
              id: 4,
              optionVal: 'efaif',
              optionLabel: 'EFAiF',
              icon: '/images/common/filter/efaif.svg',
            },
            {
              id: 5,
              optionVal: 'i2cf',
              optionLabel: 'I2CF',
              icon: '/images/common/filter/i2cf.svg',
            },
            {
              id: 6,
              optionVal: 'uefab',
              optionLabel: 'UEFA B',
              icon: '/images/common/filter/uefab.svg',
            },
            {
              id: 7,
              optionVal: 'woc',
              optionLabel: 'WOC',
              icon: '/images/common/filter/woc.svg',
            },
            {
              id: 8,
              optionVal: 'futsal',
              optionLabel: 'FUTSAL',
              icon: '/images/common/filter/futsal.svg',
            }
          ]
        }
      }
    ]
  };


  return (
    <div css={styles.venueEdit}>
      {!showVenueMap && (
        <div css={styles.venueEditWrapper}>
          {selectedVenueDetails && (
            <div css={styles.selectedDetails}>
              <div css={styles.venueDetails}>
                <div css={styles.venueDetailsTitle}>
                  {selectedVenueDetails.venue1}
                </div>
                <div css={styles.venueDetailsAdress}>
                  <div>
                    <div css={styles.venueAddressTitle}>Address</div>
                    <p>{selectedVenueDetails.address}</p>
                    <p>{selectedVenueDetails.postCode}</p>
                  </div>
                  <div>
                    <div css={styles.venueAddressTitle}>Contact</div>
                    <p>{selectedVenueDetails.phone}</p>
                    <p>{selectedVenueDetails.email}</p>
                  </div>
                </div>
              </div>
              <div css={styles.venueDetails}>
                <div css={styles.venueDetailsTitle}>Selected Trainers</div>
                {selectedTrainer &&
                  selectedTrainer.map(trainer => {
                    const trainerName = trainerList.filter(t => {
                      return t.trainerId == trainer;
                    });
                    return (
                      <p key={trainerName[0]?.trainerId}>
                        {trainerName[0]?.fullName} &nbsp; - &nbsp;{' '}
                        {trainerName[0]?.discipline} &nbsp; {trainerName[0]?.role}
                      </p>
                    );
                  })}
              </div>
            </div>
          )}
          <Fragment>
            <form
              css={styles.venueEditForm}
              onSubmit={onSubmit}
              autoComplete="off"
            >
              <div css={styles.venueEditFormStep}>
                <div css={styles.inputContainer}>
                  <label css={styles.inputLabel}>
                    <span css={styles.inputLabelName}>Course Type</span>
                  </label>
                  <CustomSelectDropDown
                    id="courseType"
                    name="courseType"
                    optionsList={courseTypeData}
                    // {...register('courseType', venueFormOptions.courseType)}
                    selectedOptionValue={selectQueryOption}
                    selectedDefaultValue={courseTypeDefault} 
                    setSelectedDefaultValue={setCourseTypeDefault}
                    ref={null}
                  />
                </div>
                <div css={styles.inputContainer}>
                  <label css={styles.inputLabel}>
                    <span css={styles.inputLabelName}>Start date</span>
                  </label>
                  <div css={styles.calendarWrapper}>
                    <input
                      type="text"
                      name="startdate"
                      css={[
                        styles.inputField,
                        errors?.startdate && styles.danger,
                      ]}
                      {...register('startdate', venueFormOptions.startdate)}
                      value={datevalue}
                      onClick={e => toggleCalendar()}
                    />
                    {showCalendar && (
                      <Calendar
                        value={calendarValue}
                        onChange={handleCalendarChange}
                        onClickDay={value => handleCalendarChange(value)}
                      />
                    )}
                  </div>
                </div>
                <div css={styles.inputContainer}>
                  <label css={styles.inputLabel}>
                    <span css={styles.inputLabelName}>Capacity</span>
                  </label>
                  <input
                    type="text"
                    name="places"
                    css={[styles.inputField, errors?.places && styles.danger]}
                    {...register('places', venueFormOptions.places)}
                  // onChange={(e) => setInputValues({ ...inputValues, ['places']: e.target.value })}
                  />
                </div>
                <div css={styles.inputContainer}>
                  <label css={styles.inputLabel}>
                    <span css={styles.inputLabelName}>Coaches</span>
                  </label>
                  <input
                    type="text"
                    name="coaches"
                    css={[styles.inputField, errors?.coaches && styles.danger]}
                    {...register('coaches', venueFormOptions.coaches)}
                  // onChange={(e) => setInputValues({ ...inputValues, ['coaches']: e.target.value })}
                  />
                </div>
                <div css={styles.inputContainer}>
                  <label css={styles.inputLabel}>
                    <span css={styles.inputLabelName}>Duration</span>
                  </label>
                  <input
                    type="text"
                    name="duration"
                    css={[styles.inputField, errors?.duration && styles.danger]}
                    {...register('duration', venueFormOptions.duration)}
                  // onChange={(e) => setInputValues({ ...inputValues, ['duration']: e.target.value })}
                  />
                </div>
                <div css={styles.venueEditFormCta}>
                  <button
                    css={styles.cancelCta}
                    onClick={() => onClickCancelHandler()}
                  >
                    Cancel
                  </button>
                  {selectedVenueDetails && (
                    <button
                      css={styles.updateCta}
                      onClick={() => onClickEditHandler()}
                    >
                      Edit
                    </button>
                  )}
                  {!submitCta && (
                    <button css={styles.updateCta} onClick={nextVenueUpdate}>
                      Next
                    </button>
                  )}
                  {submitCta && (
                    <button type="submit" css={styles.updateCta}>
                      {courseInstanceId && courseInstanceId != '0' ? 'Update' : 'Submit'}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </Fragment>
        </div>
      )}
      {showVenueMap && (
        <div css={styles.venueMap}>
          <div css={styles.venueActionCta}>
            {/* <button css={styles.cancelCta} onClick={() => previousStep()}>
              Back
            </button>
            <button
              css={styles.nextStep(nextStepCta)}
              onClick={() => nextTrainerUpdate()}
              aria-disabled={nextStepCta}
            >
              Next
            </button> */}

            <div css={styles.filterWrapper}>
              <div css={styles.filterTitle}>
                ORGANISE A NEW COURSE
              </div>
              <div css={styles.filterList}>
                {filterOptions.filters.length > 0 && filterOptions.filters.map(filter => (
                  <FilterOptions filterOptions={filter.filterList} filterTitle={filter.title} handleClick={handleClick} />
                ))}
              </div>
            </div>
          </div>
          <CourseVenueMap
            setSelectedTrainer={setSelectedTrainer}
            selectedTrainer={selectedTrainer}
            selectedVenue={selectedVenue}
            setSelectedVenue={setSelectedVenue}
            setSelectedVenueDetails={setSelectedVenueDetails}
            trainerIds={trainerIds}
            trainerList={trainerListFiltered}
            // trainerList={(selectedOption.length === 0 ||
            //   selectedOption.some(option => option.includes("all")))?
            //   trainerList
            //   :
            //   trainerList.filter(trainer =>
            //     selectedOption.includes(trainer?.discipline?.toLowerCase))
            // }
            hideFilter={hideFilter}
          />
        </div>
      )}
    </div>
  );
};

export default CourseManagementNew;
