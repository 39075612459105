/** @jsxImportSource @emotion/react */
import { useState, useReducer, useEffect } from 'react';
import React, { useLayoutEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DatePicker from 'react-multi-date-picker';
import useStyles from './styles';
import useCourseVenueAPI from '../../hooks/useCourseVenueAPI';
import {
  getTrainerIcon,
  getVenueDefaultIcon,
  getVenueSelectedIcon,
} from '../../hooks/useMapPins';
import Map from '../Map';
import MapService from '../../utils/MapService';
import trayStateReducer, {
  EXPAND_ACTION,
  EXPAND_MAP_ACTION,
  SHRINK_ACTION,
  STANDARD,
} from '../../reducers/trayState';

import LeftFilter from '../LeftFilter';
import FilterOptions from '../FilterOptions';
import {
  CloseDatePickerIcon,
  DateIcon,
  // CloseDatePickerIcon
} from '../Icons';
import CustomSelectDropDown from '../CustomSelectDropDown';
import {
  getCourseVenueList,
  courseInstanceApi,
  createVenueCourseInstance,
  createCourseGroup,
} from '../../services';
import Buttons from 'react-multi-date-picker/components/button';
import CourseInfoPopup from '../CourseInfoPopup';
import CourseInfoConfirmPopup from '../CourseInfoConfirmPopup';

const CourseManagementAddNew = props => {
  const styles = useStyles();
  const [selectedId, setSelectedId] = useState();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [trayState, dispatch] = useReducer(trayStateReducer, STANDARD);
  const [selectedFilterCourse, setSelectedFilterCourse] = useState([]);
  const [selectedFilterVenue, setSelectedFilterVenue] = useState([]);
  const { courseTrainerListData, courseVenueListData } = useCourseVenueAPI(0);
  const [numberOfTrainers, setNumberOfTrainers] = useState(0);
  const [selectedVenue, setSelectedVenue] = useState();
  const [selectedVenueDetails, setSelectedVenueDetails] = useState(null);
  const [trainers, setTrainers] = useState(false);
  const [hideFilter, setHideFilter] = useState(true);
  const [datevalue, setDatevalue] = useState('');
  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarValue, setCalendarValue] = useState('');
  const [courseTypeSelected, setCourseTypeSelected] = useState(0);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [courseInfoModal, showCourseInfoModal] = useState(false);

  const [courseConfirmModal, showCourseConfirmModal] = useState(false);

  const [additionalDateCount, setSetAdditionalDateCount] = useState(1);
  const [items, setItems] = useState([{ id: 1, date: null }]);
  const [courseInfoData, setCourseInfoData] = useState();
  const [notes, setNotes] = useState('');
  //form
  const {
    register,
    handleSubmit,
    setValue,
    resetField,
    setFocus,
    getValues,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  let history = useHistory();

  useEffect(() => {
    if (!map.current && courseTrainerListData && courseVenueListData) {
      initializeMap(courseTrainerListData, courseVenueListData);
    }
  }, [courseTrainerListData, courseVenueListData, additionalDateCount]);

  const filterOptions = {
    filters: [
      {
        title: 'Coach dev qualifications',
        filterList: {
          options: [
            {
              id: 1,
              optionVal: 'all',
              optionLabel: 'ALL COACH DEVELOPERS',
              icon: '',
            },
            {
              id: 2,
              optionVal: 'uefac',
              optionLabel: 'UEFA C',
              icon: '/images/common/filter/uefac.svg',
            },
            {
              id: 3,
              optionVal: 'gk',
              optionLabel: 'GOALKEEPING',
              icon: '/images/common/filter/gk.svg',
            },
            {
              id: 4,
              optionVal: 'efaif',
              optionLabel: 'EFAiF',
              icon: '/images/common/filter/efaif.svg',
            },
            {
              id: 5,
              optionVal: 'i2cf',
              optionLabel: 'I2CF',
              icon: '/images/common/filter/i2cf.svg',
            },
            {
              id: 6,
              optionVal: 'uefab',
              optionLabel: 'UEFA B',
              icon: '/images/common/filter/uefab.svg',
            },
            {
              id: 7,
              optionVal: 'woc',
              optionLabel: 'WOC',
              icon: '/images/common/filter/woc.svg',
            },
            {
              id: 8,
              optionVal: 'futsal',
              optionLabel: 'FUTSAL',
              icon: '/images/common/filter/futsal.svg',
            },
          ],
        },
      },
    ],
  };

  const showTrainerInfoBox = (id, pinData) => {
    const description = `
      <div class="tooltipWrapper">
        <div class="tooltipTitle">${pinData.fullName}</div>
        <div class="tooltipAddress">
            <div class="addressWrapper">
                <div class="addressTitle">Address</div>
                <div class="addressDetails">
                    <p>${pinData.address1}, ${pinData.address2}, ${pinData.address3}</p>
                    <p>${pinData.postCode}</p>
                </div>
            </div>
            <div class="addressWrapper">
                <div class="addressTitle">Contact</div>
                <div class="addressDetails bluetext">
                    <p>${pinData.contactNumber}</p>
                    <p>${pinData.email}</p>
                </div>
            </div>
        </div>
        <div class="actionCta">
          <button class="createCta"><span>${pinData.discipline} &nbsp; ${pinData.role}</span></button>
        </div>
      </div>
    `;
    //setSelectVenuePin(id);
    map.current.infobox.setOptions({
      title: pinData.venue1,
      location: id.getLocation(),
      description: description,
      visible: true,
      actions: [],
    });
  };

  const getDateFormat = (date, format = 'mm dd') => {
    const dt = new Date(date);
    const yyyy = dt.getFullYear();
    let mm =
      format === 'mm dd'
        ? dt.toLocaleString('default', { month: 'short' })
        : dt.getMonth() + 1;
    let dd = dt.getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    if (format === 'dd-mm-yyyy') return `${dd}-${mm}-${yyyy}`;

    if (format === 'yyyy-mm-dd') return `${yyyy}-${mm}-${dd}`;

    return `${dd} - ${mm} - ${yyyy}`;
  };

  const createVenueInstance = courseInstance => {
    const venueCourseInstanceData = {
      venuesCourseInstanceId: 0,
      venueId: selectedVenueDetails?.venueId,
      courseInstanceId: courseInstance?.courseInstanceId,
      confirmed: 0,
      declined: 0,
      contacted: 1,
      lastContacted: getDateFormat(new Date()?.toString(), 'yyyy-mm-dd'),
    };

    createVenueCourseInstance(venueCourseInstanceData)
      .then(({ data }) => {
        console.log('Venue Course instance API after submitting - ', data);
        history.push('/');
      })
      .catch(ex => {
        console.log('error in creating venue instance -', ex);
      });
  };

  const createNewCourse = () => {
    console.log('Confirming course');

    const todayDt = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(start - end);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const selectedDates = courseInfoData?.courseDates;

    console.log('Selected dates - ', selectedDates);

    const courseGroupApiData = {
      courseGroupId: 0,
      courseId: courseInfoData?.courseId,
      venueId: selectedVenueDetails?.venueId,
      courseGroupName: courseInfoData?.courseGroupName,
      courseName: courseInfoData?.courseType,
      venueName: selectedVenueDetails?.venue1,
      plannedAttendance: 0,
      noTrainers: courseInfoData?.noOfCoach,
      noRunDays: 0,
      season: 0,
      startDate: courseInfoData?.courseDate,
      endDate:
        courseInfoData?.courseDates[courseInfoData?.courseDates?.length - 1],
      resourced: 0,
      finalised: 0,
      locationLon: selectedVenueDetails?.locationLon,
      locationLat: selectedVenueDetails?.locationLat,
      notes: '',
      venueConfirmed:courseInfoData?.venueConfirmed
    };

    console.log('courseGroupApiData', courseGroupApiData);

    createCourseGroup(courseGroupApiData)
      .then(({ data }) => {
        console.log('Course group API after submitting - ', data);
        //courseGroupId
        //selectedDates.map(date => createCourseIntance(data?.courseGroupId,date));
        //createVenueInstance(data);

        const promises = selectedDates?.map(date => {
          
          const courseIntancedata = {
            courseInstanceId: 0,
            courseGroupId: data?.courseGroupId,
            venueId: 0,
            courseDate: date,
            resourced: 0,
            finalised: 0,
            notes: '',
          };

          console.log('courseIntancedata - ', courseIntancedata);

          return courseInstanceApi(courseIntancedata)
            .then(({ data }) => {
              console.log('Course instance API after submitting - ', data);
              //createVenueInstance(data);
            })
            .catch(ex => {
              console.log('error in creating course instance -', ex);
            });
        });

        return Promise.all(promises)
        .then(()=>{
          console.log("All course instances created");
          history.push('/');
        })
      })
      .catch(ex => {
        console.log('error in creating pending course instance -', ex);
      });
  };

  const createCourseIntance = (courseGroupId, date) => {
    const courseIntancedata = {
      courseInstanceId: 0,
      courseGroupId: courseGroupId,
      venueId: 0,
      courseDate: date,
      resourced: 0,
      finalised: 0,
      notes: '',
    };

    console.log('courseIntancedata - ', courseIntancedata);

    courseInstanceApi(courseIntancedata)
      .then(({ data }) => {
        console.log('Course instance API after submitting - ', data);
        //createVenueInstance(data);
      })
      .catch(ex => {
        console.log('error in creating course instance -', ex);
      });
  };

  const handleTrainerSelect = (e, trainerId, mapdata) => {
    map.current.infobox.setOptions({
      visible: false,
    });
    showTrainerInfoBox(e.target, mapdata);
  };

  useLayoutEffect(() => {
    if (trainers) {
      //getTrainerDetails();
    }
  }, [trainers]);

  useLayoutEffect(() => {
    if (!map.current) {
      return;
    }

    map.current.setSelected(selectedId);
  }, [selectedId]);

  const handlePinSelect = (e, id, pinData) => {
    if (hideFilter) {
      showInfoBox(e.target, pinData);
      setSelectedVenue(id);
      //setSelectedVenueDetails(pinData);

      setTrainers(true);

      setTimeout(() => {
        setSelectedId(id);
      }, 300);
    } else {
      showInfoBox(e.target, pinData);
    }
  };

  const showInfoBox = (id, pinData) => {
    const description = `
      <div class="tooltipWrapper">
        <div class="tooltipTitle">${pinData.venue1}</div>
        <div class="tooltipAddress">
            <div class="addressWrapper">
                <div class="addressTitle">Address</div>
                <div class="addressDetails">
                    <p>${pinData.address}</p>
                    <p>${pinData.postCode}</p>
                </div>
            </div>
            <div class="addressWrapper">
                <div class="addressTitle">Contact</div>
                <div class="addressDetails bluetext">
                    <p>${pinData.phone}</p>
                    <p>${pinData.email}</p>
                </div>
            </div>
        </div>
        <div class="facilitiesWrapper">
            <div class="addressTitle">Facilities</div>
            <div class="facilitiesDetails">
                <p class="type">Full size, futsal, 3G </p>
                <p class="treatment">Treatment facilities</p>
                <p class="facility">Classroom with whiteboard</p>
            </div>
        </div>
      </div>
    `;
    //setSelectVenuePin(id);
    map.current.infobox.setOptions({
      title: pinData.venue1,
      location: id.getLocation(),
      description: description,
      visible: true,
      actions: [
        {
          label: 'CREATE COURSE HERE',
          eventHandler: () => venueSelect(pinData),
        },
      ],
    });
  };

  const venueSelect = venuedata => {
    setSelectedVenueDetails(venuedata);

    setCourseInfoData({ ...courseInfoData, courseLocation: venuedata.venue1 });

    //selectedVenueDetails?.venue1

    map.current.infobox.setOptions({
      visible: false,
    });

    showCourseInfoModal(true);
  };

  const checkTrainerFilterValue = data => {
    if (selectedFilterCourse.length > 0) {
      const filterCheck = selectedFilterCourse.filter(fil => {
        return (fil.option === 'efaif' && data.discipline === 'EFAiF') ||
          (fil.option === 'futsal' && data.discipline === 'Futsal') ||
          (fil.option === 'gk' && data.discipline === 'Goalkeeping') ||
          (fil.option === 'i2cf' && data.discipline === 'I2CF') ||
          (fil.option === 'uefab' && data.discipline === 'UEFA B') ||
          (fil.option === 'uefac' && data.discipline === 'UEFA C') ||
          (fil.option === 'woc' && data.discipline === 'WOC') ||
          fil.option === 'all'
          ? true
          : false;
      });
      return filterCheck.length;
    }
    return false;
  };

  const checkVenueFilterValue = data => {
    if (selectedFilterVenue.length > 0) {
      const filterCheck = selectedFilterVenue.filter(fil => {
        return (fil.option === 'low' &&
          data.classroomSize != null &&
          parseInt(data.classroomSize) < 20) ||
          (fil.option === 'good' &&
            data.classroomSize != null &&
            parseInt(data.classroomSize) >= 20) ||
          (fil.option === 'empty' &&
            (data.classroomSize == 0 || data.classroomSize == null))
          ? true
          : false;
      });
      return filterCheck.length;
    }
    return true;
  };

  const generateMapPins = (data, venuedata = []) => {
    const pushPins = [];
    let inc = 0;

    data
      .filter(
        v =>
          v.locationLon !== null &&
          v.locationLat !== null &&
          checkTrainerFilterValue(v)
      )
      .forEach(mapdata => {
        const id = mapdata.trainerId;
        pushPins[inc] = {
          id,
          longitude: mapdata.locationLon,
          latitude: mapdata.locationLat,
          options: {
            icon: getTrainerIcon(mapdata.discipline),
          },
          handleClick: e => handleTrainerSelect(e, mapdata.trainerId, mapdata),
        };
        inc += 1;
      });

    if (venuedata.length !== 0) {
      venuedata
        .filter(
          v =>
            v.locationLon !== null &&
            v.locationLat !== null &&
            checkVenueFilterValue(v)
        )
        .forEach(mapdata => {
          const id = mapdata.venueId;
          pushPins[inc] = {
            id,
            longitude: mapdata.locationLon,
            latitude: mapdata.locationLat,
            capacity: mapdata.classroomSize,
            options: {
              icon: getVenueDefaultIcon(),
            },
            handleClick: e => handlePinSelect(e, id, mapdata),
          };
          inc += 1;
        });
    }

    return pushPins;
  };

  useEffect(() => {
    //if (selectedFilterCourse.length > 0) {
    if (courseTrainerListData?.length > 0 && courseVenueListData?.length > 0) {
      const updatedPins = generateMapPins(
        courseTrainerListData,
        courseVenueListData
      );

      // removed existing  pins and regenerate it
      map.current.removePins();
      map.current.addPins(updatedPins);
    }
    //}
  }, [selectedFilterCourse]);

  useEffect(() => {
    console.log('courseInfoUPDATED', setCourseInfoData);
  }, [setCourseInfoData]);

  const initializeMap = (data, venuedata) => {
    const userLocation = {
      latitude: 51.5567,
      longitude: -0.2797,
    };

    const pushPins = generateMapPins(data, venuedata);

    map.current = new MapService(
      {
        container: mapContainer.current,
        center: userLocation,
      },
      pushPins,
      getVenueDefaultIcon,
      getVenueSelectedIcon,
      hideFilter
    );
  };

  const handleClick = (e, filtertype, filterValue) => {
    if (e.target.checked) {
      const filterData = { option: e.target.value, type: filtertype };

      setSelectedFilterCourse([...selectedFilterCourse, filterData]);
    } else {
      const removeData = selectedFilterCourse.filter(data => {
        return !(data.option === e.target.value && data.type === filtertype);
      });

      setSelectedFilterCourse(removeData);
    }
  };

  const updateCourseConfirmModal = val => {
    showCourseConfirmModal(val);
    showCourseInfoModal(false);
  };

  return (
    <div css={styles.trainerMap}>
      <div css={styles.trainerMapWrapper}>
        <div ref={mapContainer} style={{ height: '100%', width: '100%' }}></div>
      </div>

      <div css={styles.venueActionCta}>
        <div css={styles.filterWrapper}>
          <div css={styles.filterTitle}>
            <button css={styles.backBtn} onClick={history.goBack}>
              <img src="/images/common/back-arrow-ios.svg" />
              Back
            </button>
          </div>
          <div css={styles.filterTitle}>ORGANISE A NEW COURSE</div>
          <div css={styles.filterList}>
            {filterOptions.filters.length > 0 &&
              filterOptions.filters.map(filter => (
                <FilterOptions
                  filterOptions={filter.filterList}
                  filterTitle={filter.title}
                  handleClick={handleClick}
                />
              ))}
          </div>
        </div>
      </div>

      {(courseInfoModal === true || courseConfirmModal === true) && (
        <div css={styles.overlay}>
          {courseInfoModal === true && (
            <CourseInfoPopup
              updateCourseConfirmModal={updateCourseConfirmModal}
              updateCourseData={setCourseInfoData}
              data={courseInfoData}
              mode="create"
            />
          )}
        </div>
      )}
      {courseConfirmModal === true && (
        <div css={styles.overlay}>
          <CourseInfoConfirmPopup
            data={courseInfoData}
            confirmCourse={createNewCourse}
          />
        </div>
      )}
    </div>
  );
};

export default CourseManagementAddNew;
