import axios from 'axios';

import {
  Api404,
  Api500,
  CLUB,
  KICKABOUT_CASUAL,
  MAX_DURATION,
  MAX_DURATION_OVERRIDE,
  SELECTED_RECOMMENDATIONS_TYPE_ID,
  SESSION_DURATION,
  UnspecifiedError,
} from '../const';
import AppError from '../utils/AppError';
import headerMenu from '../mocks/data/headerMenu';

const apiUrlMap = {
  [KICKABOUT_CASUAL]: 'casualrecommendation',
  [CLUB]: 'clubrecommendation',
};

const miniOnboardingApiUrlMap = {
  [KICKABOUT_CASUAL]: 'sessionrecommendation',
  [CLUB]: 'teamsrecommendation',
};

const apiMLUrlMap = {
  [KICKABOUT_CASUAL]: 'mlcasualrecommendation',
  [CLUB]: 'mlclubrecommendation',
};

const errorMapping = {
  404: Api404,
  500: Api500,
};

const baseURL = process.env.REACT_APP_API_URL;
const subscriptionKey = process.env.REACT_APP_SUBSCRIPTION_KEY;
const baseExternalURL = process.env.REACT_APP_API_URL_EXTERNAL;
const subscriptionKeyAccreditations =
  process.env.REACT_APP_SUBSCRIPTION_KEY_ACC;
const siteCoreBaseURL = process.env.REACT_APP_SITECORE_API_URL;

const baseHeaders = {
  'Content-Type': 'application/json',
  'Ocp-Apim-Subscription-Key': subscriptionKey,
};

const service = axios.create({
  baseURL,
  headers: baseHeaders,
});

service.interceptors.response.use(undefined, error => {
  const status = error.response?.status.toString();

  throw new AppError(
    status && status in errorMapping ? errorMapping[status] : UnspecifiedError
  );
});

// TODO: move normalisers to their specific files

// Preferences normaliser
const normalisePreferences = preferences => {
  const { [SESSION_DURATION]: SessionDuration } = preferences;

  // TODO: Move this logic to the back-end
  // Please refer to this ticket https://the-fa.atlassian.net/browse/EF-444
  // for more details about this change
  if (SessionDuration === MAX_DURATION) {
    return {
      ...preferences,
      SessionDuration: MAX_DURATION_OVERRIDE,
    };
  }

  return preferences;
};

export const getHomepageData = () => {
  const siteCoreAPIUrl = `${siteCoreBaseURL}/layout/render/jss?sc_site=discoverfootball-site&sc_apikey={68A451F8-7553-4D18-8C3D-4BDA0F132688}&item={352FCB53-6C93-4651-A54F-43EDA3890758}&sc_lang=en`;
  return service.get(siteCoreAPIUrl, {
    headers: {
      ...baseHeaders,
      'Ocp-Apim-Subscription-Key': subscriptionKeyAccreditations,
    },
  });
};

// get menus
export const getHeaderMenu = () => {
  return new Promise(resolve => {
    resolve({ data: headerMenu });
  });
  // const siteCoreAPIUrl = `${siteCoreBaseURL}/layout/placeholder/jss?placeholderName=header&item=/sitecore/content/EnglandFootball/home&sc_apikey={E2F3D43E-B1FD-495E-B4B1-84579892422A}`;
  // const siteCoreAPIUrl = `${siteCoreBaseURL}/layout/placeholder/jss?placeholderName=header&item=/sitecore/content/EnglandFootball/Home&sc_apikey={E2F3D43E-B1FD-495E-B4B1-84579892422A}`;
  // return service.get(siteCoreAPIUrl, {
  //   headers: {
  //     ...baseHeaders,
  //     'Ocp-Apim-Subscription-Key': subscriptionKeyAccreditations,
  //   },
  // });
};

export const getSessionLogo = () => {
  const siteCoreAPIUrl = `${siteCoreBaseURL}/layout/placeholder/jss?placeholderName=FAInitiatives&item=/sitecore/content/DiscoverFootball/Home&sc_apikey={E2F3D43E-B1FD-495E-B4B1-84579892422A}`;
  return service.get(siteCoreAPIUrl, {
    headers: {
      ...baseHeaders,
      'Ocp-Apim-Subscription-Key': subscriptionKeyAccreditations,
    },
  });
};

export const getClubAccreditation = id => {
  return service.get(`${baseExternalURL}/${id}/accreditation`, {
    headers: {
      ...baseHeaders,
      'Ocp-Apim-Subscription-Key': subscriptionKeyAccreditations,
    },
  });
};

export const getClubContactDetails = id => {
  return service.get(`${baseExternalURL}/${id}/clubcontact`, {
    headers: {
      ...baseHeaders,
      'Ocp-Apim-Subscription-Key': subscriptionKeyAccreditations,
    },
  });
};

// POST
export const getRecommendations = (footballType, preferences) => {
  const endpoint = apiUrlMap[footballType];
  const normalisedPreferences = normalisePreferences(preferences);

  return service.post(`/${endpoint}`, normalisedPreferences);
};

//POST
export const getMiniOnboardingRecommendations = (footballType, preferences) => {
  const endpoint = miniOnboardingApiUrlMap[footballType];
  const normalisedPreferences = normalisePreferences(preferences);

  return service.post(`/${endpoint}`, normalisedPreferences);
};

// POST
export const getMLRecommendations = (footballType, preferences) => {
  const endpoint = apiMLUrlMap[footballType];
  const normalisedPreferences = normalisePreferences(preferences);

  return service.post(`/${endpoint}`, normalisedPreferences);
};

export const getProviderDetails = (id, providerId, preferences) => {
  return service.post(`${baseURL}/provider`, {
    PitchFinderId: id,
    ProviderId:
      typeof providerId === 'string' ? providerId : providerId?.toString(),
    PlayWith: preferences.PlayWith,
    Age: preferences.Age,
    WeekDays: preferences.WeekDays,
    Disabilityoption: preferences.Disabilityoption,
    Latitude: preferences.Latitude,
    Longitude: preferences.Longitude,
    DisabilityType: preferences.DisabilityType,
    FootballType: preferences[SELECTED_RECOMMENDATIONS_TYPE_ID],
    PageNumber: preferences.PageNumber,
    PageSize: preferences.PageSize,
  });
};

export const getVenueDetails = (id, preferences) => {
  return service.post(`${baseURL}/centre`, {
    CentreId: id,
    Age: preferences.Age,
    PlayWith: preferences.PlayWith,
    FootballType: preferences[SELECTED_RECOMMENDATIONS_TYPE_ID],
    WeekDays: preferences.WeekDays,
    Disabilityoption: preferences.Disabilityoption,
    DisabilityType: preferences.DisabilityType,
    Latitude: preferences.Latitude,
    Longitude: preferences.Longitude,
    PageNumber: preferences.PageNumber,
    PageSize: preferences.PageSize,
  });
};

export const getClubDetails = (id, preferences) => {
  return service.post(`${baseURL}/club`, {
    ClubId: id,
    Age: preferences.Age,
    PlayWith: preferences.PlayWith,
    FootballType: preferences[SELECTED_RECOMMENDATIONS_TYPE_ID],
    WeekDays: preferences.WeekDays,
    Disabilityoption: preferences.Disabilityoption,
    DisabilityType: preferences.DisabilityType,
    Latitude: preferences.Latitude,
    Longitude: preferences.Longitude,
  });
};

export const getTeamDetails = (id, preferences) => {
  const url = !preferences
    ? `${baseURL}/team?FadwTeamId=${id}`
    : `${baseURL}/team?FadwTeamId=${id}&Latitude=${preferences.Latitude}&Longitude=${preferences.Longitude}`;
  return service.get(url, {
    headers: {
      ...baseHeaders,
      'Ocp-Apim-Subscription-Key': subscriptionKey,
    },
  });
};

export const getMatchDetails = id => {
  const WgsTeamID = id.toString().toLowerCase();
  return service.post(
    `${baseExternalURL}/team`,
    { WgsTeamIDs: [WgsTeamID] },
    {
      headers: {
        ...baseHeaders,
        'Ocp-Apim-Subscription-Key': subscriptionKeyAccreditations,
      },
    }
  );
};

// submit contact form
export const submitContactForm = requestPayload => {
  return service.post(`${baseURL}/notification`, requestPayload);
};

export const getSocialMediaData = WgsClubId => {
  return service.get(`${baseExternalURL}/${WgsClubId}/socialmedia`, {
    headers: {
      ...baseHeaders,
      'Ocp-Apim-Subscription-Key': subscriptionKeyAccreditations,
    },
  });
};

export const getCourseVenueList = () => {
  return service.get(`https://courseplanningapi.azurewebsites.net/api/venue`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getCourseVenueData = venueId => {
  return service.get(
    `https://courseplanningapi.azurewebsites.net/api/venue/${venueId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const updateCourseVenueData = (venueId, venueData) => {
  return service.put(
    `https://courseplanningapi.azurewebsites.net/api/venue/${venueId}`,
    venueData,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const getCourseTrainerList = () => {
  return service.get(`https://courseplanningapi.azurewebsites.net/api/Trainer`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getCourseList = () => {
  return service.get(`https://courseplanningapi.azurewebsites.net/api/Course`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getCourseInstanceDetails = () => {
  return service.get(
    `https://courseplanningapi.azurewebsites.net/api/CourseInstance/Details`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const getCourseGroups = () => {
  return service.get(
    `https://courseplanningapi.azurewebsites.net/api/CourseGroup`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
}

export const getCourseGroupById = courseGroupId => {
  return service.get(
    `https://courseplanningapi.azurewebsites.net/api/CourseGroup/${courseGroupId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const getCourseGroupsWithTrainers = () => {
  return service.get(
    `https://courseplanningapi.azurewebsites.net/api/CourseGroup/GetWithTrainers`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
}


export const getCourseInstanceByGroup = courseGroupId => {
  return service.get(
    `https://courseplanningapi.azurewebsites.net/api/CourseInstance/GetByGroup/${courseGroupId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
}

export const getCourseGroupDetail = courseGroupId => {
  return service.get(
    `https://courseplanningapi.azurewebsites.net/api/CourseGroup/Detail/${courseGroupId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
}

export const createCourseGroup = courseData => {
  return service.post(
    `https://courseplanningapi.azurewebsites.net/api/CourseGroup`,
    courseData
  );
};



export const updateCourseGroup = (courseData,courseGroupId) => {
  return service.put(
    `https://courseplanningapi.azurewebsites.net/api/CourseGroup/${courseGroupId}`,
    courseData
  );
}

export const deleteCourseGroup = courseData => {
  return service.delete(
    `https://courseplanningapi.azurewebsites.net/api/CourseGroup`,
    { data: courseData }
  );
};

export const deleteCourseInstance = courseInstanceData => {
  return service.delete(
    `https://courseplanningapi.azurewebsites.net/api/CourseInstance`,
    { data: courseInstanceData }
  );
};

export const getTrainersCourseGroup = courseGroupID => {
  return service.get(
    `https://courseplanningapi.azurewebsites.net/api/CourseGroupTrainer/GetTrainersByCourseGroup/${courseGroupID}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const createTrainersCourseGroup = trainerData => {
  return service.post(
    `https://courseplanningapi.azurewebsites.net/api/CourseGroupTrainer`,
    trainerData
  );
};

export const updateTrainersCourseGroup = trainerData => {
  return service.put(
    `https://courseplanningapi.azurewebsites.net/api/CourseGroupTrainer`,
    trainerData
  );
};

export const updateCourseInstance = (courseInstanceData,courseInstanceId) => {
  return service.put(
    `https://courseplanningapi.azurewebsites.net/api/CourseInstance/${courseInstanceId}`,
    courseInstanceData
  );
}


export const courseInstanceApi = courseData => {
  return service.post(
    `https://courseplanningapi.azurewebsites.net/api/CourseInstance`,
    courseData
  );
};

export const trainersCourseInstanceApi = trainerData => {
  return service.post(
    `https://courseplanningapi.azurewebsites.net/api/TrainersCourseInstance`,
    trainerData
  );
};

export const updateTrainersCourseInstance = trainerData => {
  return service.put(
    `https://courseplanningapi.azurewebsites.net/api/TrainersCourseInstance`,
    trainerData
  );
};

export const updateVenuesCourseInstance = venueData => {
  return service.put(
    `https://courseplanningapi.azurewebsites.net/api/VenuesCourseInstance`,
    venueData
  );
};

export const createVenueCourseInstance = venueData => {
  return service.post(
    `https://courseplanningapi.azurewebsites.net/api/VenuesCourseInstance`,
    venueData
  );
};

export const getCourseInstanceApi = courseInstanceID => {
  return service.get(
    `https://courseplanningapi.azurewebsites.net/api/CourseInstance/${courseInstanceID}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const getTrainersCourseInstanceApi = courseInstanceID => {
  return service.get(
    `https://courseplanningapi.azurewebsites.net/api/TrainersCourseInstance/${courseInstanceID}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const getVenueCourseInstanceApi = courseInstanceID => {
  return service.get(
    `https://courseplanningapi.azurewebsites.net/api/VenuesCourseInstance/${courseInstanceID}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const getVenuesByDistance = (latitude, longitude) => {
  return service.get(
    `https://courseplanningapi.azurewebsites.net/api/Venue/GetByDistance?lat=${latitude}&lon=${longitude}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const getTrainersByDistance = (latitude, longitude) => {
  return service.get(
    `https://courseplanningapi.azurewebsites.net/api/Trainer/GetByDistance?lat=${latitude}&lon=${longitude}`,
    {
      headers: {
        'Content-Type': 'application/json',
      }
    }
  );
};

export const updateCourseInstanceApi = (courseData, courseInstanceID) => {
  return service.put(
    `https://courseplanningapi.azurewebsites.net/api/CourseInstance/${courseInstanceID}`,
    courseData
  );
};

export const deleteTrainersCourseInstanceApi = courseData => {
  return service.delete(
    `https://courseplanningapi.azurewebsites.net/api/TrainersCourseInstance`,
    { data: courseData }
  );
};
