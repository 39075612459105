/** @jsxImportSource @emotion/react */

import Button from '../Button';
import Footer from '../Footer';
import useStyles from '../Page500/styles';
import Text from '../Text';

const Page404 = ({ resetError }) => {
  const styles = useStyles();

  return (
    <main css={styles.base}>
      <div css={styles.pageWrapper('page-404-bg@2x')}>
        <div css={styles.textWrapper}>
          <Text as="h1" size={3} css={styles.mainText}>
            Sorry, we can't find that page
          </Text>
          <Text css={styles.subText}>
            It may have been moved, or doesn't exist. Not to worry, hit the
            button below to return to the Find Football homepage.
          </Text>
          <div css={styles.bottom}>
            <Button.Link isExternal css={styles.cta} to="/">
              Find football homepage
            </Button.Link>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default Page404;
