/** @jsxImportSource @emotion/react */
import { useRef } from 'react';
import useStyles from './styles';

import useScrollTop from '../../hooks/useScrollTop';
import Navigation from '../Navigation';
import LeftMenu from '../LeftMenu';

const Page = ({ children }) => {
  const styles = useStyles();
  const pageRef = useRef();
  useScrollTop(pageRef);

  return (
    <div className="spa-scroll-top-fix" ref={pageRef} tabIndex={-1}>
      <div css={styles.base}>
        <div css={styles.pageWrapper}>
          {/* <div css={styles.leftMenu}>
            <LeftMenu />
          </div> */}
          <div css={styles.maincontent}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Page;
