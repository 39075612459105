import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import DontKnowMyFan from '../components/Account/DontKnowMyFan';
import SignInFan from '../components/Account/SignInFan';
import SignUp from '../components/Account/SignUp';
import CourseVenueEdit from './../components/CourseVenueEdit';
import CourseVenueList from './../components/CourseVenueList';
import CourseVenueMap from './../components/CourseVenueMap';
import CourseTrainerMap from './../components/CourseTrainerMap';
import CourseTrainerList from './../components/CourseTrainerList';
import CourseManagementMap from './../components/CourseManagementMap';
import CourseManagementList from './../components/CourseManagementList';
import CourseManagementNew from './../components/CourseManagementNew';
import CourseManagementAddNew from './../components/CourseManagementAddNew';
import CourseManagementLayout from './../components/CourseManagementLayout';

import { setLocalItem } from '../hooks/useLocalStorage';
import HomePage from './../components/HomePage';

import Page from './../components/Page';
import Page404 from './../components/Page404';
import { EMPTY_STRING } from './../const';

const setLocalStorageUrl = () => {
  let urlValue = true;
  let locationName = window.location.href;
  let localStorageName;
  let isMiniOnboarding = true;
  switch (true) {
    case locationName.indexOf('letgirlsplay') > -1:
      localStorageName = 'ISLETGOGIRLSPLAY';
      isMiniOnboarding = false;
      break;
    case locationName.indexOf('wildcats') > -1:
      localStorageName = 'ISWILDCATS';
      break;
    case locationName.indexOf('justplay') > -1:
      localStorageName = 'ISJUSTPLAY';
      break;
    case locationName.indexOf('ageuk') > -1:
      localStorageName = 'ISAGEUK';
      break;
    case locationName.indexOf('youthclub') > -1:
      localStorageName = 'ISYOUTHCLUB';
      break;
    case locationName.indexOf('adultclub') > -1:
      localStorageName = 'ISADULTCLUB';
      break;
    case locationName.indexOf('squadgirls') > -1:
      localStorageName = 'ISSQUADGIRLS';
      break;
    case locationName.indexOf('external') > -1:
      localStorageName = 'ISIFRAME';
      isMiniOnboarding = false;
      break;
    default:
      localStorageName =
        window.location.pathname === '/'
          ? null
          : JSON.parse(sessionStorage.getItem('MOPREVIOUSURL'));
      break;
  }

  setLocalItem('MOPREVIOUSURL', JSON.stringify(localStorageName));
  setLocalItem('ISMINIONBOARDING', localStorageName ? isMiniOnboarding : false);

  return localStorageName
    ? setLocalItem(localStorageName, urlValue)
    : EMPTY_STRING;
};

const AppRoutes = () => {
  // Check the site is external or not
  useEffect(() => {
    let footballTypes = [
      'ISIFRAME',
      'ISLETGOGIRLSPLAY',
      'ISWILDCATS',
      'ISJUSTPLAY',
      'ISAGEUK',
      'ISYOUTHCLUB',
      'ISADULTCLUB',
      'ISSQUADGIRLS',
    ];
    footballTypes.forEach(type => sessionStorage.removeItem(type));
    setLocalStorageUrl();
  });

  return (
    <Switch>
      <Route exact path="/">
        <Page>
          <CourseManagementLayout />
          {/* <CourseManagementList/> */}
        </Page>
      </Route>
      <Route path="/courses/map">
        <Page>
          {/* <CourseManagementMap /> */}
          <CourseManagementLayout />
        </Page>
      </Route>
      <Route path="/courses/addnew">
        <Page>
          {/* <CourseManagementNew /> */}
          {/* <CourseManagementAddNew /> */}
          <CourseManagementLayout />
        </Page>
      </Route>
      <Route
        exact
        path="/courses/update/:courseInstanceId/:venueId/:redirectPage"
      >
        <Page>
          <CourseManagementNew />
        </Page>
      </Route>
      <Route exact path="/venues/map">
        <Page>
          <CourseVenueMap />
        </Page>
      </Route>
      <Route exact path="/venues/list">
        <Page>
          <CourseVenueList />
        </Page>
      </Route>
      <Route exact path="/venues/addnew">
        <Page>
          <CourseVenueEdit />
        </Page>
      </Route>
      <Route exact path="/editvenue/:venueId">
        <Page>
          <CourseVenueEdit />
        </Page>
      </Route>
      <Route exact path="/trainers/map">
        <Page>
          <CourseTrainerMap />
        </Page>
      </Route>
      <Route exact path="/trainers/list">
        <Page>
          <CourseTrainerList />
        </Page>
      </Route>
      <Route exact path="/homepage/:matchid">
        <Page>
          <HomePage />
        </Page>
      </Route>

      <Route exact path="/account/signup">
        <SignUp />
      </Route>
      <Route exact path="/account/signinfan">
        <SignInFan />
      </Route>
      <Route exact path="/account/idontknowmyfan">
        <DontKnowMyFan />
      </Route>
      <Route path="*">
        <Page404 />
      </Route>
    </Switch>
  );
};

export default AppRoutes;
