/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';

import useStyles from './styles';
import publicPath from '../../utils/publicPath';

import FilterOptions from '../FilterOptions';

const LeftFilter = ({setSelectedFilter, filterOptions, setFilterOpen, filterOpen}) => {
  const styles = useStyles();
  const [selectedOption, setSelectedOption] = useState([]);
  let filterSelected = [];

  const toggleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  useEffect(() => {
    console.log(selectedOption);
    setSelectedFilter(selectedOption);
  }, [selectedOption]);

  const handleClick = (e, filtertype, filterValue) => {
    // console.log(e.target.value);
    // console.log(e.target.checked);
    // console.log(filtertype);
    if (e.target.checked) {
      const filterData = {option: e.target.value, type: filtertype};

      filterSelected.push(filterData);
      setSelectedOption([...selectedOption, filterData]);
    } else {
      const removeData = selectedOption.filter(data => {return !(data.option === e.target.value && data.type === filtertype)});

      filterSelected = removeData;
      setSelectedOption(removeData);
    }
    // console.log(filterSelected);
    // setSelectedOption(filterSelected);
    // console.log(selectedOption);
  };

  return (
    <div css={styles.base(filterOpen)}>
      <div css={styles.filterWrapper}>
        <div css={styles.filterTitle}>
            COACH DEVELOPER AND VENUE KEY
        </div>
        <div css={styles.filterList}>
            {filterOptions.filters.length > 0 && filterOptions.filters.map(filter => (
              <FilterOptions filterOptions={filter.filterList} filterTitle={filter.title} handleClick={handleClick} />
            ))}
        </div>
      </div>
      <div css={styles.filterOpen}>
          {!filterOpen && (
          <button onClick={toggleFilter}>            
            <img
              css={styles.image}
              alt="user"
              src={publicPath('/images/common/filter/tune.svg')}
            />
            <span>FILTERS</span>
          </button>
          )}
          {filterOpen && (
          <button onClick={toggleFilter}>            
            <img
              css={styles.image}
              alt="user"
              src={publicPath('/images/common/filter/close.svg')}
            />
            <span>CLOSE FILTERS</span>
          </button>
          )}
      </div>
    </div>
  );
};

export default LeftFilter;
