/** @jsxImportSource @emotion/react */

import useStyles from './styles';

const HomePage = () => {
  const styles = useStyles();

  return (
    <div css={styles.base}>
      <section css={styles.home_page}>
        {/* <HomePageHeader /> */}
      </section>
    </div>
  );
};

export default HomePage;
