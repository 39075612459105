import { OnboardingDataProvider } from './OnboardingData';
import { PageHistoryProvider } from './pageHistory';
import { PageInfoProvider } from './pageInfo';
import { SetHeaderHeightProvider } from './setHeaderHeight';
import { SetFixtureDataProvider } from './setFixtureData';

const ContextProviders = ({ children }) => {
  return (
    <SetHeaderHeightProvider>
      <SetFixtureDataProvider>
        <OnboardingDataProvider>
            <PageHistoryProvider>
              <PageInfoProvider>{children}</PageInfoProvider>
            </PageHistoryProvider>
        </OnboardingDataProvider>
      </SetFixtureDataProvider>
    </SetHeaderHeightProvider>
  );
};

export default ContextProviders;
