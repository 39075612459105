/** @jsxImportSource @emotion/react */
import { useState, useReducer, useEffect } from 'react';
import React, { useLayoutEffect, useRef } from 'react';
import useStyles from './styles';
import { useHistory } from 'react-router-dom';
import { getCourseIcon } from '../../hooks/useMapPins';
import Map from '../Map';
import MapService from '../../utils/MapService';
import trayStateReducer, {
  EXPAND_ACTION,
  EXPAND_MAP_ACTION,
  SHRINK_ACTION,
  STANDARD,
} from '../../reducers/trayState';
import { getCourseInstanceDetails, getCourseVenueList, getCourseGroups } from '../../services';
import { courseInstaceDetails } from '../CourseManagementList/mockdata';
import FilterOptionsList from '../FilterOptionsList';
import LeftFilter from '../LeftFilter';

const CourseManagementMap = () => {
  const styles = useStyles();
  const history = useHistory();
  const [selectedId, setSelectedId] = useState();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [trayState, dispatch] = useReducer(trayStateReducer, STANDARD);
  const [filterOpen, setFilterOpen] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [venues, setVenues] = useState([]);

  const filterOptions = {
    filters: [
      {
        title: '',
        filterList: {
          options: [
            {
              id: 1,
              optionVal: 'all',
              optionLabel: 'ALL',
            },
            {
              id: 2,
              optionVal: 'confirmed',
              optionLabel: 'CONFIRMED COURSES',
              images: '/images/common/venue/greenPin.svg',
            },
            {
              id: 3,
              optionVal: 'pending',
              optionLabel: 'PENDING COURSES',
              images: '/images/common/venue/yellowPin.svg',
            },
          ],
        },
      },
    ],
  };

  useEffect(() => {
    //getCourseInstanceDetails()
    getCourseGroups()
      .then(({ data }) => {
        setCourseData(data);
      })
      .catch();

    getCourseVenueList()
      .then(({ data }) => {
        setVenues(data);
      })
      .catch(ex => console.log('Error in getCourseVenueList', ex));
  }, []);

  useEffect(() => {
    console.log('selectedFilter - ', selectedFilter);
    console.log('venues - ', venues);
    console.log('courseData - ', courseData);
  }, [selectedFilter, venues,courseData]);

  useLayoutEffect(() =>{
    if(courseData?.length > 0 && venues?.length > 0)
    initializeMap(courseData);
  },[courseData,venues])

  useEffect(() => {
    if (courseData?.length > 0) {
      const updatedPins = generateMapPins(courseData);

      // removed existing  pins and regenerate it
      map.current.removePins();
      map.current.addPins(updatedPins);
    }
  }, [selectedFilter]);

  const getDateFormat = date => {
    const dt = new Date(date);
    const yyyy = dt.getFullYear();
    let mm = dt.getMonth() + 1; // Months start at 0!
    let dd = dt.getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    return `${dd}-${mm}-${yyyy}`;
  };

  const handleCourseSelect = (e, id, pinData) => {
    showCourseInfoBox(e.target, pinData);
  };

  const isEligibleCourse = status => {
    if (selectedFilter?.length === 0 || selectedFilter?.includes('ALL')) {
      return true;
    } else if (status !== 1 && selectedFilter?.includes('PENDING COURSES')) {
      return true;
    } else if (status === 1 && selectedFilter?.includes('CONFIRMED COURSES')) {
      return true;
    } else {
      return false;
    }
  };

  const generateMapPins = courseData => {
    let inc = 0;

    console.log(selectedFilter);

    const pushPins = [];

    courseData
      .filter(
        course =>
          course?.locationLon !== null &&
          course?.locationLat !== null &&
          isEligibleCourse(course?.finalised)
      )
      .forEach(mapdata => {
        pushPins[inc] = {
          longitude: mapdata.locationLon,
          latitude: mapdata.locationLat,
          options: {
            icon: getCourseIcon(mapdata?.finalised),
          },
          handleClick: e =>
            handleCourseSelect(e, mapdata.courseInstanceId, mapdata),
        };
        inc += 1;
      });

    return pushPins;
  };

  const initializeMap = courseData => {
    const userLocation = {
      latitude: 51.5567,
      longitude: -0.2797,
    };

    const pushPins = generateMapPins(courseData);

    map.current = new MapService(
      {
        container: mapContainer.current,
        center: userLocation,
      },
      pushPins,
      getCourseIcon
    );
  };

  const updateCourseInfo = (id, venueId) => {
    console.log(id);
    console.log('updted course');
    history.push(`/courses/update/${id}/${venueId}/map`);
  };

  const filterCourseInstances = (element, title, option) => {
    if (
      element === null &&
      (option?.toUpperCase()?.trim() === 'ALL' ||
        option?.toUpperCase()?.startsWith('ALL'))
    ) {
      setSelectedFilter([]);
    } else if (element?.target?.checked) {
      if (
        option?.toUpperCase()?.trim() === 'ALL' ||
        option?.toUpperCase()?.startsWith('ALL')
      )
        setSelectedFilter([]);
      else setSelectedFilter([...selectedFilter, option?.toUpperCase()]);
    } else {
      setSelectedFilter(
        selectedFilter?.filter(
          data => data.toUpperCase() !== option?.toUpperCase()
        )
      );
    }
  };

  const getVenueName = venueId => {
    if (venueId === 0) {
      return 'Unassigned';
    } else {
      const venue = venues?.find(data => data?.venueId === venueId);

      return venue?.venue1;
    }
  };

  const showCourseInfoBox = (id, pinData) => {
    const description = `
      <div class="tooltipWrapper">
        <div class="tooltipTitle">${pinData?.courseName}</div>
        <div class="tooltipAddress">
            <div class="addressWrapper">
                <div class="addressTitle">Details</div>
                <div class="addressDetails">
                    <p>Venue: ${getVenueName(pinData?.venueId)}</p>
                    <p>Min Trainer: ${pinData?.noTrainers}</p>
                    <p>Start Date: ${getDateFormat(pinData?.startDate)}</p>
                    <p>End Date: ${getDateFormat(pinData?.endDate)}</p>
                </div>
            </div>
        </div>
      </div>
    `;
    //setSelectVenuePin(id);
    map.current.infobox.setOptions({
      title: pinData.courseType,
      location: id.getLocation(),
      description: description,
      // actions: [
      //   {
      //     label: 'Update',
      //     eventHandler: () =>
      //       updateCourseInfo(pinData.courseInstanceId, pinData.venueId),
      //   },
      // ],
      visible: true,
    });
  };

  return (
    <div css={styles.venueMap}>
      <div css={styles.venueMapWrapper}>
        <div ref={mapContainer} style={{ height: '100%', width: '100%' }}></div>
      </div>
      <div>
        {filterOptions.filters.length > 0 &&
          filterOptions.filters.map(filter => (
            <FilterOptionsList
              filterOptions={filter.filterList}
              filterTitle={filter.title}
              handleClick={filterCourseInstances}
              resetFilters={false}
              style={styles.filterListStyle}
            />
          ))}
      </div>
    </div>
  );
};

export default CourseManagementMap;
