/** @jsxImportSource @emotion/react */
import { useState, useReducer, useEffect } from 'react';
import React, { useLayoutEffect, useRef } from 'react';
import useStyles from './styles';
import useCourseVenueAPI from '../../hooks/useCourseVenueAPI';
import { getTrainerIcon } from '../../hooks/useMapPins';
import Map from '../Map';
import MapService from '../../utils/MapService';
import trayStateReducer, {
  EXPAND_ACTION,
  EXPAND_MAP_ACTION,
  SHRINK_ACTION,
  STANDARD,
} from '../../reducers/trayState';

import LeftFilter from '../LeftFilter';

const CourseTrainerMap = () => {
  const styles = useStyles();
  const [selectedId, setSelectedId] = useState();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [trayState, dispatch] = useReducer(trayStateReducer, STANDARD);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const { courseTrainerListData } = useCourseVenueAPI(0);
  // console.log(courseVenueListData);

  useEffect(() => {
    if (!map.current && courseTrainerListData) {
      initializeMap(courseTrainerListData);
    }
  }, [courseTrainerListData]);
  
  const filterOptions = {
    filters: [
      {
        title: 'COURSE DEVELOPERS',
        filterList: {
          options: [
            {
              id: 1,
              optionVal: 'all',
              optionLabel: 'ALL COACH DEVELOPERS',
              icon: '',
            },
            {
              id: 2,
              optionVal: 'uefac',
              optionLabel: 'UEFA C',
              icon: '/images/common/filter/uefac.svg',
            },
            {
              id: 3,
              optionVal: 'gk',
              optionLabel: 'GOALKEEPING',
              icon: '/images/common/filter/gk.svg',
            },
            {
              id: 4,
              optionVal: 'efaif',
              optionLabel: 'EFAiF',
              icon: '/images/common/filter/efaif.svg',
            },
            {
              id: 5,
              optionVal: 'i2cf',
              optionLabel: 'I2CF',
              icon: '/images/common/filter/i2cf.svg',
            },
            {
              id: 6,
              optionVal: 'uefab',
              optionLabel: 'UEFA B',
              icon: '/images/common/filter/uefab.svg',
            },
            {
              id: 7,
              optionVal: 'woc',
              optionLabel: 'WOC',
              icon: '/images/common/filter/woc.svg',
            },
            {
              id: 8,
              optionVal: 'futsal',
              optionLabel: 'FUTSAL',
              icon: '/images/common/filter/futsal.svg',
            }
          ]
        }
      }
    ]
  };

  const showTrainerInfoBox = (id, pinData) => {
    const description = `
      <div class="tooltipWrapper">
        <div class="tooltipTitle">${pinData.fullName}</div>
        <div class="tooltipAddress">
            <div class="addressWrapper">
                <div class="addressTitle">Address</div>
                <div class="addressDetails">
                    <p>${pinData.address1}, ${pinData.address2}, ${pinData.address3}</p>
                    <p>${pinData.postCode}</p>
                </div>
            </div>
            <div class="addressWrapper">
                <div class="addressTitle">Contact</div>
                <div class="addressDetails bluetext">
                    <p>${pinData.contactNumber}</p>
                    <p>${pinData.email}</p>
                </div>
            </div>
        </div>
        <div class="actionCta">
          <button class="createCta"><span>${pinData.discipline} &nbsp; ${pinData.role}</span></button>
        </div>
      </div>
    `;
    //setSelectVenuePin(id);
    map.current.infobox.setOptions({
      title: pinData.venue1,
      location: id.getLocation(),
      description: description,
      visible: true,
    });
  };

  const handleTrainerSelect = (e, trainerId, mapdata) => {
    map.current.infobox.setOptions({
      visible: false,
    });
    showTrainerInfoBox(e.target, mapdata);

    // const pinIndex = map.current.pushpinObjects.findIndex(pin => pin.id === trainerId);

    // console.log(pinIndex);

    // const center=  map.current.createLocation(map.current.pushpinObjects[pinIndex]);

    // console.log(center);

    // map.current.map.setView({ center: center, zoom: 9});
  };

  const checkTrainerFilterValue = data => {
    if (selectedFilter.length > 0) {
      const filterCheck = selectedFilter.filter(fil => {
        return (fil.option === 'uefac' && data.discipline === 'EFAiF') ||
          (fil.option === 'futsal' && data.discipline === 'Futsal') ||
          (fil.option === 'gk' && data.discipline === 'Goalkeeping') ||
          (fil.option === 'i2cf' && data.discipline === 'I2CF') ||
          (fil.option === 'uefab' && data.discipline === 'UEFA B') ||
          (fil.option === 'uefac' && data.discipline === 'UEFA C') ||
          (fil.option === 'woc' && data.discipline === 'WOC')
          ? true
          : false;
      });
      return filterCheck.length;
    }
    return true;
  };

  const generateMapPins = (data) => {
    const pushPins = [];
    let inc = 0;

    data
      .filter(v => v.locationLon !== null && v.locationLat !== null && checkTrainerFilterValue(v))
      .forEach(mapdata => {
        const id = mapdata.trainerId;
        pushPins[inc] = {
          id,
          longitude: mapdata.locationLon,
          latitude: mapdata.locationLat,
          options: {
            icon: getTrainerIcon(mapdata.discipline),
          },
          handleClick: e => handleTrainerSelect(e, mapdata.trainerId, mapdata),
        };
        inc += 1;
      });


    return pushPins;
  };

  useEffect(() => {
    console.log(selectedFilter);
    if (selectedFilter.length > 0) {
      const updatedPins = generateMapPins(courseTrainerListData);

      // removed existing  pins and regenerate it
      map.current.removePins();
      map.current.addPins(updatedPins);
    }
  }, [selectedFilter]);


  const initializeMap = data => {
    const userLocation = {
      latitude: 51.5567,
      longitude: -0.2797,
    };

    const pushPins = generateMapPins(data);

    map.current = new MapService(
      {
        container: mapContainer.current,
        center: userLocation,
      },
      pushPins,
    );
  };


  return (
    <div css={styles.trainerMap}>
      <div css={styles.trainerMapWrapper}>
        <div ref={mapContainer} style={{ height: '100%', width: '100%' }}></div>
      </div>
      <div css={styles.filterPopup}>
        <LeftFilter setSelectedFilter={setSelectedFilter} filterOptions={filterOptions} setFilterOpen={setFilterOpen}  filterOpen={filterOpen} />
      </div>
    </div>
  );
};

export default CourseTrainerMap;
