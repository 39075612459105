/** @jsxImportSource @emotion/react */

// import courseVenueListData from '../../mocks/data/courseVenueListData';
import useStyles from './styles';
import { useHistory } from 'react-router-dom';
import { useState, useEffect, useLayoutEffect } from 'react';
import useCourseVenueAPI from '../../hooks/useCourseVenueAPI';
import CourseVenueEdit from '../CourseVenueEdit';
import Loader from '../Loader';
import {
  getCourseVenueList,
} from '../../services';

const CourseTrainerList = () => {
  const styles = useStyles();
  const history = useHistory();
  const [selectedId, setSelectedId] = useState();
  const [trainerData, setTrainerData] = useState();
  const [isLoadingPageData, setIsLoadingPageData] = useState(true);

  const { courseTrainerListData } = useCourseVenueAPI(0);

  useEffect(() => {
    if (courseTrainerListData) {
      setTrainerData(courseTrainerListData);
    }
  }, [courseTrainerListData]);

  if (isLoadingPageData && !courseTrainerListData) {
    return <Loader message="Loading course trainer data..." />;
  }


return (
    <div css={styles.venuelist}>
      <div css={styles.footerWrapper}>
          <div css={styles.list}>
            <div css={styles.listhead}>
              <div>Discipline</div>
              <div>Role</div>
              <div>Name</div>
              <div>Region</div>
              <div>Address</div>
              <div>Postcode</div>
              <div>Email</div>
              <div>Phone</div>
            </div>
            {trainerData &&
              trainerData.length > 0 &&
              trainerData.map((venue, index) => {
                return (
                  <div  css={[styles.listrow,  (index + 1) % 2 === 0 ? styles.even: '']} key={index}>
                    <div>
                      {venue.discipline}
                    </div>
                    <div>
                      {venue.role}
                    </div>
                    <div>
                      {venue.fullName}
                    </div>
                    <div>
                      {venue.regionalTeam}
                    </div>
                    <div>
                      {venue.address1}, {venue.address2}, {venue.address3}
                    </div>
                    <div>
                      {venue.postCode}
                    </div>
                    <div>
                      {venue.email}
                    </div>
                    <div>
                      {venue.contactNumber}
                    </div>
                  </div>
                );
              })}
          </div>
      </div>
    </div>
);
};
export default CourseTrainerList;
