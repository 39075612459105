/** @jsxImportSource @emotion/react */

// import courseVenueListData from '../../mocks/data/courseVenueListData';
import useStyles from './styles';
import { useHistory } from 'react-router-dom';
import { useState, useEffect, useLayoutEffect } from 'react';
import CourseVenueEdit from '../CourseVenueEdit';
import Loader from '../Loader';
import {
  getCourseVenueList,
} from '../../services';

const CourseVenueList = () => {
  const styles = useStyles();
  const history = useHistory();
  const [selectedId, setSelectedId] = useState();
  const [venueData, setvenueData] = useState();
  const [isLoadingPageData, setIsLoadingPageData] = useState(true);

  useEffect(() => {
    getCourseVenueList()
      .then(({ data }) => {
        setvenueData(data);
      })
      .catch();
  }, []);

  const onClickEditVenueHandler = (venueId) => {
    // console.log(fixtureData);
    //history.push(`/editvenue/${venueId}`);
    setSelectedId(venueId);
  };


  if (isLoadingPageData && !venueData) {
    return <Loader message="Loading course venue data..." />;
  }


  if (!selectedId) {
  return (
    <div css={styles.venuelist}>
      <div css={styles.footerWrapper}>
          <div css={styles.list}>
            <div css={styles.listhead}>
              <div>Venue</div>
              <div>Address</div>
              <div>Postcode</div>
              <div>Contact Name</div>
              <div>Email</div>
              <div>Phone</div>
              <div>Action</div>
            </div>
            {venueData &&
              venueData.length > 0 &&
              venueData.map((venue, index) => {
                return (
                  <div  css={[styles.listrow,  (index + 1) % 2 === 0 ? styles.even: '']} key={index}>
                    <div>
                      {venue.venue1}
                    </div>
                    <div>
                      {venue.address}
                    </div>
                    <div>
                      {venue.postCode}
                    </div>
                    <div>
                      {venue.contactName}
                    </div>
                    <div>
                      {venue.email}
                    </div>
                    <div>
                      {venue.phone}
                    </div>
                    <div>
                      <a css={styles.editlist} onClick={() => onClickEditVenueHandler(venue.venueId)}>Edit</a>
                    </div>
                  </div>
                );
              })}
          </div>
      </div>
    </div>
  );
  } else {
    return (
      <CourseVenueEdit 
        venueId={selectedId}
        handleSelect={setSelectedId}
        selectedId={selectedId}
      />
    );
  }
};

export default CourseVenueList;
