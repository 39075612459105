import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const {
    colors,
    breakpoints,
    screenSizes,
    spacing,
    typography,
    borderRadius,
  } = useTheme();

  return {
    base: css`
      background-color: ${colors.SecondaryColor};
      color: ${colors.white};
      ${breakpoints.l} {
        width: 360px;
        max-width: 360px;
        display: block;
        margin: 10px auto;
        max-height: calc(100vh - 20px);
        border-radius: 10px;
        overflow-y: auto;
        ::-webkit-scrollbar {
          width: 1px;
          height: 2px;
        }
        ::-webkit-scrollbar-track {
          background: #375578;
        }
        ::-webkit-scrollbar-thumb {
          background: #888;
        }
      }
    `,
  };
};
