import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const { colors, spacing, breakpoints, screenSizes, utility } = useTheme();

  return {
    venueEdit: css`
      color: ${colors.white};
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.125rem;
      letter-spacing: 0.02em;
      text-transform: initial;
      background-color: ${colors.black};
      min-height: 100vh;
    `,
    venueEditWrapper: css`
      position: relative;
      background-color: ${colors.black};
      color: ${colors.white};
      padding: 93px 0 40px 98px;
    `,
    venueDetails: css`
      max-width: 400px;
      padding: 24px;
      border: 1px solid rgba(228, 228, 228, 0.60);
      margin-bottom: 32px;
      border-radius: 4px;
      min-width: 366px;
    `,
    selectedDetails: css`
      display: flex;
      gap: 32px;
    `,
    venueDetailsTitle: css`
      font-size: 24px;
      line-height: 28px;
    `,
    venueDetailsAdress: css`
      display: flex;
      gap: 32px;
      margin-top: 8px;
    `,
    venueAddressTitle: css`
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: 1px solid #E6C6FF;
    `,
    venueEditForm: css`
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: fit-content;
    `,
    venueEditFormStep: css`
      & > div {
        display: flex;
      }
      input {
        color: ${colors.lightBlue};
        background: transparent url(${publicPath('/images/common/edit.svg')}) no-repeat
          calc(100% - 10px) center;
        margin-top: -10px;
        padding: 10px 16px;
        border-radius: 4px;
        border: 1px solid rgba(228, 228, 228, 0.60);
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        height: 56px;
        max-width: 366px;
      }
      textarea {
        border: 1px solid rgba(228, 228, 228, 0.60);
        border-radius: 4px;
        min-height: 100px;
        padding: 13px 16px;
        font-size: 16px;
        line-height: 18px;
        color: ${colors.lightBlue};
        height: 100%;
        width: 100%;
        resize: none;
        max-width: 366px;
      }
    `,
    inputContainer: css`
      position: relative;
      margin-bottom: 32px;
      width: 366px;
      border-radius: 4px;
    `,
    inputLabel: css`
      position: absolute;
      top: -19px;
      left: 10px;
      background-color: ${colors.black};
      padding: 0 5px;
      color: ${colors.white};
      z-index: 1;
    `,
    inputLabelName: css`
      color: ${colors.white};
      font-size: 15.63px;
      line-height: 15.63px;
    `,
    calendarWrapper: css`
      width: 100%;
      position: relative;
      .react-calendar {
        position: absolute;
        width: 366px;
        z-index: 2;
      }
      .react-calendar__tile--active {
        background: #e6e6e6;
        color: ${colors.black};
      }
      .react-calendar__tile--now {
        background: #8F00FF;
        color: ${colors.white};
      }
    `,
    danger: css`
      border: 2px solid ${colors.red};

      &:focus,
      &:focus-visible {
        border: 2px solid ${colors.red};
        outline: 0;
      }
    `,
    inputField: css`
      border: 2px solid ${colors.lightBlue};
    `,
    switch: css`
      flex-shrink: 0;
      position: relative;
      display: inline-block;
      width: 38px !important;
      height: 18px;
      border-radius: 8px;
      min-width: initial !important;
      input {
        opacity: 0;
        width: 0 !important;
        height: 0 !important;
        outline: none;
        :checked + span::before {
          background-color: ${colors.darkBlue};
          -webkit-transform: translateX(18px);
          -ms-transform: translateX(18px);
          transform: translateX(18px);
        }
      }
    `,
    slider: css`
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color:  ${colors.white};
      border: 2px solid  ${colors.lightBlue};
      -webkit-transition: 0.4s;
      transition: 0.4s;
      width: initial !important;
      border-radius: 8px;
      min-width: initial !important;
      &:before {
        position: absolute;
        content: '';
        height: 10px;
        width: 10px;
        left: 4px;
        bottom: 2px;
        background-color: ${colors.lightBlue};
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
      }
    `,
    venueEditFormCta: css`
      justify-content: left;
      gap: 16px;
      align-items: center;
      button {
        width: 100%;
        height: 54px;
        border-radius: 4px;
        border: none;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 300px;
        min-height: 44px;
        text-transform: uppercase;
        text-decoration: none;
        transition: background-color 0.2s ease-in;
        position: relative;
        white-space: nowrap;
        padding: 0 20px;
        box-sizing: border-box;
      }
    `,
    cancelCta: css`
      width: 100%;
      height: 54px;
      background: ${colors.white};
      border-radius: 4px;
      color: ${colors.black};
      border: 2px solid #E6C6FF;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      max-width: 300px;
    `,
    updateCta: css`
      text-decoration: none;
      height: 54px;
      color: ${colors.white};
      border: 1px solid transparent;
      background: #8f00ff;
      padding: 8px 24px;
      transition: background-color 0.2s ease-in;
    `,
    nextStep: enabled => css`
    height: 54px;
    color: ${colors.white};
    background: #8f00ff;
    border-radius: 4px;
    border: none;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    width: 100%;
    cursor: pointer;

    ${enabled &&
      css`
        color: ${colors.white};
        background: ${colors.red};
      `};
    `,
    venueMap: css`
      .mapContainer {
       // height: calc(100vh - 100px) !important;
      }
    `,
    venueActionCta: css`
      display: flex;
      //margin-left: 50px;
      //margin-top: 32px;
      gap: 32px;
      button {
        max-width: 150px;
        cursor: pointer;
      }

      // display: flex;
      // flex-direction: column;
      // color: ${colors.white};
      // padding: 53px 24px 53px 48px;
      // background: ${colors.black};
      // transform: translateX(-394px);
      // transition: transform 0.3s ease-in-out;

      position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    height: 100%;
    background: #1D1D1B;
    padding: 32px;
}
    `,

    filterWrapper: css`
      width: 226px;
    `,

    filterTitle: css`
      font-size: 15.63px;
      line-height: 15.63px;
      letter-spacing: -0.156px;
      padding-bottom: 24px;
      margin-bottom: 40px;
      border-bottom: 1px solid #4F4F4E;
      color: #FFFFFF;
    `,

    filterList: css`
      display: flex;
      flex-direction: column;
      gap: 32px;
      max-height: calc(100vh - 220px);
      height: 100vh;
      overflow-y: auto;
      padding-bottom: 32px;
      padding-right: 24px;

      ::-webkit-scrollbar {
        width: 4px;
      }
      ::-webkit-scrollbar-thumb {
        background: ${colors.grey};
        cursor: pointer;
      }      
      ::-webkit-scrollbar-track {
        background-color: ${colors.darkGrey};
        -webkit-border-radius: 1rem;
        border-radius: 1rem;
      }

      label{
        height: 48px;
      }
    `,
  };
};
