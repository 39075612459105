import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const { breakpoints, borderRadius, colors, typography, spacing } = useTheme();

  return {
    base: isOpened => css`
      display: flex;
      flex-direction: column;
      color: ${colors.white};
      padding: 53px 24px 53px 48px;
      background: ${colors.black};
      transform: translateX(-394px);
      transition: transform 0.3s ease-in-out;
      ${isOpened &&
        css`
        transform: translateX(0);        
      `};
    `,
    filterWrapper: css`
      width: 322px;
    `,
    filterTitle: css`
      font-size: 15.63px;
      line-height: 15.63px;
      letter-spacing: -0.156px;
      padding-bottom: 24px;
      margin-bottom: 40px;
      border-bottom: 1px solid #4F4F4E;
    `,
    filterList: css`
      display: flex;
      flex-direction: column;
      gap: 32px;
      max-height: calc(100vh - 185px);
      height: 100vh;
      overflow-y: auto;
      padding-bottom: 32px;
      padding-right: 24px;

      ::-webkit-scrollbar {
        width: 4px;
      }
      ::-webkit-scrollbar-thumb {
        background: ${colors.grey};
        cursor: pointer;
      }      
      ::-webkit-scrollbar-track {
        background-color: ${colors.darkGrey};
        -webkit-border-radius: 1rem;
        border-radius: 1rem;
      }
    `,
    filterOpen: css`
      padding: 24px;
      background: #E6E6E6;
      position: absolute;
      top: 0;
      right: -120px;
      width: 120px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  
      button {
        display: flex;
        flex-direction: column;
        border: none;
        background: transparent;
        justify-conmtent: center;
        align-items: center;
        gap: 7px;
        cursor: pointer;

        span {
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
          letter-spacing: -0.14px;
        }
      }
    `,
  };
};
