/** @jsxImportSource @emotion/react */

import useStyles from './styles';
import useCourseVenueAPI from '../../hooks/useCourseVenueAPI';
import Footer from '../Footer';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Fragment, useEffect, useState } from 'react';
import { updateCourseVenueData } from '../../services';
import LocationAutoSuggest from '../LocationAutoSuggest';

export const venueFormOptions = {
  region: { required: 'Region is required', maxLength: 255 },
  venue1: { required: 'Venue is required', maxLength: 500 },
  address: { required: 'Address is required', maxLength: 500 },
  postCode: { required: 'PostCode is required', maxLength: 255 },
  contactName: { required: 'Contact Name is required', maxLength: 255 },
  email: {
    required: 'Email is required',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: 'Please enter a valid email address',
    },
    maxLength: 255,
  },
  phone: {
    required: 'Phone is required',
    maxLength: 15
  },
  classroomSize: { required: 'Class room size is required'},
};

const CourseVenueEdit = ({
  venueId,
  handleSelect,
  selectedId,
}) => {
  const styles = useStyles();
  const history = useHistory();
  const params = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    resetField,
    setFocus,
    getValues,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });
  const [locationData, setLocationData] = useState();

  // console.log(venueId);

  const { courseVenueEditData } = useCourseVenueAPI(venueId);
  //console.log(courseVenueEditData);

  useEffect(() => {
    setValue('venueId', venueId);
    setValue('region', courseVenueEditData?.region);
    setValue('venue1', courseVenueEditData?.venue1);
    setValue('address', courseVenueEditData?.address);
    setValue('postCode', courseVenueEditData?.postCode);
    setValue('locationLon', courseVenueEditData?.locationLon);
    setValue('locationLat', courseVenueEditData?.locationLat);
    setValue('contactName', courseVenueEditData?.contactName);
    setValue('email', courseVenueEditData?.email);
    setValue('phone', courseVenueEditData?.phone);
    setValue('classroomSize', courseVenueEditData?.classroomSize);
    setValue('_3g', courseVenueEditData?._3g);
    setValue('grassPitch', courseVenueEditData?.grassPitch);
    setValue('futsal', courseVenueEditData?.futsal);
    setValue('costperday', courseVenueEditData?.costperday);
    setValue('comments', courseVenueEditData?.comments);
  },[courseVenueEditData]);


  const onClickCancelHandler = () => {
    // console.log(fixtureData);
    // history.push(`/`);
    handleSelect('');
  };

  const onClickUpdateHandler = () => {
    // console.log(fixtureData);
    // history.push(`/`);
    handleSelect('');
  };

  const onSubmit = handleSubmit((data) => {
   console.log(data);

   if (venueId && venueId!= '0') {
   history.push(`/`);
   handleSelect('');
  } else {
    resetField('region');
    resetField('venue1');
    resetField('address');
    resetField('postCode');
    resetField('locationLon');
    resetField('locationLat');
    resetField('contactName');
    resetField('email');
    resetField('phone');
    resetField('classroomSize');
    resetField('_3g');
    resetField('grassPitch');
    resetField('futsal');
    resetField('costperday');
    resetField('comments');
    handleSelect(2);
  }

  updateCourseVenueData(venueId, data)
    .then(({ data }) => {
      if (data) {
        console.log(data);
        history.push(`/`);
        handleSelect('');
      }
    })
    .catch(() => {});
  });

  return (
    <div css={styles.venueEdit}>
      <div css={styles.venueEditWrapper}>
         <Fragment>
          <form css={styles.venueEditForm} onSubmit={onSubmit}  autoComplete="off">
            <div css={styles.inputContainer}>
              <label css={styles.inputLabel}>
                <span css={styles.inputLabelName}>Region</span>
              </label>
              <input 
                type='text' 
                name='region'
                css={[styles.inputField, errors?.region && styles.danger]}
                {...register('region', venueFormOptions.region)}
               />
            </div>
            <div css={styles.inputContainer}>
              <label css={styles.inputLabel}>
                <span css={styles.inputLabelName}>Venue name</span>
              </label>
              <textarea 
                name='venue1' 
                css={[styles.inputField, errors?.venue1 && styles.danger]}
                {...register('venue1', venueFormOptions.venue1)}
              >
              </textarea>
            </div>
            <div css={styles.inputContainer}>
              <label css={styles.inputLabel}>
                <span css={styles.inputLabelName}>Address</span>
              </label>
              <textarea 
                name='address'
                css={[styles.inputField, errors?.address && styles.danger]}
                {...register('address', venueFormOptions.address)}
              >
              </textarea>
            </div>
            <div css={styles.inputContainer}>
              <label css={styles.inputLabel}>
                <span css={styles.inputLabelName}>Post code</span>
              </label>
              <LocationAutoSuggest
                placeholder="Enter your postcode"
                setLocationData={setLocationData}
              />
            </div>
            <div css={styles.inputContainer}>
              <label css={styles.inputLabel}>
                <span css={styles.inputLabelName}>Contact Name</span>
              </label>
              <input 
                type='text'
                name='contactName' 
                css={[styles.inputField, errors?.contactName && styles.danger]}
                {...register('contactName', venueFormOptions.contactName)}
              />
            </div>
            <div css={styles.inputContainer}>
              <label css={styles.inputLabel}>
                <span css={styles.inputLabelName}>Email</span>
              </label>
              <input 
                type='email'
                name='email' 
                css={[styles.inputField, errors?.email && styles.danger]}
                {...register('email', venueFormOptions.email)}
              />
            </div>
            <div css={styles.inputContainer}>
              <label css={styles.inputLabel}>
                <span css={styles.inputLabelName}>Phone</span>
              </label>
              <input 
                type='text'
                name='phone' 
                css={[styles.inputField, errors?.phone && styles.danger]}
                {...register('phone', venueFormOptions.phone)}  
              />
            </div>
            <div css={styles.inputContainer}>
              <label css={styles.inputLabel}>
                <span css={styles.inputLabelName}>Class Room Size</span>
              </label>
              <input 
                type='text'
                name='classroomSize' 
                css={[styles.inputField, errors?.classroomSize && styles.danger]}
                {...register('classroomSize', venueFormOptions.classroomSize)}  
              />
            </div>
            {/* <div css={styles.inputContainer}>
              <span>3g</span>
              <label css={styles.switch}>
                <input
                  type="checkbox"
                  name='_3g'
                  {...register('_3g', venueFormOptions._3g)}  
                />
                <span css={styles.slider}></span>
              </label>
            </div>
            <div css={styles.inputContainer}>
              <span>Grass Pitch</span>
              <label css={styles.switch}>
                <input
                  type="checkbox"
                  name='grassPitch'
                  {...register('grassPitch', venueFormOptions.grassPitch)}  
                />
                <span css={styles.slider}></span>
              </label>
            </div>
            <div css={styles.inputContainer}>
              <span>Futsal</span>
              <label css={styles.switch}>
                <input
                  type="checkbox"
                  name='futsal'
                  {...register('futsal', venueFormOptions.futsal)} 
                />
                <span css={styles.slider}></span>
              </label>
            </div>  */}
            <div css={styles.inputContainer}>
              <label css={styles.inputLabel}>
                <span css={styles.inputLabelName}>Cost Per Day</span>
              </label>
              <input 
                type='text'
                name='costperday' 
                css={[styles.inputField, errors?.costperday && styles.danger]}
                {...register('costperday', venueFormOptions.costperday)}  
              />
            </div>
            <div css={styles.inputContainer}>
              <label css={styles.inputLabel}>
                <span css={styles.inputLabelName}>Comments</span>
              </label>
              <textarea 
                name='comments'
                css={[styles.inputField, errors?.comments && styles.danger]}
                {...register('comments', venueFormOptions.comments)}
              >
              </textarea>
            </div>       
            <div css={styles.venueEditFormCta}>
              <button css={styles.cancelCta} onClick={()=> onClickCancelHandler()}>Cancel</button>
              <button type="submit" css={styles.updateCta}>{venueId && venueId!= '0' ? 'Update': 'Save'}</button>
            </div>
          </form>
          </Fragment>
      </div>
    </div>
  );
};

export default CourseVenueEdit;
