/** @jsxImportSource @emotion/react */
import React from 'react';
import useStyles from './styles';

const Switch = ({isChecked,setChecked}) => {
  const styles = useStyles();
  return (
    <label css={styles.switch}>
      <input type='checkbox' checked={isChecked} 
      onChange={() => setChecked(!isChecked)}/>
      <span className='slider'/>
    </label>
  );
};

export default Switch;
